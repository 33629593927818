import { Typography, Button, TextField } from "@material-ui/core";
import params from "../helper/params";
import { useDispatch } from "react-redux";
import { hideloader, showloader } from "../actions/loader.actions";
import { uploadTextOnlyVariant } from "../models/chapter.model";
import { show_alert } from "../actions/alert.actions";
import { useState } from "react";

export default function OnlyTextVariant(props) {
    let chapterData = props.chapterData
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    const [materi, setMateri] = useState(
        (props.chapterData.content.length > 0)
        ? props.chapterData.content[0].text.replace(/<\s*\/?br\s*[\/]?>/gi, "\n")
        : ''
    )
    function handleInputMateri(e) {
        setMateri(e.target.value)
    }

    function handleSubmit() {
        let text_br = materi.replace(/\r?\n/g, '<br />');

        dispatch(showloader("Memproses data..."))
        uploadTextOnlyVariant(token, chapterData._id, {
            text: text_br,
            variant: params.CHAPTER_VARIANT.ONLY_TEXT
        }).then(res => {
            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
        }).catch(err => {
            dispatch(show_alert({
                status: 'success',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    return <div>
        <Typography>
            Materi berupa teks.
        </Typography>
        <TextField
            multiline={true}
            rows={4}
            margin="dense"
            id="bookdetail"
            label="Teks materi"
            type="text"
            fullWidth
            variant='outlined'
            onChange={handleInputMateri}
            placeholder="masukan teks..."
            value={materi}
        />
        <Button onClick={handleSubmit} variant="contained" color="primary">
            SIMPAN
        </Button>
    </div>
}