import { Button, Grid, makeStyles } from "@material-ui/core";
import { loadImage } from "../helper/utils";
import lang from "../helper/localize";
const useStyles = makeStyles((theme) => ({
    cardBox: {
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        backgroundColor: 'white'
    },
    cardTitle: {
        backgroundColor: '#ffc107',
        padding: '10px',
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold'
    },
    cardImage: {
        width: '200px',
        height: '200px',
        objectFit: 'contain'
    },
    cardImagePlaceholder: {
        paddingTop: '10px',
        paddingBottom: '10px',
        width: '100%',
        margin: 'auto',
        textAlign: 'center'
    }
}));
export default function BookCard(props) {
    const classes = useStyles()
    return <Grid item md={3} xs={12} lg={3}>
        <div className={classes.cardBox}>
            <div className={classes.cardTitle}>
                {props.title}
            </div>
            <div className={classes.cardImagePlaceholder}>
                <img src={(props.cover) ? loadImage(props.cover) : "na.jpg"} alt={props.title} className={classes.cardImage} />
            </div>
            <Button variant="contained" fullWidth color="primary" onClick={props.onClick} style={{
                fontFamily: "Hacen"
            }}>
                {lang.start_learning_now}
            </Button>
        </div>
    </Grid>
}