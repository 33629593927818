
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Hidden, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EmailIcon from '@material-ui/icons/Email';
import params from '../helper/params'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { forgotPassword, login, register } from '../models/user.model'
import { useDispatch } from 'react-redux';
import {showloader, hideloader} from '../actions/loader.actions'
import { show_alert } from '../actions/alert.actions';
import jwt_decode from 'jwt-decode'
import Bilingual from '../component/bilingual.component';
import lang from '../helper/localize';
import { getBookList } from '../models/book.model';
import '@brainhubeu/react-carousel/lib/style.css';
import { loadImage } from '../helper/utils';
import {Carousel} from '3d-react-carousal';

const useStyles = makeStyles((theme) => ({
    mask: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'url(background_img.png)',
        position:'relative',
        width: '100%',
        minHeight: '100vh',
    },
    root: {
        width: '100%',
        minHeight: '100vh',
        background: 'linear-gradient(90.2deg, rgba(58, 180, 226, 0.85) 0.16%, rgba(255, 94, 229, 0.85) 130.67%)',
        position: 'absolute'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    titleLogo: {
      maxWidth: 176
    },
    bar: {
        flexGrow: 1
    },
    imageSlide: {
        width: 150,
        height: 150,
        objectFit: 'contain'
    }
}));

export default function WelcomePage() {
    const classes = useStyles();
    const history = useHistory()
    const [authOpen, setAuthOpen] = useState(false)
    const [formState, setFormState] = useState(params.AUTH.LOGIN)
    const auth = JSON.parse(localStorage.getItem('auth'))
    const dispatch = useDispatch()

    const [bookList, setBookList] = useState([])
    function refreshBook() {
        dispatch(showloader(lang.loading_data))
        getBookList().then(res => {
            let book = []
            for (let i = 0; i < res.data.length; i++) {
                const data = res.data[i];
                book.push(<img className={classes.imageSlide}  src={loadImage(data.cover)} alt={data.cover} />)
            }
            setBookList(book)
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    function handleClose() {
        setAuthOpen(!authOpen)
    }

    function handleLogin() {
        setAuthOpen(true)
        setFormState(params.AUTH.LOGIN)
    }

    useEffect(() => {
        if (auth && auth.role === params.ROLE.USER) {
            history.replace('/course')
        }

        if (auth && (auth.role === params.ROLE.ADMIN)) {
            history.replace('/cpanel')
        }

        if (auth && (auth.role === params.ROLE.FACULTY)) {
            history.replace('/cpanel/score')
        }

        refreshBook()
        // eslint-disable-next-line
    }, [])

    return <div className={classes.mask}><div className={classes.root}>
        <AppBar position="static" style={{backgroundColor: 'rgba(255, 255, 255, 0.45)'}} elevation={0}>
            <Toolbar>
                <img src="logo_tran.png" className={classes.titleLogo} alt="logo" />
                <div className={classes.bar}>&nbsp;</div>
                <Hidden xsDown>
                    <Bilingual style={{marginRight: 20}} />
                </Hidden>
                
                <Button color="primary" variant="contained" disableElevation onClick={handleLogin}>
                    <span style={{
                        fontFamily: "Hacen"
                    }}>{lang.login}</span>
                </Button>
            </Toolbar>
        </AppBar>
        <Container style={{flex: 1}} dir={
            (lang.getLanguage() === params.LANG.ARB)
                ? "rtl"
                : "ltr"
        }>
            <Grid container spacing={3} justify="center">
                <Grid item md={12} xs={12} lg={12} style={{
                    textAlign: 'center',
                }}>
                    <Typography color="primary" variant="h1" style={{fontSize: 55, paddingTop: 50, fontWeight: 'bold', marginBottom: 10, textAlign: 'center', color: 'white', fontFamily: "Hacen"}}>
                    حَيَاةُ العَرَبِيَّةِ
                    </Typography>
                    <Typography color="primary" variant="h4" style={{ paddingTop: 10, fontWeight: 'bold', marginBottom: 40, textAlign: 'center', color: 'white', fontFamily: "Hacen"}}>
تَعْلِيْمُ العَرَبِيَّةِ تَفَاعُلِيًّا إِلِكْترُونِيًّا
                    </Typography>
                    
                    <Button style={{
                        textAlign: 'center',
                        margin: 'auto',
                        fontFamily: "Hacen"
                    }} color="default" variant="contained" disableElevation onClick={handleLogin}>{lang.start_learning}</Button>
                </Grid>
            </Grid>
            <Grid container dir="ltr" justify="center" style={{
                marginTop: 40
            }}>
                <Grid item md={10} xs={12} lg={12}>
                    <Carousel slides={bookList} autoplay={true} interval={1000}/>
                </Grid>
            </Grid>
        </Container>
        {
            AuthDialog(authOpen, formState, handleClose)
        }
    </div></div>
}

function AuthDialog (open, state, handleClose) {

    const [formState, setFormState] = useState(state)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [forgotEmail, setForgotEmail] = useState('')
    const [fullName, setFullName] = useState('')
    const [birthdate, setBirthdate] = useState('')
    const [gender, setGender] = useState(params.GENDER.MALE)
    const dispatch = useDispatch()
    const history = useHistory()

    function handleOpenLogin() {
        setFormState(params.AUTH.LOGIN)
        resetFormValue();
    }
 
    function resetFormValue() {
        setEmail('');
        setPassword('');
        setForgotEmail('');
        setFullName('')
        setBirthdate('')
        setGender(params.GENDER.MALE)
    }

    function handleOpenForgotPassword() {
        setFormState(params.AUTH.FORGOT)
        resetFormValue()
    }

    function handleInputEmail(e) {
        setEmail(e.target.value)
    }

    function handleInputPassword(e) {
        setPassword(e.target.value)
    }

    function handleInputForgotPassword(e) {
        setForgotEmail(e.target.value)
    }

    function handleInputFullName(e) {
        setFullName(e.target.value)
    }

    function handleInputBirthDate(e) {
        setBirthdate(e.target.value)
    }

    function handleInputGender(e) {
        setGender(e.target.value)
    }

    function handleSubmitForm() {
        switch (formState) {
            case params.AUTH.FORGOT:
                dispatch(showloader(lang.process_data))
                forgotPassword(forgotEmail).then(res => {
                    dispatch(show_alert({
                        status: 'success',
                        message: res.message
                    }))
                }).catch(err => {
                    dispatch(show_alert({
                        status: 'error',
                        message: err.message
                    }))
                }).finally(() => {
                    dispatch(hideloader())
                })
                break;
            case params.AUTH.REGISTER:
                dispatch(showloader(lang.process_data))
                register({
                    name: fullName,
                    email,
                    password,
                    birthdate,
                    role: params.ROLE.USER,
                    gender
                }).then(res => {
                    dispatch(show_alert({
                        status: 'success',
                        message: res.message
                    }))
                }).catch(err => {
                    dispatch(show_alert({
                        status: 'error',
                        message: err.message
                    }))
                }).finally(() => {
                    dispatch(hideloader())
                })
                break;
            default:
                dispatch(showloader(lang.process_data))
                login(email, password).then(res => {
                    let jwt = res.data
                    localStorage.setItem('token', jwt)
                    let auth = jwt_decode(jwt)
                    
                    localStorage.setItem('auth', JSON.stringify(auth))
                    dispatch(show_alert({
                        status: 'success',
                        message: res.message
                    }))
                    handleClose()

                    if (auth.role === params.ROLE.USER) {
                        history.replace('/course')
                    }

                    if (auth.role === params.ROLE.ADMIN) {
                        history.replace('/cpanel/classroom')
                    }

                    if (auth.role === params.ROLE.FACULTY) {
                        history.replace('/cpanel/score')
                    }
                    
                }).catch(err => {
                    dispatch(show_alert({
                        status: 'error',
                        message: err.message
                    }))
                }).finally(() => {
                    dispatch(hideloader())
                })
                break;
        }
    }

    useEffect(() => {
        setFormState(state)
    }, [state])

    return <Dialog style={{zIndex:10000}} open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xs" dir={
        (lang.getLanguage() === params.LANG.ARB)
            ? "rtl"
            : "ltr"
    }>
        { (formState === params.AUTH.REGISTER) && <DialogTitle id="form-dialog-title">Daftar</DialogTitle> }
        { (formState === params.AUTH.LOGIN) && <DialogTitle id="form-dialog-title">{lang.authentication}</DialogTitle> }
        { (formState === params.AUTH.FORGOT) && <DialogTitle id="form-dialog-title">{lang.forgot_password}</DialogTitle> }
        <DialogContent>

            { (formState === params.AUTH.LOGIN) && <LoginForm onChangeEmail={handleInputEmail} onChangePassword={handleInputPassword} handleSubmitForm={handleSubmitForm} /> }
            { (formState === params.AUTH.FORGOT) && <ForgotPassword onChangeForgotEmail={handleInputForgotPassword} /> }
            { (formState === params.AUTH.REGISTER) && <RegisterForm onChangeEmail={handleInputEmail} onChangePassword={handleInputPassword} onChangeFullName={handleInputFullName} onChangeBirthdate={handleInputBirthDate} onChangeGender={handleInputGender} /> }
            
            { (formState === params.AUTH.LOGIN) && <Button variant="text" onClick={handleOpenForgotPassword}>{lang.forgot_password}</Button> }
            { (formState === params.AUTH.FORGOT) && <Button variant="text" onClick={handleOpenLogin}>{lang.have_account_text}</Button> }
            
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary" style={{
                fontFamily: "Hacen"
            }}>
                {lang.cancel}
            </Button>
            <Button onClick={handleSubmitForm} color="primary" variant="contained"
            style={{
                fontFamily: "Hacen"
            }}>
                {(formState) ? lang.submit : lang.login}
            </Button>
        </DialogActions>
    </Dialog>
}

function LoginForm(props) {
    return <div>
        <TextField
            autoFocus
            margin="dense"
            id="email"
            label={lang.email}
            type="email"
            fullWidth
            variant='outlined'
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <EmailIcon/>
                </InputAdornment>
                ),
            }}
            onChange={props.onChangeEmail}
            placeholder={lang.email_placeholder}
        />
        <TextField
            margin="dense"
            id="katasandi"
            label={lang.password}
            type="password"
            variant="outlined"
            fullWidth
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <VpnKeyIcon/>
                </InputAdornment>
                ),
            }}
            onChange={props.onChangePassword}
            placeholder={lang.password_placeholder}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    props.handleSubmitForm()
                }
            }}
        />
    </div>
}

function ForgotPassword(props) {
    return <div>
        <TextField
            autoFocus
            margin="dense"
            id="email"
            label={lang.email}
            type="email"
            fullWidth
            variant='outlined'
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <EmailIcon/>
                </InputAdornment>
                ),
            }}
            onChange={props.onChangeForgotEmail}
            placeholder={lang.email_placeholder}
        />
    </div>
}

function RegisterForm(props) {
    return <div>
        <TextField
            autoFocus
            margin="dense"
            id="fullname"
            label={lang.full_name}
            type="text"
            fullWidth
            variant='outlined'
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <AccountCircleIcon/>
                </InputAdornment>
                ),
            }}
            onChange={props.onChangeFullName}
            placeholder={lang.full_name_placeholder}
        />
        <br/>
        <TextField
            id="date"
            label="Tanggal Lahir"
            type="date"
            defaultValue="2000-01-01"
            variant="outlined"
            fullWidth
            InputLabelProps={{
                shrink: true,
            }}
            style={{
                marginTop: 5
            }}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <AccountCircleIcon/>
                </InputAdornment>
                ),
            }}
            onChange={props.onChangeBirthdate}
        />
        <FormControl variant="outlined" style={{
            marginTop:8
        }} fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Jenis Kelamin</InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={params.GENDER.MALE}
                onChange={props.onChangeGender}
                label="Jenis Kelamin"
                style={{
                    marginTop: 5
                }}
            >
                <MenuItem value={params.GENDER.MALE}>
                    <em>Laki-Laki</em>
                </MenuItem>
                <MenuItem value={params.GENDER.FEMALE}>Perempuan</MenuItem>
            </Select>
        </FormControl>
        <hr/>
        <TextField
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            variant='outlined'
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <EmailIcon/>
                </InputAdornment>
                ),
            }}
            onChange={props.onChangeEmail}
            placeholder="masukan e-mail..."
        />
        <TextField
            margin="dense"
            id="katasandi"
            label="Kata Sandi"
            type="password"
            variant="outlined"
            fullWidth
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <VpnKeyIcon/>
                </InputAdornment>
                ),
            }}
            onChange={props.onChangePassword}
            placeholder="masukan kata sandi..."
        />
    </div>
}
