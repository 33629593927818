import './App.css';
import WelcomePage from './pages/welcome.pages'
import {
    BrowserRouter as Router, 
    Switch,
    Route,
    Redirect
  } from 'react-router-dom'
import { idID } from '@material-ui/core/locale'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber'
import blue from '@material-ui/core/colors/blue'
import red from '@material-ui/core/colors/red'
import { checkAdmin, checkUser } from './helper/utils';
import CourseRoute from './learning.route'
import HomePage from './pages/elearning/home.pages'
import CpanelRoute from './cpanel.route'
import DashboardPage from './pages/cpanel/dashboard.pages';
import { useSelector, useDispatch } from 'react-redux'
import FullLoader from './component/fullpageloader.component';
import FullAlert from './component/fullalert.component';
import FullDialog from './component/fulldialog.component'
import { hide_dialog } from './actions/dialog.actions'
import { hide_alert } from './actions/alert.actions'
import { useCallback, useEffect, useState } from 'react';
import lang from './helper/localize'
import ReactHowler from 'react-howler';

const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: amber,
        error: red
    }
}, idID);

function App() {
    const loader = useSelector(state => state.globalLoader)
    const dialog = useSelector(state => state.globalDialog)
    const alert = useSelector(state => state.globalAlert)
    const globalCurrentTrack = useSelector(state => state.globalCurrentTrack)
    const globalIsAudioPlaying = useSelector(state => state.globalIsAudioPlaying)

    const dispatch = useDispatch()
    const language = localStorage.getItem("lang") ?? 'ar'
    const [, updateState] = useState()
    const forceState = useCallback(() => updateState({}), [])

    function refreshLang() {
        lang.setLanguage(language)
        forceState()
    }

    useEffect(() => {
        refreshLang()
        // eslint-disable-next-line
    }, [])

    return (
        <ThemeProvider theme={theme}>
        <Router>
            <Switch>
                <Route path="/" exact={true} component={WelcomePage}/>

                {CourseRoute.map((mr, key) => {
                    return <Route key={mr.path} exact={true} path={mr.path} render={ () =>                 
                    (checkUser()) ? <HomePage component={mr.component} route={mr.route} menu={mr.menu} /> : <Redirect to="/" />
                    }/>
                })}

                {CpanelRoute.map((mr, key) => {
                    return <Route key={mr.path} exact={true} path={mr.path} render={ () =>                 
                    (checkAdmin()) ? <DashboardPage component={mr.component} route={mr.route} menu={mr.menu} /> : <Redirect to="/" />
                    }/>
                })}
                <Route path="*" component={WelcomePage}/>
            </Switch>
        </Router>
        <FullLoader isopen={loader.isopen} message={loader.message}/>
        <FullDialog isopen={dialog.isopen} title={dialog.title} message={dialog.message} handleClose={() => {
        dispatch(hide_dialog())
        }} handleOk={dialog.handleok} />
        <FullAlert isopen={alert.isopen} message={(alert.data) ? alert.data.message : ""} status={(alert.data) ? alert.data.status : "info" } handleClose={() => {dispatch(hide_alert({status: alert.data.status, message: alert.data.message}))}} />
        <ReactHowler
            src={[globalCurrentTrack]}
            playing={globalIsAudioPlaying}
        />
        </ThemeProvider>
    );
}

export default App;
