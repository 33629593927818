import React from 'react'
import { Snackbar, Grow } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

function GrowTransition(props) {
    return <Grow {...props} />;
  }

const FullAlert = props => {
    return <Snackbar
        style={{zIndex: 110000}}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={props.isopen} 
        autoHideDuration={5000} 
        TransitionComponent={GrowTransition}
        onClose={props.handleClose}>
            <Alert onClose={props.handleClose} severity={props.status}>
                {props.message}
            </Alert>
    </Snackbar>
}

export default FullAlert