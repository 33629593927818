import { Container, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { show_alert } from "../../actions/alert.actions";
import { hideloader, showloader } from "../../actions/loader.actions";
import BookCard from "../../component/bookCard.component";
import EmptyCourseCard from "../../component/emptyCourseCard.component";
import TitlePage from "../../component/titlePage.component";
import { getBookList } from "../../models/book.model";
import { enrollBook } from "../../models/enrollment.model";
import lang from "../../helper/localize";
import { setIsPlaying } from "../../actions/audio-player.actions";

export default function CoursePage() {
    const history = useHistory()
    const [bookList, setBookList] = useState([])
    const dispatch = useDispatch()
    const userDetails = JSON.parse(localStorage.getItem("auth"))
    const token = localStorage.getItem('token')

    function refreshBook() {
        dispatch(showloader(lang.loading_data))
        getBookList(userDetails.class_code[0]).then(res => {
            setBookList(res.data)
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    async function handleClickBook(detail) {
        try {
            dispatch(showloader(lang.process_data))
            await enrollBook(token, detail._id)

            history.push({
                pathname: '/course/detail',
                state: detail
            })
        } catch (error) {
            dispatch(show_alert({
                status: 'error',
                message: error.message
            }))
        }
        
    }

    useEffect(() => {
        dispatch(setIsPlaying(
            false
        ))
        refreshBook()
        // eslint-disable-next-line
    }, [])

    return <Container>
        <TitlePage title={lang.books_title} />
        <Grid container spacing={5} style={{marginTop: 20}} justify="center">
            {(bookList.length === 0) && <EmptyCourseCard message={lang.empty_books} showBookLink={false}/>}
            {(bookList.length !== 0) && bookList.map(book => <BookCard title={book.name} cover={book.cover} onClick={() => handleClickBook(book)}/> )}
        </Grid>
    </Container>
}