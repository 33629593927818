import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, Typography, Breadcrumbs } from "@material-ui/core"
import { useEffect, useState } from "react";
import UnitCard from '../../component/unitCad.component'
import { useHistory } from 'react-router';
import { loadImage } from "../../helper/utils";
import EmptyCourseCard from "../../component/emptyCourseCard.component";
import { useDispatch } from "react-redux";
import { getUnitList } from "../../models/unit.model";
import { hideloader, showloader } from "../../actions/loader.actions";
import { show_alert } from "../../actions/alert.actions";
import { Link } from 'react-router-dom'
import lang from "../../helper/localize";
import params from "../../helper/params";
import { setIsPlaying } from "../../actions/audio-player.actions";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: 'flex'
    },
    bookTitle: {
        fontSize: 32,
        fontWeight: 'bold'
    },
    titlePage: {
        fontSize: 32,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    textInput: {
        backgroundColor: 'white'
    },
    labelField: {
        textAlign: 'right',
        verticalAlign: 'middle',
    },
    cardBox: {
        border: '1px solid #e0e0e0',
        borderRadius: '8px'
    },
    cardTitle: {
        backgroundColor: '#ffc107',
        padding: '10px',
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold'
    },
    cardImage: {
        width: '100%',
        marginBottom: '10px'
    },
    cardImagePlaceholder: {
        padding: '10px',
        width: '80%',
        margin: 'auto'
    },
    unitCard: {
        backgroundColor: '#ffc107',
        padding: '3px 10px 3px 10px',
        borderRadius: '8px'
    }
}));
export default function CourseDetailPage(props) {

    const classes = useStyles()
    const history = useHistory()
    const [showAboutBook, setShowAboutBook] = useState(false)
    const bookDetails = history.location.state
    const [listUnit, setListUnit] = useState([])
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    function handleCloseAboutBook() {
        setShowAboutBook(!showAboutBook)
    }

    function refreshUnit() {
        dispatch(showloader(lang.loading_data))
        getUnitList(token, bookDetails._id).then(res => {
            setListUnit(res.data)
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    function handleClickUnit(unit) {
        history.push({
            pathname: "/course/detail/unit",
            state: {
                bookDetails,
                unit
            }
        })
    }

    useEffect(() => {
        dispatch(setIsPlaying(
            false
        ))
        refreshUnit()
        // eslint-disable-next-line
    }, [])

    return <div>
        <Grid container style={{
            marginBottom: 20
        }} dir="rtl">
            <Grid item md={12}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" to={{pathname:'/course'}} style={{
                        fontFamily: "Hacen"
                    }}>
                    {lang.books}
                </Link>
                <Typography color="textPrimary" style={{
                        fontFamily: "Hacen"
                    }}>{bookDetails.name}</Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
        <Container dir="rtl">
            <Grid container justify="center" spacing={3}>
                <Grid item md={3}>
                    <img src={(bookDetails.cover) ? loadImage(bookDetails.cover) : "../na.jpg"} alt="buku1" className={classes.cardImage} />
                    <Typography variant="h1" color="primary" className={classes.bookTitle} style={{
                        fontFamily: "Hacen"
                    }}>
                        {bookDetails.name}
                    </Typography>
                    <br/>
                    <Typography variant="p" style={{textAlign: 'left', marginTop: 30, lineHeight: 1.5, fontFamily: "Hacen"}}>
                        ISBN: {bookDetails.isbn}
                    </Typography>
                    <br/>
                    <Typography variant="p" style={{textAlign: 'left', marginTop: 20, lineHeight: 1.5, fontFamily: "Hacen"}}>
                        {lang.class_code}: {bookDetails.class_code.map(x => x.name).join(', ')}
                    </Typography>
                    <br/>
                    <br/>
                    <Button variant="contained" fullWidth color="primary" onClick={handleCloseAboutBook}>
                        {lang.about_book}
                    </Button>
                </Grid>
                <Grid item md={9} style={(lang.getLanguage() === params.LANG.ARB) ? {
                    borderRight: '1px solid #e0e0e0'
                } : {
                    borderLef: '1px solid #e0e0e0'
                }}>
                    <Typography variant="h1" color="primary" className={classes.bookTitle} style={{marginTop:-5, marginBottom: 10, fontFamily: "Hacen"}}>
                            {lang.book_detail_title}
                    </Typography>
                    <Grid container>
                        {(listUnit.length === 0) && <EmptyCourseCard message={lang.empty_unit} showBookLink={false} />}
                        {(listUnit.length !== 0) && listUnit.map(unit => <Grid item md={12} style={{
                            width: '100%'
                        }}>
                            <UnitCard title={unit.name} onClick={() => handleClickUnit(unit)} lessonCount={unit.lesson_count} quizCount={unit.quiz_count} progress={unit.progress} />
                        </Grid>)}
                    </Grid>
                </Grid>
            </Grid>

            <Dialog
                open={showAboutBook}
                onClose={handleCloseAboutBook}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                dir={
                    (lang.getLanguage() === params.LANG.ARB)
                        ? "rtl"
                        : "ltr"
                }
            >
                <DialogTitle id="alert-dialog-title">{bookDetails.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {bookDetails.sinopsys}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAboutBook} color="primary" autoFocus>
                        {lang.ok}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    </div>
}