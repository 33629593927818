import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Zoom from '@material-ui/core/Zoom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const AlertDialogGrow = props => {
  
  return (
      <Dialog
        open={props.isopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{(props.title) ? props.title : ""}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        {
            props.isopen && <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                CANCEL
                </Button>
                <Button onClick={props.handleOk} color="primary">
                OK
                </Button>
            </DialogActions>
        }

      </Dialog>
  );
}

export default AlertDialogGrow