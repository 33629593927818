import Api from '../helper/api'

export const getUnitList = (token, bookid) => {
    return new Promise(function(resolve, reject) {
        Api.get("/api/units?bookid="+bookid, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const createUnit = (token, data) => {
    return new Promise(function(resolve, reject) {
        Api.post("/api/units", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const updateUnit = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/units/"+id, data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteUnit = (token, id) => {
    return new Promise(function(resolve, reject) {
        Api.delete("/api/units/"+id, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}