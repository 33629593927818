import {
    Grid, makeStyles, Box, Typography,Tabs, Tab, Paper, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Button
} from '@material-ui/core'
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import TitleBar from "../../component/titleBar.component";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import params from '../../helper/params'
import { hideloader, showloader } from '../../actions/loader.actions';
import { changePassword, updateprofile } from '../../models/user.model';
import { show_alert } from '../../actions/alert.actions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box p={3}>
                <Typography>{children}</Typography>
            </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    content: {
        padding: 20
    }
}));

export default function AdminProfilePage() {
    const userData = JSON.parse(localStorage.getItem('auth'))
    const classes = useStyles()
    const dispatch = useDispatch()

    const token = localStorage.getItem('token')

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [fullName, setFullname] = useState(userData.name)
    function handleChangeFullName(e) {
        setFullname(e.target.value)
    }

    const [nik, setNik] = useState(userData.nik)
    function handleChangeNik(e) {
        setNik(e.target.value)
    }

    const [gender, setGender] = useState(userData.gender)
    function handleChangeGender(e) {
        setGender(e.target.value)
    }

    const [email, setEmail] = useState(userData.email)
    function handleChangeEmail(e) {
        setEmail(e.target.value)
    }

    const [oldPassword, setOldPassword] = useState("")
    function handleChangeInputOldPassword(e) {
        setOldPassword(e.target.value)
    }

    const [newPassword, setNewPassword] = useState("")
    function handleChangeInputNewPassword(e) {
        setNewPassword(e.target.value)
    }

    const [conPassword, setConPassword] = useState("")
    function handleChangeInputConPassword(e) {
        setConPassword(e.target.value)
    }

    function handleChangeProfile() {
        dispatch(showloader("Memproses data..."))
        updateprofile(token, userData.id, {
            name: fullName,
            nik,
            gender,
            email
        }).then(res => {
            userData.name = fullName
            userData.nik = nik
            userData.gender = gender
            userData.email = email

            localStorage.setItem('auth', JSON.stringify(userData))
            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    function handleChangePassword() {
        if (newPassword !== conPassword) {
            dispatch(show_alert({
                status: 'error',
                message: "Konfirmasi password baru tidak sesuai"
            }))
        } else {
            dispatch(showloader("Memproses data..."))
            changePassword(token, {
                old_password: oldPassword,
                new_password: newPassword
            }).then(res => {
                dispatch(show_alert({
                    status: 'success',
                    message: res.message
                }))
            }).catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
                setOldPassword("")
                setNewPassword("")
                setConPassword("")
            })
        }
    }

    return <div>
        <TitleBar title="Profil" subtitle="Profil data pengguna."/>
        <Tabs
            style={{backgroundColor: 'white'}}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
            aria-label="full width tabs example"
            centered={false}
        >
          <Tab label="Ubah profil" {...a11yProps(0)} />
          <Tab label="Ganti kata sandi" {...a11yProps(1)} />
        </Tabs>
        <Grid container className={classes.content}>
            <Grid item md={12}>
                <Paper elevation={1}>
                <TabPanel value={value} index={0} style={{maxWidth: 600}}>
                    <Grid item md={12}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="fullname"
                            label="Nama lengkap"
                            type="text"
                            fullWidth
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircleIcon/>
                                </InputAdornment>
                                ),
                            }}
                            className={classes.textInput}
                            placeholder="masukan nama lengkap..."
                            value={fullName}
                            onChange={handleChangeFullName}
                        />
                        <TextField
                            id="nik"
                            label="Kode Dosen"
                            type="text"
                            defaultValue=""
                            value={nik}
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                marginTop: 5
                            }}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircleIcon/>
                                </InputAdornment>
                                ),
                            }}
                            onChange={handleChangeNik}
                            placeholder="masukan kode dosen..."
                        />
                        <FormControl variant="outlined" style={{
                            marginTop:8,
                            backgroundColor: 'white',
                            textAlign: 'left'
                        }} fullWidth>
                            <InputLabel id="demo-simple-select-outlined-label">Jenis Kelamin</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={gender}
                                onChange={handleChangeGender}
                                label="Jenis Kelamin"
                                style={{
                                    marginTop: 5
                                }}
                            >
                                <MenuItem value={params.GENDER.MALE}>
                                    <em>Laki-Laki</em>
                                </MenuItem>
                                <MenuItem value={params.GENDER.FEMALE}>Perempuan</MenuItem>
                            </Select>
                        </FormControl>
                        <hr/>
                        <TextField
                            margin="dense"
                            id="email"
                            label="Email"
                            type="email"
                            fullWidth
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon/>
                                </InputAdornment>
                                ),
                            }}
                            className={classes.textInput}
                            placeholder="masukan e-mail..."
                            value={email}
                            onChange={handleChangeEmail}
                        />
                    </Grid>
                    <Grid item md={12} style={{textAlign:'right'}}>
                        <Button variant="contained" color="primary" style={{marginTop:10}} onClick={handleChangeProfile}>
                            Simpan
                        </Button>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1} style={{maxWidth: 600}}>
                    <Grid item md={12}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="oldpassword"
                            label="Password lama"
                            type="password"
                            fullWidth
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <VpnKeyIcon/>
                                </InputAdornment>
                                ),
                            }}
                            className={classes.textInput}
                            placeholder="masukan password lama..."
                            value={oldPassword}
                            onChange={handleChangeInputOldPassword}
                        />
                        <TextField
                            margin="dense"
                            id="oldpassword"
                            label="Password baru"
                            type="password"
                            fullWidth
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <VpnKeyIcon/>
                                </InputAdornment>
                                ),
                            }}
                            className={classes.textInput}
                            placeholder="masukan password baru..."
                            value={newPassword}
                            onChange={handleChangeInputNewPassword}
                        />
                        <TextField
                            margin="dense"
                            id="oldpassword"
                            label="Konfirmasi password baru"
                            type="password"
                            fullWidth
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <VpnKeyIcon/>
                                </InputAdornment>
                                ),
                            }}
                            className={classes.textInput}
                            placeholder="konfirmasi password baru..."
                            value={conPassword}
                            onChange={handleChangeInputConPassword}
                        />
                    </Grid>
                    <Grid item md={12} style={{textAlign:'right', marginTop: 10}}>
                        <Button variant="contained" color="primary" onClick={handleChangePassword}>
                            Simpan
                        </Button>
                    </Grid>
                </TabPanel>
                </Paper>
            </Grid>
        </Grid>
    </div>
}