import CoursePage from './pages/elearning/course.pages'
import ProfilePage from './pages/elearning/profile.pages'
import CourseDetailPage from './pages/elearning/courseDetail.pages'
import UnitPage from './pages/elearning/unit.pages'
import ElearningPage from './pages/elearning/elearning.pages'

const routes = [
    {
        path: '/course',
        component: CoursePage,
        menu: "COURSE"
    },
    {
        path: '/course/detail',
        component: CourseDetailPage,
        menu: "COURSE"
    },
    {
        path: '/course/detail/unit',
        component: UnitPage,
        menu: "COURSE"
    },
    {
        path: '/profile',
        component: ProfilePage,
        menu: "PROFILE"
    },
    {
        path: '/course/elearning',
        component: ElearningPage,
        menu: "COURSE"
    },
]

export default routes