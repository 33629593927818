const params = {
    AUTH: {
        LOGIN: 1,
        FORGOT: 2,
        REGISTER: 3
    },
    GENDER: {
        MALE: 1,
        FEMALE: 2
    },
    ROLE: {
        ADMIN: 1,
        FACULTY: 2,
        USER: 3
    },
    CHAPTER: {
        MUFRODAT: 1,
        MUFRODAT_TEXT: "MUFRODAT",
        TAROKIB: 2,
        TAROKIB_TEXT: "TAROKIB",
        ISTIMA: 3,
        ISTIMA_TEXT: "ISTIMA",
        ALKALAM: 4,
        ALKALAM_TEXT: "ALKALAM",
        QIROAH: 5,
        QIROAH_TEXT: "QIROAH",
        QILABAH: 6,
        QILABAH_TEXT: "QILABAH",
        HIWAR: 7,
        HIWAR_TEXT: "HIWAR"
    },
    CHAPTER_TYPE: {
        LESSON: 1,
        QUIZ: 2
    },
    CHAPTER_VARIANT: {
        ONLY_IMAGE: 1,
        ONLY_VIDEO: 2,
        LIST_AUDIO: 3,
        AUDIO_IMAGES: 4,
        AUDIO_A_B: 5,
        MULTI_CHOICE: 6,
        ESSAI: 7,
        DRAG_N_DROP: 8,
        DRAG_N_DROP_TEXT: 9,
        ONLY_TEXT: 10,
        TEXT_IMAGE: 11,
        READING: 12
    },
    LANG: {
        INA: "id",
        ARB: "ar"
    },
    LANGUAGE: {
        INDONESIA: "B.INDONESIA",
        ARABIC: "ARABIC"
    }
}

export default params