import { Typography, Button, TextField, Grid, InputAdornment, List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction, IconButton } from "@material-ui/core";
import params from "../helper/params";
import { useDispatch } from "react-redux";
import { hideloader, showloader } from "../actions/loader.actions";
import { deleteDragAndDropVariant, getChapter, uploadDragNDropVariant } from "../models/chapter.model";
import { show_alert } from "../actions/alert.actions";
import { useState } from "react";
import { hide_dialog, show_dialog } from "../actions/dialog.actions";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { loadImage } from "../helper/utils";
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from "react-router-dom";

export default function DragNDropVariant(props) {
    let chapterData = props.chapterData
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const history = useHistory()
    const {bookDetail, unitDetail} = history.location.state

    const [listItem, setListItem] = useState(
        (props.chapterData.content.length > 0)
        ? props.chapterData.content
        : []
    )

    const [itemText, setInputText] = useState('')
    function handleInputText(e) {
        setInputText(e.target.value)
    }

    const [itemImage, setItemImage] = useState('')
    function handleInputImage(e) {
        setItemImage(e.target.files[0])
    }

    function refreshListQuestion() {
        dispatch(showloader("Memuat data..."))
        getChapter(token, props.chapterData._id).then(res => {
            setListItem(res.data.content)
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    function handleSubmit() {
        dispatch(showloader("Memproses data..."))
        let formData = new FormData()
        formData.append('text', itemText)
        formData.append('image', itemImage)
        formData.append('variant', params.CHAPTER_VARIANT.DRAG_N_DROP)
        formData.append('book_id', bookDetail._id)
        formData.append('unit_id', unitDetail._id)
        uploadDragNDropVariant(token, chapterData._id, formData).then(res => {
            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
            refreshListQuestion()
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    function handleDelete (data) {
        dispatch(show_dialog({
            title: "Perhatian",
            message: "Apa Anda yakin ingin menghapus data ini?"
        }, () => {
            dispatch(hide_dialog())
            dispatch(showloader("Memproses data..."))
            deleteDragAndDropVariant(token, chapterData._id, {deleted: data})
            .then(res => {
                dispatch(show_alert({
                    status: 'success',
                    message: res.message
                }))
                refreshListQuestion()
            }).catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }))
    }

    return <div>
        <Typography>
            Kuis berupa mencocokan gambar dan text.
        </Typography>
        <Grid container spacing={2}>
            <Grid item md={5}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="itemText"
                    label="Text"
                    type="text"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <InsertDriveFileIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputText}
                    placeholder="Masukan text..."
                    value={itemText}
                />
            </Grid>
            <Grid item md={5}>
                <TextField
                    margin="dense"
                    id="itemImage"
                    label="Gambar"
                    type="file"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <InsertDriveFileIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputImage}
                    placeholder="Masukan gambar..."
                />
            </Grid>
            <Grid item md={2}>
                <Button onClick={handleSubmit} variant="contained" color="primary" fullWidth style={{
                    marginTop: 9
                }}>
                    TAMBAH
                </Button>
            </Grid>
        </Grid>
        <hr/>
        <Grid container>
            <Grid item md={12}>
            <List dense={true}>
                {(listItem.length === 0) && <ListItem>
                    <ListItemText
                        primary="Tidak ada data"
                    />
                </ListItem>}
                {(listItem.length !== 0) && listItem.map(data => <ListItem
                    key={listItem.indexOf(data)}
                >
                    <ListItemAvatar style={{width: 50}}>
                        <img src={loadImage(data.image)} alt="avatar" style={{
                            width: '100%'
                        }} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={data.text}
                        secondary={data.audio}
                        style={{paddingLeft: 10}}
                    />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(data)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>)}
                </List>
            </Grid>
        </Grid>
    </div>
}