import { Grid, LinearProgress, makeStyles } from "@material-ui/core"
import lang from "../helper/localize";
import params from "../helper/params";

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 20,
        background: '#FF9E28',
        width: '85%',
        padding: 16,
        border: '5px solid #fff',
        "&:hover": {
            cursor: 'pointer',
            border: '5px solid #13A0F2'
        },
        margin: "auto",
        fontFamily: "Hacen"
    },
    cardTitle: {
        color: 'white',
        fontSize: 24,
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        fontFamily: "Hacen",
        lineHeight: 1
    },
    cardAction: {
        textAlign: (lang.getLanguage() === params.LANG.ARB)
            ? 'left' : 'right'
    },
    cardContent: {
        color: 'white',
    }
}));

export default function CustomCard(props) {
    const classes = useStyles()
    return <div className={classes.card} onClick={props.onClick}>
        <Grid container dir={
            (lang.getLanguage() === params.LANG.ARB)
                ? "rtl"
                : "ltr"
        }>
            <Grid item md={11} className={classes.cardTitle}>
                {props.title}
            </Grid>
            <Grid item md={1} className={classes.cardAction}>
                {(lang.getLanguage() !== params.LANG.ARB) && <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C17.061 0 22 4.939 22 11C22 17.072 17.061 22 11 22L10.6923 21.9958C4.76236 21.8326 0 16.9691 0 11C0 4.939 4.928 0 11 0ZM9.416 6.358C9.196 6.358 8.987 6.435 8.822 6.6C8.503 6.93 8.503 7.447 8.833 7.766L12.078 11L8.833 14.234C8.503 14.553 8.503 15.081 8.822 15.4C9.152 15.73 9.669 15.73 9.988 15.4L13.827 11.583C13.981 11.429 14.069 11.22 14.069 11C14.069 10.78 13.981 10.571 13.827 10.417L9.988 6.6C9.834 6.435 9.625 6.358 9.416 6.358Z" fill="white"/>
                </svg>}
                
                {(lang.getLanguage() === params.LANG.ARB) && <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C4.939 0 0 4.939 0 11C0 17.072 4.939 22 11 22L11.3077 21.9958C17.2376 21.8326 22 16.9691 22 11C22 4.939 17.072 0 11 0ZM12.584 6.358C12.804 6.358 13.013 6.435 13.178 6.6C13.497 6.93 13.497 7.447 13.167 7.766L9.922 11L13.167 14.234C13.497 14.553 13.497 15.081 13.178 15.4C12.848 15.73 12.331 15.73 12.012 15.4L8.173 11.583C8.019 11.429 7.931 11.22 7.931 11C7.931 10.78 8.019 10.571 8.173 10.417L12.012 6.6C12.166 6.435 12.375 6.358 12.584 6.358Z" fill="white"/>
                </svg>}
            </Grid>
        </Grid>
        <Grid container>
            <Grid item md={12} className={classes.cardContent}>
                {props.lessonCount ?? 0} {lang.lesson} {props.quizCount ?? 0} {lang.quiz}
            </Grid>
            <Grid item md={6}>
                <LinearProgress value={props.progress} variant="determinate" />
                <span style={{color: 'white', marginTop: 3, display: 'inline-block'}}>{props.progress}%</span>
            </Grid>
        </Grid>
    </div>
}