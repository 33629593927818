import Api from '../helper/api'

export const getClassList = (token, class_id) => {
    return new Promise(function(resolve, reject) {
        let cid = (class_id !== null) ? "?class_id="+class_id.join(',') : ""
        Api.get("/api/classroom"+cid, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const addClass = (token, data) => {
    return new Promise(function(resolve, reject) {
        Api.post("/api/classroom/", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const updateClass = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/classroom/" + id, data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteClass = (token, id) => {
    return new Promise(function(resolve, reject) {
        Api.delete("/api/classroom/" + id, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const importKelas = (token, data) => {
    return new Promise(function(resolve, reject) {
        Api.post(`/api/classroom/import-classroom`, data, {
            headers: {'auth-token': token, 'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}