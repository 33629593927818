import { Container, Grid, makeStyles, IconButton } from "@material-ui/core";
import { loadAssets } from "../helper/utils";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { useDispatch, useSelector } from "react-redux";
import InstructionTextAudio from "./instructionTextAudio.component";
import EmptyCourseCard from "./emptyCourseCard.component";
import lang from "../helper/localize";
import { setCurrentTrack, setIsPlaying } from "../actions/audio-player.actions";
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 8,
        fontFamily: "trado",
        fontSize: 32
    }
}));

export default function AudioListUnit(props) {
    const audioIsPlay = useSelector(state => state.globalIsAudioPlaying)
    const currentTrack = useSelector(state => state.globalCurrentTrack)
    const classes = useStyles()
    const content = props.data.content
    const dispatch = useDispatch()

    function setAudio (audio) {
        if (currentTrack !== loadAssets(audio)) {
            dispatch(setIsPlaying(
                false
            ))
            dispatch(setCurrentTrack(
                loadAssets(audio)
            ))
            setTimeout(() => {
                dispatch(setIsPlaying(
                    true
                ))
            }, 1000);
        } else {
            dispatch(setCurrentTrack(
                loadAssets('')
            ))
            dispatch(setCurrentTrack(
                loadAssets(audio)
            ))
            setTimeout(() => {
                dispatch(setIsPlaying(
                    !audioIsPlay
                ))
            }, 1000);
        }
    }
    return <Container className={classes.root}>
        <InstructionTextAudio data={props.data} />
        <Grid container>
            <Grid item md={12}>
                {(content.length === 0) && <EmptyCourseCard
                    message={lang.empty_unit}
                    showLinkButton={false}
                />}
                <table>
                    <tbody>
                        {(content.length !== 0) && content.map(data => <tr key={content.indexOf(data)}>
                            <td><IconButton onClick={() => setAudio(data.audio)}>
                                {(currentTrack === loadAssets(data.audio) && audioIsPlay)
                                    ? <PauseCircleFilledIcon/>
                                    : <PlayCircleFilledIcon color="primary" />}
                                </IconButton></td>
                            <td>{data.text}</td>
                        </tr>)}
                    </tbody>
                </table>
            </Grid>
        </Grid>
    </Container>
}