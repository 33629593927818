import { Accordion, AccordionDetails, AccordionSummary, Breadcrumbs, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from "@material-ui/core";
import TitlePage from "../../component/titlePage.component";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link } from 'react-router-dom'
import { useEffect, useState } from "react";
import EmptyCourseCard from "../../component/emptyCourseCard.component";
import { useDispatch } from "react-redux";
import { hideloader, showloader } from "../../actions/loader.actions";
import { getCourseChapter } from "../../models/chapter.model";
import { show_alert } from "../../actions/alert.actions";
import { chapterCategoryText } from "../../helper/utils";
import { updateProgress } from "../../models/enrollment.model";
import lang from "../../helper/localize";
import params from "../../helper/params";
import { requestForScore } from "../../models/score.model";
import { setIsPlaying } from "../../actions/audio-player.actions";

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function UnitPage() {
    const classes = useStyles();
    const history = useHistory()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const userData = JSON.parse(localStorage.getItem('auth'))

    const {bookDetails, unit} = history.location.state
    const [chapterList, setChapterList] = useState([])

    function refreshChapter() {
        dispatch(showloader(lang.process_data))
        getCourseChapter(token, bookDetails._id, unit._id).then(res => {
            setChapterList(res.data)
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    async function handleTakeChapter(data, {index, key, arrayOfKey}) {
        try {
            dispatch(showloader(lang.process_data))
            if (data.type !== params.CHAPTER_TYPE.QUIZ) {
                await updateProgress(token, bookDetails._id, {
                    chapter_complete: data._id
                })
            } else {
                await requestForScore(token, {
                    user_id: userData.id,
                    book_id: bookDetails._id,
                    unit_id: unit._id,
                    chapter_id: data._id
                })
            }
            
            dispatch(hideloader())
            history.push({pathname: '/course/elearning', state: {
                bookDetails,
                unit,
                syllabus: chapterList,
                chapter: data,
                chapterIndex: chapterList[key].indexOf(data),
                chapterList: chapterList[key],
                categoryKey: arrayOfKey,
                categoryIndex: index
            }})
        } catch (error) {
            dispatch(show_alert({
                status: 'error',
                message: error.message
            }))
        }
    }

    useEffect(() => {
        dispatch(setIsPlaying(
            false
        ))
        refreshChapter()
        // eslint-disable-next-line
    }, [])
    return <div>
        <Grid container dir="rtl">
            <Grid item md={12}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" to={{pathname:'/course'}} style={{
                        fontFamily: "Hacen"
                    }}>
                    {lang.books}
                </Link>
                <Link color="primary" to={{pathname:'/course/detail', state: bookDetails}} style={{
                        fontFamily: "Hacen"
                    }}>
                    {bookDetails.name}
                </Link>
                <Typography color="textPrimary" style={{
                        fontFamily: "Hacen"
                    }}>{unit.name}</Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
        <TitlePage title={bookDetails.name+": "+unit.name} />
        <Grid container justify="center" style={{
          marginTop:20
        }}>
            <Grid item md={7}>
                <div className={classes.root}>
                    {(chapterList.length === 0) && <EmptyCourseCard message={lang.empty_unit} showBookLink={false} />}
                    {(chapterList.length !== 0) && Object.keys(chapterList).map(function (key, index, arrayOfKey) {
                        return <Accordion key={index} dir="rtl">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{color: "white"}} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{
                                backgroundColor: '#FF9E28',
                                color: 'white',
                                textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                }}
                            >
                                <Typography className={classes.heading} style={{
                        fontFamily: "Hacen"
                    }}>{chapterCategoryText(key)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{padding: 0}}>
                                <List component="nav" style={{
                                    width: '100%',
                                    fontFamily: 'trado',
                                }} >
                                    {chapterList[key].map(data => 
                                        <ListItem button onClick={() => handleTakeChapter(data, {
                                            index,
                                            key,
                                            arrayOfKey
                                        })}>
                                            <ListItemIcon>
                                                {(data.is_done)
                                                ? <CheckCircleIcon />
                                                : <RadioButtonUncheckedIcon/>} 
                                            </ListItemIcon>
                                            <ListItemText primary={
                                                <span style={{
                                                    fontFamily: 'Hacen',
                                                    fontSize: 18
                                                }}>{data.name}</span>
                                            } style={{
                                                textAlign: (lang.getLanguage() === params.LANG.ARB) ? 'right' : 'left'
                                            }} />
                                        </ListItem>
                                    )}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    })}
                </div>
            </Grid>
        </Grid>
    </div>
}