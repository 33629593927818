import { AppBar, Button, Container, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, Toolbar, Typography, useTheme } from "@material-ui/core";
import clsx from 'clsx'
import { useEffect, useState } from "react";
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LockIcon from '@material-ui/icons/Lock';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { NavLink, useHistory } from "react-router-dom";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import FooterLogo from '../../assets/images/logo-elearning1.png'
import { useDispatch } from "react-redux";
import { hideloader, showloader } from "../../actions/loader.actions";
import { logout } from "../../models/user.model";
import { show_alert } from "../../actions/alert.actions";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import params from "../../helper/params";
import ClassIcon from '@material-ui/icons/Class';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        backgroundColor: '#FAFAFA',
        height: '100vh'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    activemenu: {
        backgroundColor: '#eeeeee'
    },
    activeIcon: {
        color: "#fff"
    },
    navlink: {
        color:'inherit', 
        textDecoration:'none'
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        backgroundColor: "#fff",
        color:'#2196f3',
        height:20,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(8,0),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    footerLogo: {
        width: 150
    }
}));

export default function DashboardPage(props) {
    const Component = props.component
    const route = props.route
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const [drawOpen, setOpen] = useState(true)
    const [userName, setUserName] = useState("Admin")
    const theme = useTheme()
    const menu = props.menu
    const history = useHistory()
    const userData = JSON.parse(localStorage.getItem('auth'))
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenu = event => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null)
    };

    const handleProfile = () => {
        history.push('/cpanel/profile')
    }

    const handleLogout = () => {
        dispatch(showloader("Keluar aplikasi..."))
        logout(token).then(res => {
            localStorage.removeItem('auth')
            localStorage.removeItem('token')
            history.replace('/')
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    useEffect(() => {
        setUserName(userData.name)
    }, [userData.name])

    return <div className={classes.root}>
        <AppBar position="fixed" className={clsx(classes.appBar, {
          [classes.appBarShift]: drawOpen,
        })} style={{background: "rgba(54, 200, 255, 1)"}}>
        <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, drawOpen && classes.hide)}
            >
                <MenuIcon />
            </IconButton>
          <Typography variant="h6" className={classes.title}>
            E-LEARNING
          </Typography>
            <div>
              <Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleMenu}
                startIcon={<AccountCircle />}
              >
                  {userName}
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={handleClose}
                open={open}
              >
                <MenuItem onClick={handleProfile}>
                    {/* <NavLink to="/console/profile" className={classes.navlink}> */}
                        <ListItemIcon><AccountBoxIcon/></ListItemIcon> Profil
                    {/* </NavLink> */}
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                        <ListItemIcon><LockIcon/></ListItemIcon> Keluar
                </MenuItem>
              </Menu>
            </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={drawOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader} style={{paddingTop:0}}>
            <Container align="left" style={{paddingLeft: 10}}>
                <img src={FooterLogo} className={classes.footerLogo} alt="footer-logo"/>
            </Container>
            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon/>}
            </IconButton>
        </div>
        <Divider/>
        <List style={{marginTop:-10}}>
            {(userData.role === params.ROLE.ADMIN) && <NavLink to="/cpanel/classroom" className={classes.navlink}>
                <ListItem button={true} selected={(menu === "CLASS_ROOM") ? true : false } >
                    <ListItemIcon><ClassIcon/></ListItemIcon>
                    <ListItemText primary="Kelas" />
                </ListItem>
            </NavLink>}
            {(userData.role === params.ROLE.ADMIN) && <NavLink to="/cpanel/books" className={classes.navlink}>
                <ListItem button={true} selected={(menu === "BOOKS") ? true : false } >
                    <ListItemIcon><AssignmentIcon/></ListItemIcon>
                    <ListItemText primary="Buku" />
                </ListItem>
            </NavLink>}
            
            
            {(userData.role === params.ROLE.ADMIN) && <NavLink to="/cpanel/enrollment" className={classes.navlink}>
                <ListItem button={true} selected={(menu === "ENROLL") ? true : false}>
                    <ListItemIcon><GroupAddIcon /></ListItemIcon>
                    <ListItemText primary="Pengguna" />
                </ListItem>
            </NavLink>}

            {(userData.role === params.ROLE.ADMIN) &&<NavLink to="/cpanel/user" className={classes.navlink}>
                <ListItem button={true} selected={(menu === "USER") ? true : false }>
                    <ListItemIcon><PermContactCalendarIcon /></ListItemIcon>
                    <ListItemText primary="Dosen" />
                </ListItem>
            </NavLink>}

            {(userData.role === params.ROLE.ADMIN || userData.role === params.ROLE.FACULTY) && <NavLink to="/cpanel/score" className={classes.navlink}>
                <ListItem button={true} selected={(menu === "SCORE") ? true : false }>
                    <ListItemIcon><PlaylistAddCheckIcon /></ListItemIcon>
                    <ListItemText primary="Nilai" />
                </ListItem>
            </NavLink>}
        </List>
      </Drawer>
        <main className={clsx(classes.content, {[classes.contentShift]: drawOpen})}>
            <Component route={route}/>
        </main>
    </div>
}