import { makeStyles, Typography, Grid, TextField, InputAdornment, FormControl, Select, MenuItem, InputLabel, Button, IconButton } from "@material-ui/core";
import { useState } from "react";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useDispatch } from "react-redux";
import { hideloader, showloader } from "../actions/loader.actions";
import { deleteAudioABVariant, getChapter, uploadAudioABVariant } from "../models/chapter.model";
import { show_alert } from "../actions/alert.actions";
import { hide_dialog, show_dialog } from "../actions/dialog.actions";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 8
    },
    content: {
        padding: 20,
        margin:0,
        width: '100%'
    },
    right_answer: {
        padding: 8,
        border: "3px solid blue",
        borderRadius: 8
    }
}));

export default function AudioAB(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const [listMateri, setListMateri] = useState(props.chapterData.content)
    const [selectedAnswerText, setSelectedAnswerText] = useState('')

    const [masterAudioText, setMasterAudioText] = useState(props.chapterData.audio)
    const [masterAudio, setMasterAudio] = useState('')
    function handleInputMasterAudio(e) {
        setMasterAudio(e.target.files[0])
    }

    const [answerA, setAnswerA] = useState('')
    function handleInputAnswerA(e) {
        setAnswerA(e.target.value)
    }
    const [answerB, setAnswerB] = useState('')
    function handleInputAnswerB(e) {
        setAnswerB(e.target.value)
    }

    const [selectedRightAnswer, setSelectedRightAnswer] = useState(0)
    function handleInputSelectedRightAnswer(e) {
        let right_answer = e.target.value
        setSelectedRightAnswer(e.target.value)

        switch (right_answer) {
            case 0:
                setSelectedAnswerText(answerA)
                break;
            case 1:
                setSelectedAnswerText(answerB)
                break;
            default:
                setSelectedAnswerText(answerA)
                break;
        }
    }

    function refreshChapter() {
        dispatch(showloader("Memuat data..."))
        getChapter(token, props.chapterData._id).then(res => {
            setListMateri(res.data.content)
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    function handleDelete(data) {
        dispatch(show_dialog({
            title: "Perhatian",
            message: "Apa Anda yakin ingin menghapus konten ini?"
        }, () => {
            dispatch(hide_dialog())
            dispatch(showloader("Memproses data..."))
            deleteAudioABVariant(token, props.chapterData._id, {
                deleted: data,
                book_id: props.chapterData.book_id,
                unit_id: props.chapterData.unit_id
            }).then(res => {
                refreshChapter()
            }).catch(err => {
                dispatch(show_alert({
                    status: "error",
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }))
    }

    function handleSubmit() {
        let formData = new FormData()
        formData.append('audio', masterAudio)
        formData.append('book_id', props.chapterData.book_id)
        formData.append('unit_id', props.chapterData.unit_id)
        formData.append('option_a', answerA)
        formData.append('option_b', answerB)
        if (selectedAnswerText === "") {
            setSelectedAnswerText(answerA)
            formData.append('right_answer', answerA)
        } else {
            formData.append('right_answer', selectedAnswerText)
        }

        let chapter_id = props.chapterData._id
        dispatch(showloader("Memproses data..."))
        uploadAudioABVariant(token, chapter_id, formData).then(res => {
            refreshChapter()
            setMasterAudioText(res.data.audio)
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
            resetFormValue();
        })
    }

    function resetFormValue() {
        setAnswerA('')
        setAnswerB('')
        setSelectedRightAnswer(0)
        setSelectedAnswerText("")
        document.getElementById('instructionsAudio').value = '';
    }

    return <div className={classes.root}>
        <Typography>
            Materi terdiri dari audio dan pilihan jawaban A atau B.
        </Typography>
        <Grid container>
            <Grid item md={12}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="instructionsAudio"
                    label="Audio tunggal"
                    type="file"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <InsertDriveFileIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputMasterAudio}
                    placeholder="Masukan audio..."
                />
            </Grid>
        </Grid>
        <hr/>
        <Grid container spacing={2}>
            <Grid item md={4}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="instructionsAudio"
                    label="Pilijan jawaban A"
                    type="text"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <InsertDriveFileIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputAnswerA}
                    placeholder="Masukan pilihan jawaban A..."
                    value={answerA}
                />
            </Grid>
            <Grid item md={4}>
                <TextField
                    margin="dense"
                    id="instructionsAudio"
                    label="Pilihan Jawaban B"
                    type="text"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <InsertDriveFileIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputAnswerB}
                    placeholder="Masukan pilihan jawaban B..."
                    value={answerB}
                />
            </Grid>
            <Grid item md={2}>
                <FormControl variant="outlined" fullWidth >
                    <InputLabel style={{
                        marginTop: 8
                    }} id="demo-simple-select-outlined-label">Jawaban Benar</InputLabel>
                    <Select
                    style={{
                        height: '40px',
                        marginTop: 8
                    }}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectedRightAnswer}
                        onChange={handleInputSelectedRightAnswer}
                        label="Jawaban benar"
                    >
                        <MenuItem value={0}>
                            <em>Opsi A</em>
                        </MenuItem>
                        <MenuItem value={1}>
                            <em>Opsi B</em>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={2}>
                <Button variant="contained" color="primary" style={{
                    marginTop:10
                }} fullWidth onClick={handleSubmit}>
                    TAMBAH
                </Button>
            </Grid>
        </Grid>
        <hr/>
        <Grid container>
            <Grid item md={12}>
                <p>
                    Audio Tunggal: {masterAudioText}
                </p>
                <table style={{
                    width: '100%'
                }}>
                    <thead>
                        <tr>
                            <td width="45%">Opsi A</td>
                            <td width="45%">Opsi B</td>
                            <td width="10%">Hapus</td>
                        </tr>
                    </thead>
                    <tbody>
                        {(listMateri.length === 0) && <tr>
                            <td colSpan="3">Tidak ada data</td>
                        </tr>}
                        
                        {(listMateri.length !== 0) && listMateri.map(materi => <tr key={listMateri.indexOf(materi)}>
                            <td className={(materi.right_answer === materi.option_a) ? classes.right_answer: {}}>{materi.option_a}</td>
                            <td className={(materi.right_answer === materi.option_b) ? classes.right_answer: {}}>{materi.option_b}</td>
                            <td>
                                <IconButton onClick={() => handleDelete(materi)}>
                                    <DeleteForeverIcon/>
                                </IconButton>
                            </td>
                        </tr>)}
                    </tbody>
                    
                </table>
            </Grid>
        </Grid>
    </div>
}