import Api from '../helper/api'

export const getBookList = (class_code = null) => {
    let query = ""
    if (class_code !== null) {
        query = "?class_code="+class_code
    }
    return new Promise(function(resolve, reject) {
        Api.get("/api/books"+query).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const addBook = (token, data) => {
    return new Promise(function(resolve, reject) {
        Api.post("/api/books", data, {
            headers: {
                'auth-token': token,
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const updateBook = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/books/"+id, data, {
            headers: { 
                'auth-token': token,
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteBook = (token, id) => {
    return new Promise(function(resolve, reject) {
        Api.delete("/api/books/"+id, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}