import { Grid, Breadcrumbs, Typography, makeStyles, MenuItem, FormControl, InputLabel, Select } from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import TitleBar from "../../component/titleBar.component";
import MaterialTable from "material-table";
import tableIcons from "../../component/tableIcon.component";
import { useEffect, useState } from "react";
import params from "../../helper/params";
import ChapterInput from "../../component/chapterInput.component";
import { deleteChapter, getChapterList } from "../../models/chapter.model";
import { show_alert } from "../../actions/alert.actions";
import { useDispatch } from "react-redux";
import { hide_dialog, show_dialog } from "../../actions/dialog.actions";
import { hideloader, showloader } from "../../actions/loader.actions";
import { chapterCategoryText, chapterTypeText, chapterVariantText } from "../../helper/utils";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: 20,
        margin:0,
        width: '100%'
    },
}));

export default function ChapterManagementPage() {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    const {bookDetail, unitDetail} = history.location.state

    const [listChapter, setListChapter] = useState({
        column: [
            {title: "Nama materi", field: 'name'},
            {title: "Kategori", field: 'category_text'},
            {title: "Tipe", field: 'type_text'},
            {title: "Jenis Materi", field: 'variant_text'}
        ],
        data: []
    })

    const [selectedCategory, setSelectedCategry] = useState(params.CHAPTER.HIWAR)
    function handleSelectCategory(e) {
        refreshChapter(e.target.value)
        setSelectedCategry(e.target.value)
    }
    const [stepIndex, setStepIndex] = useState(0)
    const [chapterData, setChapterData] = useState({})
    const [isTableLoad, setIsTableLoad] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isOpenInputChapter, setIsOpenInputChapter] = useState(false)
    function handleOpenInputChapter() {
        setIsOpenInputChapter(!isOpenInputChapter)
    }

    function handleAddChapter() {
        setIsOpenInputChapter(true)
        setStepIndex(0)
        setIsEdit(false)
        setChapterData({})
    }

    function handleUpdateChapter(data) {
        setIsOpenInputChapter(true)
        setIsEdit(true)
        setStepIndex(0)
        setChapterData(data)
    }
    
    function handleDeleteChapter(data) {
        dispatch(show_dialog({
            title: "Pringatan",
            message: "Apa Anda yakin ingin menghapus materi ini?"
        }, () => {
            dispatch(hide_dialog())
            dispatch(showloader("Memproses data..."))
            deleteChapter(token, data._id).then(res => {
                refreshChapter(selectedCategory)
            }).catch(err => {
                dispatch(show_alert({
                    status: "error",
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }))
    }

    function handleDetailChapter(data) {
        setIsOpenInputChapter(true)
        setIsEdit(true)
        setStepIndex(1)
        setChapterData(data)
    }

    function refreshChapter(category) {
        setIsTableLoad(true)
        getChapterList(unitDetail._id, category).then(res => {

            for (let i = 0; i < res.data.length; i++) {
                const chapter = res.data[i];
                chapter.category_text = chapterCategoryText(chapter.category)
                chapter.type_text = chapterTypeText(chapter.type)
                chapter.variant_text = chapterVariantText(chapter.variant)
            }

            setListChapter({
                column: listChapter.column,
                data: res.data
            })
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            setIsTableLoad(false)
        })
    }

    useEffect(() => {
        refreshChapter(selectedCategory)
        // eslint-disable-next-line
    }, [])

    return <div>
        <TitleBar title="Materi" subtitle="Manajemen materi"/>
        <Grid container className={classes.content} style={{paddingBottom: 0}}>
            <Grid item md={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="primary" to={{pathname:'/cpanel/books'}}>
                        Buku
                    </Link>
                    <Link color="primary" to={{
                        pathname:'/cpanel/books/unit',
                        state: bookDetail
                    }}>
                        {bookDetail.name}
                    </Link>
                    <Typography color="textPrimary" onClick={(isOpenInputChapter) ? () => history.go(0) : () => {}} style={(isOpenInputChapter) ? {
                        textDecoration: 'underline',
                        color: 'blue',
                        cursor: 'pointer'
                    } : {}}>{unitDetail.name}</Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
        <Grid container className={classes.content}>
            <Grid item md={12}>
                {(!isOpenInputChapter) && <MaterialTable
                    icons={tableIcons}
                    title={
                        <FormControl variant="outlined" style={{
                            marginTop:12,
                            width: 200
                        }}>
                            <InputLabel id="demo-simple-select-outlined-label">Kategori</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={selectedCategory}
                                onChange={handleSelectCategory}
                                label="Kategori"
                                style={{
                                    marginTop: 5
                                }}
                            >
                                <MenuItem key={params.CHAPTER.HIWAR} value={params.CHAPTER.HIWAR}>
                                    <em>HIWAR</em>
                                </MenuItem>
                                <MenuItem key={params.CHAPTER.MUFRODAT} value={params.CHAPTER.MUFRODAT}>
                                    <em>MUFRODAT</em>
                                </MenuItem>
                                <MenuItem key={params.CHAPTER.TAROKIB} value={params.CHAPTER.TAROKIB}>
                                    <em>TAROKIB</em>
                                </MenuItem>
                                <MenuItem key={params.CHAPTER.ISTIMA} value={params.CHAPTER.ISTIMA}>
                                    <em>ISTIMA</em>
                                </MenuItem>
                                <MenuItem key={params.CHAPTER.ALKALAM} value={params.CHAPTER.ALKALAM}>
                                    <em>KALAM</em>
                                </MenuItem>
                                <MenuItem key={params.CHAPTER.QIROAH} value={params.CHAPTER.QIROAH}>
                                    <em>QIROAH</em>
                                </MenuItem>
                                <MenuItem key={params.CHAPTER.QILABAH} value={params.CHAPTER.QILABAH}>
                                    <em>KITABAH</em>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    }
                    columns={listChapter.column}
                    data={listChapter.data}
                    isLoading={isTableLoad}
                    options={{
                        header: true,
                        actionsColumnIndex:4
                    }}
                    actions={[
                        {
                            icon: tableIcons.Add,
                            tooltip: 'Tambah Materi',
                            isFreeAction: true,
                            onClick: (event) => handleAddChapter()
                        },
                        {
                            icon: tableIcons.DetailBook,
                            tooltip: 'Detail Materi',
                            onClick: (event, rowData) => handleDetailChapter(rowData)
                        },
                        {
                            icon: tableIcons.Edit,
                            tooltip: 'Ubah Materi',
                            onClick: (event, rowData) => handleUpdateChapter(rowData)
                        },
                        {
                            icon: tableIcons.Delete,
                            tooltip: 'Delete Materi',
                            onClick: (event, rowData) => handleDeleteChapter(rowData)
                        },
                    ]}
                />}

                {(isOpenInputChapter) && <ChapterInput category={selectedCategory} onClose={handleOpenInputChapter} isEdit={isEdit} unitDetail={unitDetail} bookDetail={bookDetail} chapterStepIndex={stepIndex} chapterData={chapterData} />}
                
            </Grid>
        </Grid>
    </div>
}