import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 110000,
        color: '#fff',
    },  
}))

const FullLoader = props => {

    const classes = useStyle()

    return <Backdrop className={classes.backdrop} open={props.isopen}>
        <CircularProgress color="inherit" /> <h1>&nbsp;&nbsp;{props.message}</h1>
    </Backdrop>
}

export default FullLoader