import { Grid, makeStyles, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 24,
        fontWeight: 'bold'
    },
    titleBar:{
        backgroundColor: '#eAeAeA',
        padding: 10,
        paddingLeft: 20
    }
}));

export default function TitleBar(props) {
    const classes = useStyles()
    return <Grid container className={classes.titleBar}>
        <Grid item md={12}>
            <Typography varian="h1" className={classes.title}>
                {props.title}
            </Typography>
            <Typography varian="h2" className={classes.subTitle}>
                {props.subtitle}
            </Typography>
        </Grid>
    </Grid>
}