import ImageUnit from '../component/imageUnit.component'
import params from '../helper/params'
import VideoUnit from '../component/videoUnit.component'
import AudioListUnit from '../component/audioListUnit.component'
import AudioWithImageUnit from '../component/audioWithImageUnit.component'
import AudioABUnit from '../component/audioABUnit.component'
import EmptyCourseCard from '../component/emptyCourseCard.component'
import lang from './localize'
import EssaiUnit from '../component/essaiUnit.component'
import MultiChoiceUnit from '../component/multiChoiceUnit.component'
import DragNDropUnit from '../component/dragndropUnit.component'
import TextOnlyUnit from '../component/textOnlyUnit.component'
import TextWithImageUnit from '../component/textWithImageUnit.component'
import DragNDropTextUnit from '../component/dragndropTextUnit.component'
import ReadingUnit from '../component/readingUnit.component'

export function baseurl () {
    // let baseURL = "http://localhost:4000/"
    let baseURL = "https://alhppb.uin-malang.ac.id/"
    return baseURL
}

export function checkUser() {
    const auth = JSON.parse(localStorage.getItem('auth'))
    if(auth && auth.role === params.ROLE.USER) {
        return true
    }else{
        return false
    }
}

export function checkAdmin() {
    const auth = JSON.parse(localStorage.getItem('auth'))
    if(auth && (auth.role === params.ROLE.FACULTY || auth.role === params.ROLE.ADMIN)) {
        return true
    }else{
        return false
    }
}

export function loadImage(path) {
    let _path = baseurl() + "images/na.jpg"
    if (path) {
        _path = baseurl() + path
    }
    return _path
}

export function loadAssets(path) {
    return baseurl() + path
}

export function chapterCategoryText(category) {
    switch (category) {
        case params.CHAPTER.MUFRODAT:
        case params.CHAPTER.MUFRODAT_TEXT:
            return lang.mufrodat
        case params.CHAPTER.TAROKIB:
        case params.CHAPTER.TAROKIB_TEXT:
            return lang.tarakib
        case params.CHAPTER.ISTIMA:
        case params.CHAPTER.ISTIMA_TEXT:
            return lang.istima
        case params.CHAPTER.ALKALAM:
        case params.CHAPTER.ALKALAM_TEXT:
            return lang.kalam
        case params.CHAPTER.QIROAH:
        case params.CHAPTER.QIROAH_TEXT:
            return lang.qiraah
        case params.CHAPTER.QILABAH:
        case params.CHAPTER.QILABAH_TEXT:
            return lang.kitabah
        case params.CHAPTER.HIWAR:
        case params.CHAPTER.HIWAR_TEXT:
            return lang.hiwar
        default:
            return '-'
    }
}

export function chapterTypeText(type) {
    switch (type) {
        case params.CHAPTER_TYPE.LESSON:
            return "MATERI"
        case params.CHAPTER_TYPE.QUIZ:
            return "KUIS"
        default:
            return "MATERI"
    }
}

export function chapterVariantText(variant) {
    switch (variant) {
        case params.CHAPTER_VARIANT.ONLY_IMAGE:
            return "Hanya Gambar"
        case params.CHAPTER_VARIANT.ONLY_VIDEO:
            return "Hanya Video"
        case params.CHAPTER_VARIANT.LIST_AUDIO:
            return "Teks & Audio"
        case params.CHAPTER_VARIANT.AUDIO_A_B:
            return "Audio & Kuis"
        case params.CHAPTER_VARIANT.AUDIO_IMAGES:
            return "Audio, Teks dan Gambar"
        case params.CHAPTER_VARIANT.ESSAI:
            return "Essai"
        case params.CHAPTER_VARIANT.MULTI_CHOICE:
            return "Pilihan Ganda"
        case params.CHAPTER_VARIANT.DRAG_N_DROP:
            return "Drag and Drop"
        case params.CHAPTER_VARIANT.DRAG_N_DROP_TEXT:
            return "Drag and Drop Text"
        case params.CHAPTER_VARIANT.ONLY_TEXT:
            return "Hanya text"
        case params.CHAPTER_VARIANT.TEXT_IMAGE:
            return "Text dan Gambar"
        case params.CHAPTER_VARIANT.READING:
            return "Text Bacaan dan Soal"
        default:
            return "-"
    }
}

export function variantComponent(variant, data) {
    switch (variant) {
        case params.CHAPTER_VARIANT.ONLY_IMAGE:
            return <ImageUnit data={data}/>
        case params.CHAPTER_VARIANT.ONLY_VIDEO:
            return <VideoUnit data={data} />
        case params.CHAPTER_VARIANT.LIST_AUDIO:
            return <AudioListUnit data={data} />
        case params.CHAPTER_VARIANT.AUDIO_A_B:
            return <AudioABUnit data={data} />
        case params.CHAPTER_VARIANT.AUDIO_IMAGES:
            return <AudioWithImageUnit data={data} />
        case params.CHAPTER_VARIANT.ESSAI:
            return <EssaiUnit data={data} />
        case params.CHAPTER_VARIANT.MULTI_CHOICE:
            return <MultiChoiceUnit data={data} />
        case params.CHAPTER_VARIANT.DRAG_N_DROP:
            let dropData = data.content.slice()
            let imageData = data.content.sort(() => Math.random() - 0.5)
            return <DragNDropUnit data={data} contentData={imageData} dropData={dropData}/>
        case params.CHAPTER_VARIANT.DRAG_N_DROP_TEXT:
            let dropData2 = data.content.slice()
            let textData = data.content.sort(() => Math.random() - 0.5)
            return <DragNDropTextUnit data={data} contentData={textData} dropData={dropData2} />
        case params.CHAPTER_VARIANT.TEXT_IMAGE:
            return <TextWithImageUnit data={data} />
        case params.CHAPTER_VARIANT.ONLY_TEXT:
            return <TextOnlyUnit data={data} />
        case params.CHAPTER_VARIANT.READING:
            return <ReadingUnit data={data} />
        default:
            return <EmptyCourseCard message={lang.empty_unit} showBookLink={false} />
    }
}
