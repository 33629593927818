import { Typography, Button, TextField } from "@material-ui/core";
import params from "../helper/params";
import { useDispatch } from "react-redux";
import { hideloader, showloader } from "../actions/loader.actions";
import { uploadEssaiVariant } from "../models/chapter.model";
import { show_alert } from "../actions/alert.actions";
import { useState } from "react";

export default function EssaiVariant(props) {
    let chapterData = props.chapterData
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    const [question, setQuestion] = useState(
        (props.chapterData.content.length > 0)
        ? props.chapterData.content[0].question.replace(/<\s*\/?br\s*[\/]?>/gi, "\n")
        : ''
    )
    function handleInputQuestion(e) {
        setQuestion(e.target.value)
    }

    function handleSubmit() {
        let question_br = question.replace(/\r?\n/g, '<br />');

        dispatch(showloader("Memproses data..."))
        uploadEssaiVariant(token, chapterData._id, {
            question: question_br,
            variant: params.CHAPTER_VARIANT.ESSAI
        }).then(res => {
            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
        }).catch(err => {
            dispatch(show_alert({
                status: 'success',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    return <div>
        <Typography>
            Kuis berupa essai, masukan soal essai.
        </Typography>
        <TextField
            multiline={true}
            rows={4}
            margin="dense"
            id="bookdetail"
            label="Soal Essai"
            type="text"
            fullWidth
            variant='outlined'
            onChange={handleInputQuestion}
            placeholder="masukan soal essai..."
            value={question}
        />
        <Button onClick={handleSubmit} variant="contained" color="primary">
            SIMPAN
        </Button>
    </div>
}