import { Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import lang from "../helper/localize";

const useStyles = makeStyles((theme) => ({
    congratsText: {
        fontSize: 48,
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#333',
        textShadow: '0px 6.03252px 6.03252px rgba(0, 0, 0, 0.25)',
        widht: '100%',
        fontFamily: "Hacen"
    },
    unitText:{
        position: 'absolute',
        fontSize: 48,
        color: 'white',
        fontWeight: 'bold',
        alignItems: 'center',
        textAlign: 'center',
        zIndex: 99,
        width: '100%',
        display: 'block',
        marginTop: 95,
        fontFamily: "Hacen"
    }
}));

export default function UnitComplete(props) {
    const classes = useStyles()
    return <Grid container justify="center">
            <Grid item md={12} xs={12} lg={12} style={{textAlign: 'center', marginTop:40, position: 'relative'}}>
                
            <Typography variant="h1" className={classes.congratsText}>
                {lang.chapter_complete_title}
            </Typography>

            <Hidden mdUp>
                <h1>&nbsp;{props.unit}</h1>
            </Hidden>
            
            <Hidden mdDown>
            <div className={classes.unitText}>{props.unit}</div>
            <svg width="651" height="286" viewBox="0 0 651 286" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M571.395 89.3839L578.597 97.8232L598.884 89.3839L592.946 80.5049L571.395 89.3839Z" fill="#455A64"/>
                <path d="M562.104 49.2224C562.104 51.3102 561.485 53.351 560.325 55.087C559.165 56.8229 557.516 58.1759 555.588 58.9748C553.659 59.7738 551.536 59.9828 549.489 59.5755C547.441 59.1682 545.56 58.1628 544.084 56.6866C542.608 55.2103 541.602 53.3294 541.195 51.2818C540.788 49.2341 540.997 47.1117 541.796 45.1828C542.595 43.254 543.948 41.6054 545.684 40.4455C547.419 39.2856 549.46 38.6665 551.548 38.6665C554.346 38.6738 557.026 39.7882 559.004 41.7663C560.982 43.7443 562.097 46.4251 562.104 49.2224Z" fill="#36C8FF"/>
                <path d="M325.641 206.681C325.635 207.728 325.319 208.748 324.732 209.614C324.145 210.48 323.314 211.153 322.344 211.545C321.374 211.938 320.31 212.034 319.286 211.82C318.262 211.607 317.324 211.093 316.592 210.346C315.86 209.599 315.367 208.65 315.175 207.622C314.983 206.594 315.101 205.531 315.514 204.57C315.926 203.609 316.616 202.792 317.494 202.223C318.372 201.654 319.399 201.36 320.445 201.376C321.836 201.398 323.161 201.967 324.134 202.96C325.107 203.954 325.648 205.291 325.641 206.681Z" fill="#36C8FF"/>
                <path d="M79.7486 127.347C79.7485 128.143 79.5117 128.921 79.0682 129.582C78.6247 130.243 77.9946 130.757 77.258 131.059C76.5215 131.362 75.7117 131.438 74.9317 131.278C74.1517 131.119 73.4368 130.731 72.8777 130.164C72.3187 129.598 71.9408 128.877 71.7922 128.095C71.6436 127.313 71.731 126.505 72.0432 125.772C72.3554 125.04 72.8784 124.417 73.5456 123.983C74.2128 123.548 74.9941 123.322 75.7901 123.333C76.845 123.348 77.8517 123.777 78.5926 124.528C79.3334 125.279 79.7487 126.292 79.7486 127.347Z" fill="#36C8FF"/>
                <path d="M64.8496 83.1716L76.67 83.8863L82.2778 60.6304L71.2271 61.0977L64.8496 83.1716Z" fill="#455A64"/>
                <path d="M434.306 268.807L438.292 279.967L461.877 276.009L457.122 266.03L434.306 268.807Z" fill="#36C8FF"/>
                <path d="M195.753 204.399L188.469 213.746L204.44 231.531L210.323 222.158L195.753 204.399Z" fill="#455A64"/>
                <path d="M29.8641 285.995C30.4314 286.411 141.246 258.494 141.246 258.494L98.535 186.804L0 214.526L54.256 237.378L29.8641 285.995Z" fill="#455A64"/>
                <path d="M81.8506 208.385V110.784C81.8506 110.784 234.708 90.2804 344.855 91.5038C455.002 92.7272 568.419 105.964 568.419 105.964V199.674C568.419 199.674 223.196 177.066 81.8506 208.385Z" fill="#455A64"/>
                <path d="M81.0498 208.483L141.112 258.91V199.674C141.112 199.674 90.8933 204.078 81.0498 208.483Z" fill="#263238"/>
                <path d="M81.8507 110.759C82.3512 110.759 82.5514 132.78 82.3512 159.694C82.151 186.608 81.5504 208.629 81.0833 208.629C80.6161 208.629 80.3825 186.755 80.5827 159.694C80.7829 132.633 81.3502 110.759 81.8507 110.759Z" fill="#263238"/>
                <path d="M620.139 278.508C619.572 278.924 508.758 251.006 508.758 251.006L551.368 179.292L650.004 206.965L595.714 229.818L620.139 278.508Z" fill="#455A64"/>
                <path d="M569.019 200.995L508.957 251.423V196.469C529.095 196.974 549.162 198.487 569.019 200.995Z" fill="#263238"/>
                <path d="M568.355 105.964C568.822 105.964 569.356 127.226 569.523 153.48C569.69 179.733 569.523 200.996 568.956 200.996C568.388 200.996 567.955 179.733 567.788 153.48C567.621 127.226 567.854 105.964 568.355 105.964Z" fill="#263238"/>
                
                <path d="M233.809 38.0432L251.145 55.7206L275.551 46.4976L276.525 52.5609L265.953 74.2008L275.705 90.3581L274.885 95.1916L251.264 91.212L237.293 106.532L234.014 104.056L231.127 84.5681L209.095 73.0906L208.121 68.6328L230.308 62.5695L233.809 38.0432Z" fill="#36C8FF"/>
                <g opacity="0.3">
                <path opacity="0.3" d="M233.809 38.0432L251.145 55.7206L275.551 46.4976L276.525 52.5609L265.953 74.2008L275.705 90.3581L274.885 95.1916L251.264 91.212L237.293 106.532L234.014 104.056L231.127 84.5681L209.095 73.0906L208.121 68.6328L230.308 62.5695L233.809 38.0432Z" fill="black"/>
                </g>
                <path d="M233.809 33.073L251.145 50.7675L275.551 46.4976L264.091 68.462L275.705 90.358L251.264 86.2589L234.014 104.056L230.376 79.5466L208.121 68.6328L230.308 57.5993L233.809 33.073Z" fill="#36C8FF"/>
                <path d="M413.572 38.0432L396.219 55.7206L371.812 46.4976L370.839 52.5609L381.411 74.2008L371.659 90.3581L372.478 95.1916L396.1 91.212L410.071 106.532L413.35 104.056L416.236 84.5681L438.269 73.0906L439.243 68.6328L417.056 62.5695L413.572 38.0432Z" fill="#36C8FF"/>
                <g opacity="0.3">
                <path opacity="0.3" d="M413.572 38.0432L396.219 55.7206L371.812 46.4976L370.839 52.5609L381.411 74.2008L371.659 90.3581L372.478 95.1916L396.1 91.212L410.071 106.532L413.35 104.056L416.236 84.5681L438.269 73.0906L439.243 68.6328L417.056 62.5695L413.572 38.0432Z" fill="black"/>
                </g>
                <path d="M413.571 33.073L396.218 50.7675L371.811 46.4976L383.271 68.462L371.657 90.358L396.098 86.2589L413.349 104.056L416.987 79.5466L439.241 68.6328L417.055 57.5993L413.571 33.073Z" fill="#36C8FF"/>
                <path d="M323.118 7.00952L337.55 35.208L370.838 33.2438L369.711 40.998L348.567 63.3893L354.288 86.8055L351.47 92.3905L324.16 78.5389L301.29 91.9294L298.233 87.6424L302.127 62.7574L279.616 40.366L280.129 34.576L309.506 35.6179L323.118 7.00952Z" fill="#36C8FF"/>
                <g opacity="0.3">
                <path opacity="0.3" d="M323.118 7.00952L337.55 35.208L370.838 33.2438L369.711 40.998L348.567 63.3893L354.288 86.8055L351.47 92.3905L324.16 78.5389L301.29 91.9294L298.233 87.6424L302.127 62.7574L279.616 40.366L280.129 34.576L309.506 35.6179L323.118 7.00952Z" fill="black"/>
                </g>
                <path d="M324.996 0.963379L339.428 29.1447L370.838 33.2438L348.481 55.6865L354.288 86.8055L326.055 72.4928L298.232 87.6424L303.134 56.3526L280.128 34.5761L311.401 29.5546L324.996 0.963379Z" fill="#36C8FF"/>
                </svg>
                </Hidden>
            </Grid>
        </Grid>
}