import Api from '../helper/api'

export const login = (email, password) => {
    return new Promise(function(resolve, reject) {
        Api.post("/api/users/login", {email, password}).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const logout = (token) => {
    return new Promise(function(resolve, reject) {
        Api.post("/api/users/logout", {}, {
            "headers": {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const getProfile = (token) => {
    return new Promise(function(resolve, reject) {
        Api.get("/api/users/profile", {
            "headers": {'auth-token': token}
        }).then(res => {            
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? (err.response) ? err.response.data : err : err))
    })
}

export const updateprofile = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch(`/api/users/${id}`, data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const changePassword = (token, data) => {
    return new Promise(function(resolve, reject) {
        Api.post(`/api/users/change-password`, data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const register = (data) => {
    return new Promise(function(resolve, reject) {
        Api.post('/api/users/register', data).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const addUser = (token, data) => {
    return new Promise(function(resolve, reject) {
        Api.post('/api/users/', data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const activateUser = (id, data, token) => {
    return new Promise(function(resolve, reject) {
        Api.patch(`/api/users/${id}`, data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const forgotPassword = (email) => {
    return new Promise(function(resolve, reject) {
        Api.post('/api/users/forgot-password', {email}).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const getListUser = (token, role) => {
    return new Promise(function(resolve, reject) {
        Api.get('/api/users?role='+role, {headers: {'auth-token': token}}).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteUser = (token, id) => {
    return new Promise(function(resolve, reject) {
        Api.delete('/api/users/'+id, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const importParticipant = (token, data) => {
    return new Promise(function(resolve, reject) {
        Api.post(`/api/users/import`, data, {
            headers: {'auth-token': token, 'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const importDosen = (token, data) => {
    return new Promise(function(resolve, reject) {
        Api.post(`/api/users/import-faculty`, data, {
            headers: {'auth-token': token, 'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const getClassList = (token) => {
    return new Promise(function(resolve, reject) {
        Api.get(`/api/users/class-list`, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const getUserByCode = (token, class_code) => {
    return new Promise(function(resolve, reject) {
        Api.get(`/api/users/class-code?c=${class_code}`, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}