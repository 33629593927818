import { Container, Grid, makeStyles,Typography, Button } from "@material-ui/core";
import lang from "../helper/localize";
import params from "../helper/params";
import EmptyCourseCard from "./emptyCourseCard.component";
import InstructionTextAudio from "./instructionTextAudio.component";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideloader, showloader } from "../actions/loader.actions";
import { requestForScore } from "../models/score.model";
import { updateProgress } from "../models/enrollment.model";
import { hide_dialog, show_dialog } from "../actions/dialog.actions";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 8
    },
    imagePlaceholder: {
        width: '100%',
        height: 150
    },
    kuisinfo: {
        padding: 20,
        fontSize: (lang.getLanguage() === params.LANG.ARB) ? 24 : 12
    },
    textPlaceholder: {
        padding: 8,
        border: '1px solid grey',
        backgroundColor: 'white',
        cursor: 'pointer'
    },
    fieldSet: {
        padding: 8,
        backgroundColor: '#e0e0e0'
    }
}));

export default function DragNDropTextUnit(props) {
    const classes = useStyles()
    let contentData = props.data.content
    let textData = props.contentData
    let dropAble = props.dropData
    const [tmpScore, setTmpScore] = useState(0)
    const history = useHistory()
    const {bookDetails, unit, chapter} = history.location.state
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const userData = JSON.parse(localStorage.getItem('auth'))

    function handleDrag(e, data) {
        e.dataTransfer.setData("text", e.target.id);
        e.dataTransfer.setData('data', data)
    }

    function handleOnDrop(e, data, index) {
        e.preventDefault();
        var text = e.dataTransfer.getData("text")
        e.target.appendChild(document.getElementById(text))

        if (text === data.id) {
            let score = tmpScore + 1
            setTmpScore(score)
        }
    }

    function handleOnDragOver(e) {
        e.preventDefault();
    }

    function handleSubmit() {
        let total_score = (tmpScore / contentData.length) * 100
        let score_detail = tmpScore + "/" + contentData.length
        dispatch(showloader(lang.process_data))
        requestForScore(token, {
            book_id: bookDetails._id,
            unit_id: unit._id,
            chapter_id: chapter._id,
            classcode: userData.class_code,
            score: Math.round(total_score),
            score_detail: score_detail
        }).then(async res => {
            await updateProgress(token, bookDetails._id, {
                chapter_complete: chapter._id,
                user_id: userData.id
            })
            
            dispatch(hideloader())
            dispatch(show_dialog({
                title: lang.attention,
                message: lang.formatString(lang.total_score, score_detail)
            }, () => {
                dispatch(hide_dialog())
                history.go(0)
            }))
        })
    }

    return <Container className={classes.root}>
            <InstructionTextAudio data={props.data} />
            {(contentData.length === 0) && <EmptyCourseCard
                message={lang.empty_unit}
                showLinkButton={false}/>}
            
            <Grid container style={{
                padding: 20
            }}>
                <Typography>
                    Cocokkan tulisan dengan tulisan
                </Typography>
                <Grid item md={12}>
                    <fieldset className={classes.fieldSet}>
                        <legend>Pilihan</legend>
                        <Grid container justify="center" spacing={4}>
                            {(textData.length !== 0) && textData.map((data, index) => <Grid item md={2} key={index}>
                                    <p id={data.id} className={classes.textPlaceholder} onDragStart={(e) => handleDrag(e, data)} draggable={true}>{data.text}</p>
                                </Grid>
                            )}
                        </Grid>
                    </fieldset>
                </Grid>
            </Grid>
            <br/>
            
            <Grid container justify="center" spacing={4}>
                {(dropAble.length !== 0) && dropAble.map((data, index) => {
                    return <Grid item md={2} key={index}>
                        <p style={{
                            padding: 8,
                            border: '1px solid grey',
                            backgroundColor: '#e0e0e0'
                        }}>{data.text_answer}</p>
                        <fieldset className={classes.fieldSet}>
                            <legend>Jawaban</legend>
                                <div onDrop={(e) => handleOnDrop(e, data, index)} onDragOver={handleOnDragOver} style={{width: '100%', padding: 10}}>
                                    
                                </div>
                        </fieldset>
                    </Grid>
                })}
            </Grid>
            <br/>
            <Grid container spacing={4}>
                <Grid item md={12} style={{
                    padding: 28
                }} onClick={handleSubmit}>
                    <Button variant="contained" color="primary">
                        {lang.submit}
                    </Button>
                </Grid>
            </Grid>
        </Container>
}