import { Grid, makeStyles, TextField, InputAdornment, DialogActions, Button, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { show_alert } from "../../actions/alert.actions";
import { hideloader, showloader } from "../../actions/loader.actions";
import TitleBar from "../../component/titleBar.component";
import tableIcons from '../../component/tableIcon.component'
import MaterialTable from "material-table";
import { hide_dialog, show_dialog } from "../../actions/dialog.actions";
import ClassIcon from '@material-ui/icons/Class';
import { addClass, deleteClass, updateClass, getClassList, importKelas } from "../../models/claassrom.model";
import { DropzoneDialog } from 'material-ui-dropzone';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: 20
    }
}));

export default function ClassRoomPage() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    const [listKelas, setListKelas] = useState({
        column: [
            { title: "Kode Kelas", field: "name"},
        ],
        data: []
    })

    const [openModal, setOpenModal] = useState(false)
    const [isTableLoad, setIsTableLoad] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [classCode, setClassCode] = useState('')
    const [classId, setClassId] = useState('')
    const [isOpenImport, setOpenImport] = useState(false)

    function handleEdit(data) {
        setIsEdit(true)
        setOpenModal(true)
        setClassCode(data.name)
        setClassId(data._id)
    }

    function handleOpenModal(data) {
        setIsEdit(false)
        resetFormValue()
        setOpenModal(true)
    }

    function handleInputClassCode(e) {
        setClassCode(e.target.value)
    }

    function handleDeleteClass(data) {
        dispatch(show_dialog({
            title: "Perhatian",
            message: "Apa Anda yakin ingin menghapus data nilai?"
        }, () => {
            dispatch(hide_dialog())
            dispatch(showloader("Memproses data..."))
            deleteClass(token, data._id).then(res => {
                dispatch(show_alert({
                    status: 'success',
                    message: res.message
                }))
                refreshListKelas()
            }).catch(err => {
                dispatch(show_alert({
                    status: 'success',
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }))
    }

    function refreshListKelas() {
        setIsTableLoad(true)
        getClassList(token, null).then(res => {
            setListKelas({
                column: listKelas.column,
                data: res.data
            })
        }).catch(err => {
            dispatch(show_alert({
                status: 'success',
                message: err.message
            }))
        }).finally(() => {
            setIsTableLoad(false)
        })
    }

    function handleClose() {
        setOpenModal(!openModal)
    }

    function handleSubmit() {
        dispatch(showloader("Memproses data..."))
        if (isEdit) {
            updateClass(token, classId, {name: classCode}).then(res => {
                refreshListKelas()
            }).catch(err => {
                dispatch(show_alert({
                    status: 'success',
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
                setOpenModal(false)
            })
        } else {
            addClass(token, {name: classCode}).then(res => {
                refreshListKelas()
            }).catch(err => {
                dispatch(show_alert({
                    status: 'success',
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
                setOpenModal(false)
            })
        }
    }

    function resetFormValue() {
        setClassId('')
        setClassCode('')
    }

    function handleImportKelas(file) {
        let formData = new FormData()
        formData.append('file', file[0])
        dispatch(showloader("Memproses data..."))
        importKelas(token, formData).then(res => {
            refreshListKelas()
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
            setOpenImport(false)
        })
    }

    useEffect(() => {
        refreshListKelas()
        // eslint-disable-next-line
    }, [])


    return <div>
        <TitleBar title="Kelas" subtitle="Daftar kelas" />
        <Grid container className={classes.content}>
            <Grid item md={12}>
                <MaterialTable 
                    icons={tableIcons}
                    title=""
                    columns={listKelas.column}
                    data={listKelas.data}
                    isLoading={isTableLoad}
                    options={{
                        header: true,
                        actionsColumnIndex: 1
                    }}
                    actions={[
                        {
                            icon: tableIcons.Import,
                            tooltip: 'import Kelas',
                            isFreeAction: true,
                            onClick: (event) => setOpenImport(true)
                        },
                        {
                            icon: tableIcons.Add,
                            tooltip: 'Tambah Kelas',
                            isFreeAction: true,
                            onClick: (event) => handleOpenModal()
                        },
                        {
                            icon: tableIcons.Edit,
                            tooltip: 'Masukan kode kelas',
                            onClick: (event, rowData) => handleEdit(rowData)
                        },
                        {
                            icon: tableIcons.Delete,
                            tooltip: 'Hapus data kelas',
                            onClick: (event, rowData) => handleDeleteClass(rowData)
                        },
                    ]}
                />
            </Grid>
        </Grid>
        <Dialog open={openModal} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xs">
            <DialogTitle id="form-dialog-title">{(isEdit) ? "Ubah" : "Tambah"} &nbsp;Nilai</DialogTitle>
            <DialogContent>
                <div>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="classcode"
                        label="Kode Kelas"
                        type="text"
                        fullWidth
                        variant='outlined'
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <ClassIcon/>
                            </InputAdornment>
                            ),
                        }}
                        onChange={handleInputClassCode}
                        placeholder="masukan kode kelas..."
                        value={classCode}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Batalkan
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    Kirim
                </Button>
            </DialogActions>
        </Dialog>
        <DropzoneDialog
            dialogTitle="Impor Kelas"
            dropzoneText="seret dan jatuhkan file atau klik untuk impor Kelas"
            acceptedFiles={['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
            cancelButtonText="Batal"
            submitButtonText="Kirim"
            showFileNames={true}
            useChipsForPreview={true}
            showPreviewsInDropzone={true}
            maxFileSize={5000000}
            open={isOpenImport}
            onClose={() => setOpenImport(!isOpenImport)}
            onSave={handleImportKelas}
            showPreviews={false}
            filesLimit={1}
            clearOnUnmount={true}
        />
    </div>
}