import { makeStyles, Typography, Grid, IconButton, TextField, InputAdornment, Button, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction } from "@material-ui/core";
import { useState } from "react";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { loadImage } from "../helper/utils";
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from "react-redux";
import { hideloader, showloader } from "../actions/loader.actions";
import { deleteAudioTextImageVariant, getChapter, uploadAudioTextImageVariant } from "../models/chapter.model";
import { show_alert } from "../actions/alert.actions";
import { hide_dialog, show_dialog } from "../actions/dialog.actions";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: 20,
        margin:0,
        width: '100%'
    },
    imagePlaceholder: {
        width: '100%'
    },
    deleteVariantButton: {
        position: 'absolute',
        textAlign: 'right',
        width: '115px',
        marginTop: -15
    },
    playVariantButton: {
        position: 'absolute',
        textAlign: 'left',
        width: '50px',
        marginTop: -15,
        marginLeft: -20
    }
}));

export default function AudioImageVariant(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [listMateri, setListMateri] = useState(props.chapterData.content)
    let token = localStorage.getItem('token')

    const [imageInstruction, setImageInstruction] = useState('')
    function handleInputImageInstruction(e) {
        setImageInstruction(e.target.files[0])
    }
    const [audioInstruction, setAudioInstruction] = useState('')
    function handleInputAudioInstruction(e) {
        setAudioInstruction(e.target.files[0])
    }

    const [audioText, setAudioText] = useState('')
    function handleInputAudioText(e) {
        setAudioText(e.target.value)
    }

    function refreshChapter() {
        dispatch(showloader("Memuat data..."))
        getChapter(token, props.chapterData._id).then(res => {
            setListMateri(res.data.content)
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    function handleSubmit() {
        let formData = new FormData()
        formData.append('image', imageInstruction)
        formData.append('audio', audioInstruction)
        formData.append('audio_text', audioText)
        formData.append('book_id', props.chapterData.book_id)
        formData.append('unit_id', props.chapterData.unit_id)

        let chapter_id = props.chapterData._id
        dispatch(showloader("Memproses data..."))
        uploadAudioTextImageVariant(token, chapter_id, formData).then(res => {
            refreshChapter()
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
            resetFormValue();
        })
    }

    function resetFormValue() {
        setAudioText('');
        document.getElementById('instructionsImage').value = '';
        document.getElementById('instructionsAudio').value = '';
    }

    function handleDeleteAudio(file) {
        dispatch(show_dialog({
            title: "Perhatian",
            message: "Apa Anda yakin ingin menghapus konten ini?"
        }, () => {
            dispatch(hide_dialog())
            dispatch(showloader("Memproses data..."))
            deleteAudioTextImageVariant(token, props.chapterData._id, {
                deleted_content: file
            }).then(res => {
                refreshChapter()
            }).catch(err => {
                dispatch(show_alert({
                    status: "error",
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }))
    }

    return <div>
        <Typography>
            Materi terdiri dari teks, audio disertai gambar.
        </Typography>
        <Grid container>
            <Grid item md={12}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="audioteks"
                    label="Text"
                    type="text"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <InsertDriveFileIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputAudioText}
                    placeholder="Masukan text audio..."
                    value={audioText}
                />
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item md={5}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="instructionsImage"
                    label="Image"
                    type="file"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <InsertDriveFileIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputImageInstruction}
                    placeholder="Masukan gambar..."
                />
            </Grid>
            <Grid item md={5}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="instructionsAudio"
                    label="Audio"
                    type="file"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <InsertDriveFileIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputAudioInstruction}
                    placeholder="Masukan audio..."
                />
            </Grid>
            <Grid item md={2} style={{marginTop:10}}>
                <Button color="primary" variant="contained" fullWidth onClick={handleSubmit}>
                    Tambah
                </Button>
            </Grid>
        </Grid>
        <hr/>
        <Grid container spacing={2}>
            <Grid item md={12}>
                <List dense={false}>
                    {(listMateri.length === 0) && <ListItem>
                        <ListItemText
                            primary="Tidak ada data"
                        />
                    </ListItem>}
                    {(listMateri.length !== 0) && listMateri.map(materi => <ListItem
                        key={listMateri.indexOf(materi)}
                    >
                        <ListItemAvatar style={{width: 50}}>
                            <img src={loadImage(materi.image)} alt="avatar" className={classes.imagePlaceholder}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary={materi.text}
                            secondary={materi.audio}
                            style={{paddingLeft: 10}}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteAudio(materi)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>)}
                </List>
            </Grid>
        </Grid>
    </div>
}