import { Container, Grid, makeStyles, Button } from "@material-ui/core";
import { loadAssets, loadImage } from "../helper/utils";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import EmptyCourseCard from "./emptyCourseCard.component";
import lang from "../helper/localize";
import params from "../helper/params";
import InstructionTextAudio from "./instructionTextAudio.component";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTrack, setIsPlaying } from "../actions/audio-player.actions";
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 16,
        fontFamily: "trado",
        fontSize: 32
    },
    placeholder:{
        textAlign:'center',
        border: '1px dashed #e0e0e0',
    },
    ilustration: {
        margin: 'auto',
        textAlign: 'center',
        width: '100%',
        height: 200,
        objectFit: "contain",
        backgroundColor: 'grey'
    }
}));

export default function AudioWithImageUnit(props) {
    const classes = useStyles()
    const audioIsPlay = useSelector(state => state.globalIsAudioPlaying)
    const currentTrack = useSelector(state => state.globalCurrentTrack)
    const dispatch = useDispatch()

    const content = props.data.content
    function setAudio (audio) {
        if (currentTrack !== loadAssets(audio)) {
            dispatch(setIsPlaying(
                false
            ))
            dispatch(setCurrentTrack(
                loadAssets(audio)
            ))
            setTimeout(() => {
                dispatch(setIsPlaying(
                    true
                ))
            }, 1000);
        } else {
            dispatch(setCurrentTrack(
                loadAssets('')
            ))
            dispatch(setCurrentTrack(
                loadAssets(audio)
            ))
            setTimeout(() => {
                dispatch(setIsPlaying(
                    !audioIsPlay
                ))
            }, 1000);
        }
    }

    return <Container className={classes.root} dir={
        (lang.getLanguage() === params.LANG.ARB)
            ? "rtl"
            : "ltr"
    }>
        <InstructionTextAudio data={props.data} />
        <Grid container justify="flex-start" spacing={5} className={classes.root}>
            {(content.length === 0) && <Grid item md={12}><EmptyCourseCard message={lang.empty_unit} showLinkButton={false} /></Grid>}

            {(content.length !== 0) && content.map(data => <Grid item md={3} className={classes.placeholder}>
                <img alt="img" src={loadImage(data.image)} className={classes.ilustration} />
                <Button onClick={() => setAudio(data.audio)} variant="outlined" fullWidth>
                    {(currentTrack === loadAssets(data.audio) && audioIsPlay)
                        ? <PauseCircleFilledIcon/>
                        : <PlayCircleFilledIcon color="primary" />}
                    &nbsp; <span style={{
                        fontFamily: "trado",
                        fontSize: 32
                    }}>{data.text}</span>
                </Button>
            </Grid>)}
        </Grid>
    </Container>
}