import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    titlePage: {
      fontSize: 32,
      fontWeight: 'bold',
      textAlign: 'center'
    },
}));

export default function TitlePage(props) {
    const classes = useStyles()
    return <Typography color="primary" variant="h1" className={classes.titlePage} style={{
        fontFamily: "Hacen"
    }}>
        {props.title}
    </Typography>
}