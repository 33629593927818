import { Grid, makeStyles, Stepper, Step, StepLabel, Paper, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, Button } from "@material-ui/core";
import { useState } from "react";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { chapterCategoryText } from "../helper/utils";
import params from "../helper/params";
import ImageOnlyVariant from "./imageOnly.variant";
import VideoOnlyVariant from "./videoOnly.variant";
import ListAudioVariant from "./listAudio.variant";
import AudioImageVariant from "./audioImage.variant";
import AudioAB from "./audioAB.variant";
import { useDispatch } from "react-redux";
import { hideloader, showloader } from "../actions/loader.actions";
import { createChapter, resetChapterContent, updateChapter } from "../models/chapter.model";
import { show_alert } from "../actions/alert.actions";
import { hide_dialog, show_dialog } from "../actions/dialog.actions";
import { useHistory } from "react-router-dom";
import EssaiVariant from "./essai.variant";
import MultiChoiceVariant from "./multiChoice.variant";
import DragNDropVariant from "./dragndrop.variant";
import ImageTextVariant from "./imageText.variant";
import OnlyTextVariant from "./onlyText.variant";
import DragNDropTextVariant from "./dragndropText.variant";
import ReadingVariant from "./reading.variant";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: 20,
        margin:0,
        width: '100%'
    },
}));

export default function ChapterInput(props) {
    const classes = useStyles()
    const stepLabel = [
        "Detail Materi",
        "Isi Materi"
    ]

    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const history = useHistory()

    const [chapterData, setChapterData] = useState(props.chapterData ?? {})
    const [chapterName, setChapterName] = useState(props.chapterData.name ?? '')
    function handleInputChapterName(e) {
        setChapterName(e.target.value)
    }

    const [selectedChapterType, setSelectedChapterType] = useState(props.chapterData.type ?? params.CHAPTER_TYPE.LESSON)
    
    function handleInputChapterType(e) {
        setSelectedChapterType(e.target.value)
    }

    function handleNextStep() {
        dispatch(showloader("Memproses data..."))
        let formData = new FormData()
        formData.append('name', chapterName)
        formData.append('category', props.category)
        formData.append('type', selectedChapterType)
        formData.append('unit_id', props.unitDetail._id)
        formData.append('book_id', props.bookDetail._id)
        formData.append('instructions', instructionText)
        formData.append('instructions_audio', instructionAudio)

        if (props.isEdit) {
            updateChapter(token, chapterData._id, formData).then(res => {
                dispatch(show_alert({
                    status: 'success',
                    message: res.message
                }))
                history.go(0)
            }).catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        } else {
            createChapter(token, formData).then(res => {
                setChapterData(res.data)
                if (res.data.type === params.CHAPTER_TYPE.LESSON) {
                    setSelectedVariant(params.CHAPTER_VARIANT.ONLY_IMAGE)
                } else {
                    setSelectedVariant(params.CHAPTER_VARIANT.MULTI_CHOICE)
                }
                setStepIndex(1)
            }).catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }
    }

    function handleFinish() {
        history.go(0)
    }

    let variantType = (
        props.chapterData.type === params.CHAPTER_TYPE.LESSON
        || selectedChapterType === params.CHAPTER_TYPE.LESSON
    )
        ? params.CHAPTER_VARIANT.ONLY_IMAGE
        : params.CHAPTER_VARIANT.MULTI_CHOICE

    const [selectedVariant, setSelectedVariant] = useState(
        props.chapterData.variant ?? variantType
    )

    function handleInputSelectedVariant(e) {
        if (props.isEdit) {
            dispatch(show_dialog({
                title: "Peringatan",
                message: "Merubah jenis materi akan menghapus data materi sebelumnya"
            }, async () => {
                dispatch(hide_dialog())
                dispatch(showloader("Memproses data..."))
                try {
                    await resetChapterContent(token, chapterData._id, selectedVariant, e.target.value)

                    dispatch(hideloader())
                    setSelectedVariant(e.target.value)
                    
                } catch (error) {
                    dispatch(show_alert({
                        status: 'error',
                        message: error.message
                    }))
                }
                
            }))
        } else {
            setSelectedVariant(e.target.value)
        }
    }

    const [instructionText, setInstructionText] = useState(props.chapterData.instructions ?? "")
    function handleInputInstructionText(e) {
        setInstructionText(e.target.value)
    }
    const [instructionAudio, setInstructionAudio] = useState("")
    const [instructionAudioPath, setInstructionAudioPath] = useState(props.chapterData.instructions_audio ?? "")
    function handleInputInstructionAudio(e) {
        let file = e.target.files[0]
        var url = URL.createObjectURL(file)
        setInstructionAudio(file)
        setInstructionAudioPath(url)
    }

    const [stepIndex, setStepIndex] = useState(props.chapterStepIndex)
    function stepContent(index) {
        switch (index) {
            case 0:
                return DetailMateri()
            case 1:
                return IsiMateri()
            default:
                return DetailMateri()
        }
    }

    function DetailMateri() {
        return <div>
            <TextField
                autoFocus
                margin="dense"
                id="chaptername"
                label="Nama Materi"
                type="text"
                fullWidth
                variant='outlined'
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <InsertDriveFileIcon/>
                    </InputAdornment>
                    ),
                }}
                onChange={handleInputChapterName}
                placeholder="Masukan nama materi..."
                value={chapterName}
            />
            <TextField
                disabled={true}
                margin="dense"
                id="category"
                label="Kategori"
                type="text"
                fullWidth
                variant='outlined'
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <InsertDriveFileIcon/>
                    </InputAdornment>
                    ),
                }}
                placeholder="Masukan ktegori..."
                value={chapterCategoryText(props.category)}
            />
            <FormControl variant="outlined" style={{
                marginTop:12,
                width: '100%'
            }} fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Tipe</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedChapterType}
                    onChange={handleInputChapterType}
                    label="Tipe"
                >
                    <MenuItem value={params.CHAPTER_TYPE.LESSON}>
                        <em>MATERI</em>
                    </MenuItem>
                    <MenuItem value={params.CHAPTER_TYPE.QUIZ} disabled={false}>
                        <em>KUIS</em>
                    </MenuItem>
                </Select>
            </FormControl>
            <TextField
                margin="dense"
                id="instructions"
                label="Instruksi text"
                type="text"
                fullWidth
                variant='outlined'
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <InsertDriveFileIcon/>
                    </InputAdornment>
                    ),
                }}
                onChange={handleInputInstructionText}
                placeholder="Masukan instruksi..."
                value={instructionText}
            />
            <table>
                <tr>
                    <td>Audio</td>
                    <td>:</td>
                    <td>{instructionAudioPath ?? '-'}</td>
                </tr>
            </table>
            <TextField
                margin="dense"
                id="instructionsAudio"
                label="Instruksi Audio"
                type="file"
                fullWidth
                variant='outlined'
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <InsertDriveFileIcon/>
                    </InputAdornment>
                    ),
                }}
                onChange={handleInputInstructionAudio}
                placeholder="Masukan instruksi audio..."
            />
            <div style={{
                marginTop: 10,
                textAlign: 'right'
            }}>
                <Button variant="outlined" style={{marginRight: 10}} onClick={props.onClose}>
                    Batalkan
                </Button>
                <Button variant="contained" color="primary" onClick={handleNextStep}>
                    LANJUT
                </Button>
            </div>
        </div>
    }
    
    function IsiMateri() {
        return <div>

            <FormControl variant="outlined" style={{
                marginTop:12,
                width: '100%'
            }} fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Jenis Materi</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedVariant}
                    onChange={handleInputSelectedVariant}
                    label="Jenis Materi"
                >
                    {(props.chapterData.type === params.CHAPTER_TYPE.LESSON || selectedChapterType === params.CHAPTER_TYPE.LESSON) && <MenuItem value={params.CHAPTER_VARIANT.ONLY_TEXT}>
                        <em>Hanya Teks</em>
                    </MenuItem>}
                    {(props.chapterData.type === params.CHAPTER_TYPE.LESSON || selectedChapterType === params.CHAPTER_TYPE.LESSON) && <MenuItem value={params.CHAPTER_VARIANT.ONLY_IMAGE}>
                        <em>Hanya Gambar</em>
                    </MenuItem>}
                    {(props.chapterData.type === params.CHAPTER_TYPE.LESSON || selectedChapterType === params.CHAPTER_TYPE.LESSON) && <MenuItem value={params.CHAPTER_VARIANT.ONLY_VIDEO}>
                        <em>Hanya Video</em>
                    </MenuItem>}
                    {(props.chapterData.type === params.CHAPTER_TYPE.LESSON || selectedChapterType === params.CHAPTER_TYPE.LESSON) && <MenuItem value={params.CHAPTER_VARIANT.LIST_AUDIO}>
                        <em>Teks dan Audio</em>
                    </MenuItem>}
                    {(props.chapterData.type === params.CHAPTER_TYPE.LESSON || selectedChapterType === params.CHAPTER_TYPE.LESSON) && <MenuItem value={params.CHAPTER_VARIANT.TEXT_IMAGE}>
                        <em>Teks dan Gambar</em>
                    </MenuItem>}
                    {(props.chapterData.type === params.CHAPTER_TYPE.LESSON || selectedChapterType === params.CHAPTER_TYPE.LESSON) && <MenuItem value={params.CHAPTER_VARIANT.AUDIO_IMAGES}>
                        <em>Audio, Gambar dan Teks</em>
                    </MenuItem>}
                    {(props.chapterData.type === params.CHAPTER_TYPE.LESSON || selectedChapterType === params.CHAPTER_TYPE.LESSON) && <MenuItem value={params.CHAPTER_VARIANT.AUDIO_A_B}>
                        <em>Audio dan Jawaban benar/salah</em>
                    </MenuItem>}

                    {(props.chapterData.type === params.CHAPTER_TYPE.QUIZ || selectedChapterType === params.CHAPTER_TYPE.QUIZ) && <MenuItem value={params.CHAPTER_VARIANT.MULTI_CHOICE}>
                        <em>Plihan Ganda</em>
                    </MenuItem>}
                    {(props.chapterData.type === params.CHAPTER_TYPE.QUIZ || selectedChapterType === params.CHAPTER_TYPE.QUIZ) && <MenuItem value={params.CHAPTER_VARIANT.ESSAI}>
                        <em>Essai</em>
                    </MenuItem>}
                    {(props.chapterData.type === params.CHAPTER_TYPE.QUIZ || selectedChapterType === params.CHAPTER_TYPE.QUIZ) && <MenuItem value={params.CHAPTER_VARIANT.DRAG_N_DROP}>
                        <em>Menjodohkan Dengan Gambar</em>
                    </MenuItem>}

                    {(props.chapterData.type === params.CHAPTER_TYPE.QUIZ || selectedChapterType === params.CHAPTER_TYPE.QUIZ) && <MenuItem value={params.CHAPTER_VARIANT.DRAG_N_DROP_TEXT}>
                        <em>Menjodohkan Dengan Text</em>
                    </MenuItem>}

                    {(props.chapterData.type === params.CHAPTER_TYPE.QUIZ || selectedChapterType === params.CHAPTER_TYPE.QUIZ) && <MenuItem value={params.CHAPTER_VARIANT.READING}>
                        <em>Text Bacaan dan Soal</em>
                    </MenuItem>}
                </Select>
            </FormControl>
            <hr/>
            <fieldset>
                <legend>Isi Materi</legend>
                <div>
                    {variantPlaceholder(selectedVariant)}
                </div>
            </fieldset>
            <div style={{
                marginTop: 10,
                textAlign: 'right'
            }}>
                <Button variant="outlined" style={{marginRight: 10}} onClick={props.onClose}>
                    Batalkan
                </Button>
                <Button variant="contained" color="primary" onClick={handleFinish}>
                    SELESAI
                </Button>
            </div>
        </div>
    }

    function variantPlaceholder(variant) {
        switch (variant) {
            case params.CHAPTER_VARIANT.ONLY_IMAGE:
                return <ImageOnlyVariant chapterData={chapterData} />
            case params.CHAPTER_VARIANT.ONLY_VIDEO:
                return <VideoOnlyVariant chapterData={chapterData}/>
            case params.CHAPTER_VARIANT.LIST_AUDIO:
                return <ListAudioVariant chapterData={chapterData}/>
            case params.CHAPTER_VARIANT.AUDIO_IMAGES:
                return <AudioImageVariant chapterData={chapterData}/>
            case params.CHAPTER_VARIANT.AUDIO_A_B:
                return <AudioAB chapterData={chapterData}/>
            case params.CHAPTER_VARIANT.MULTI_CHOICE:
                return <MultiChoiceVariant chapterData={chapterData}/>
            case params.CHAPTER_VARIANT.ESSAI:
                return <EssaiVariant chapterData={chapterData}/>
            case params.CHAPTER_VARIANT.DRAG_N_DROP:
                return <DragNDropVariant chapterData={chapterData} />
            case params.CHAPTER_VARIANT.DRAG_N_DROP_TEXT:
                return <DragNDropTextVariant chapterData={chapterData} />
            case params.CHAPTER_VARIANT.ONLY_TEXT:
                return <OnlyTextVariant chapterData={chapterData} />
            case params.CHAPTER_VARIANT.TEXT_IMAGE:
                return <ImageTextVariant chapterData={chapterData} />
            case params.CHAPTER_VARIANT.READING:
                return <ReadingVariant chapterData={chapterData} />
            default:
                break;
        }
    }

    return <Paper>
        <Grid container className={classes.content}>
            <Grid item md={12}>
                <Grid container justify="center">
                    <Grid item md={6}>
                        <Stepper activeStep={stepIndex} alternativeLabel style={{
                            display: (props.isEdit)
                                ? 'none'
                                : ''
                        }}>
                            {stepLabel.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </Grid>
                <Grid container justify="center">
                    <Grid item md={10}>
                        {stepContent(stepIndex)}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Paper>
}