import { Typography, Grid, IconButton, TextField, InputAdornment, Button, List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { useState } from "react";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { useDispatch } from "react-redux";
import { hideloader, showloader } from "../actions/loader.actions";
import { deleteImageTextVariant, getChapter, uploadImageTextVariant } from "../models/chapter.model";
import { show_alert } from "../actions/alert.actions";
import { hide_dialog, show_dialog } from "../actions/dialog.actions";

export default function ImageTextVariant(props) {
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    const [listMateri, setListMateri] = useState(props.chapterData.content)

    const [textInstruction, setTextIntruction] = useState('')
    function handleInputTextInstruction(e) {
        setTextIntruction(e.target.value)
    }
    const [imageInstruction, setImageInstruction] = useState('')
    function handleInputImageInstruction(e) {
        setImageInstruction(e.target.files[0])
    }

    function refreshChapter() {
        dispatch(showloader("Memuat data..."))
        getChapter(token, props.chapterData._id).then(res => {
            setListMateri(res.data.content)
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    function resetFormValue() {
        setTextIntruction('');
        document.getElementById('instructionsImage').value = '';
    }

    function handleDeleteImage(file) {
        dispatch(show_dialog({
            title: "Perhatian",
            message: "Apa Anda yakin ingin menghapus konten ini?"
        }, () => {
            dispatch(hide_dialog())
            dispatch(showloader("Memproses data..."))
            deleteImageTextVariant(token, props.chapterData._id, {
                deleted_content: file
            }).then(res => {
                refreshChapter()
            }).catch(err => {
                dispatch(show_alert({
                    status: "error",
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }))
    }

    function handleSubmit() {
        let formData = new FormData()
        formData.append('text', textInstruction)
        formData.append('image', imageInstruction)
        formData.append('book_id', props.chapterData.book_id)
        formData.append('unit_id', props.chapterData.unit_id)

        let chapter_id = props.chapterData._id
        dispatch(showloader("Memproses data..."))
        uploadImageTextVariant(token, chapter_id, formData).then(res => {
            refreshChapter()
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
            resetFormValue();
        })
    }


    return <div>
        <Typography>
            Materi terdiri dari gambar disertai text.
        </Typography>
        <Grid container spacing={2}>
            <Grid item md={5}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="instructionsText"
                    label="Text"
                    type="text"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <InsertDriveFileIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputTextInstruction}
                    placeholder="Masukan text..."
                    value={textInstruction}
                />
            </Grid>
            <Grid item md={5}>
                <TextField
                    margin="dense"
                    id="instructionsImage"
                    label="Image"
                    type="file"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <InsertDriveFileIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputImageInstruction}
                    placeholder="Masukan gambar..."
                />
            </Grid>
            <Grid item md={2} style={{marginTop:10}}>
                <Button color="primary" variant="contained" fullWidth onClick={handleSubmit}>
                    Tambah
                </Button>
            </Grid>
        </Grid>
        <hr/>
        <Grid container spacing={2}>
            <Grid item md={12}>
                <List dense={false}>
                    {(listMateri.length === 0) && <ListItem>
                        <ListItemText
                            primary="Tidak ada data"
                        />
                    </ListItem>}
                    {(listMateri.length !== 0) && listMateri.map(materi => <ListItem
                        key={listMateri.indexOf(materi)}
                    >
                        <ListItemAvatar style={{width: 50}}>
                            {listMateri.indexOf(materi) + 1}
                        </ListItemAvatar>
                        <ListItemText
                            primary={materi.text}
                            secondary={materi.image}
                            style={{paddingLeft: 10}}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteImage(materi)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>)}
                </List>
            </Grid>
        </Grid>
    </div>
}