import { Grid, makeStyles, FormControl, InputLabel, Select, MenuItem, Typography, TextField, InputAdornment, DialogActions, Button, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { show_alert } from "../../actions/alert.actions";
import { hideloader, showloader } from "../../actions/loader.actions";
import { deleteScore, getScoreList, updateScore } from "../../models/score.model";
import TitleBar from "../../component/titleBar.component";
import tableIcons from '../../component/tableIcon.component'
import MaterialTable from "material-table";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { baseurl, chapterCategoryText } from "../../helper/utils";
import { updateProgress } from "../../models/enrollment.model";
import params from "../../helper/params";
import { hide_dialog, show_dialog } from "../../actions/dialog.actions";
import { getClassList } from "../../models/claassrom.model";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: 20
    }
}));

export default function ScoreManagementPage() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const userData = JSON.parse(localStorage.getItem('auth'))

    const [listPengguna, setListPengguna] = useState({
        column: [
            { title: "Nama", field: "user_id.name"},
            { title: "NIM", field: "user_id.nik"},
            { title: "Buku", field: "book_id.name"},
            { title: "Unit", field: "unit_id.name"},
            { title: "Materi", field: "chapter_id.name"},
            { title: "Katergori", field: "chapter_id.category_text"},
            { title: "Kode Kelas", field: "classcode.name"},
            { title: "Nilai", field: "score_detail"}
        ],
        data: []
    })

    const [listClassCode, setListClassCode] = useState([])
    const [selectedClassCode, setSelectedClassCode] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [isTableLoad, setIsTableLoad] = useState(false)
    const [score, setScore] = useState(0)
    const [selectedUser, setSelectedUser] = useState({})
    const [attachment, setAttachment] = useState([])

    function handleOnChangeClassCode(e) {
        setSelectedClassCode(e.target.value)
        refreshListPengguna(e.target.value)
    }

    function handleOpenModal(data) {
        setScore(data.score)
        setSelectedUser(data)
        setOpenModal(true)
        setAttachment(data.attachment)
    }

    function handleInputScore(e) {
        setScore(e.target.value)
    }

    function handleDeleteScore(data) {
        dispatch(show_dialog({
            title: "Perhatian",
            message: "Apa Anda yakin ingin menghapus data nilai?"
        }, () => {
            dispatch(hide_dialog())
            dispatch(showloader("Memproses data..."))
            deleteScore(token, data._id).then(res => {
                refreshListPengguna(selectedClassCode)
                dispatch(show_alert({
                    status: "success",
                    message: res.message
                }))
            }).catch(err => {
                dispatch(show_alert({
                    status: "error",
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }))
    }

    function refreshListPengguna(classCode = null) {
        setIsTableLoad(true)
        getScoreList(token, classCode).then(res => {

            for (let i = 0; i < res.data.length; i++) {
                const pengguna = res.data[i];
                if (pengguna.chapter_id) {
                    pengguna.chapter_id.category_text = chapterCategoryText((pengguna.chapter_id) ? pengguna.chapter_id.category : '-')
                }
            }

            setListPengguna({
                column: listPengguna.column,
                data: res.data
            })
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            setIsTableLoad(false)
        })
    }

    function refreshListClass() {
        let class_id = (userData.role === params.ROLE.FACULTY) ? userData.class_code : null
        getClassList(token, class_id).then(res => {
            setListClassCode(res.data)
            let init_classcode = (userData.role === params.ROLE.ADMIN)
                ? res.data[0]._id
                : userData.class_code[0]
            setSelectedClassCode(init_classcode)
            refreshListPengguna(init_classcode)
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        })
    }

    function handleClose() {
        setOpenModal(!openModal)
    }

    function submitScore() {
        dispatch(showloader("Memproses data..."))
        updateScore(token, selectedUser._id, {score}).then(async (res) => {
            await updateProgress(token, res.data.book_id, {
                chapter_complete: res.data.chapter_id,
                user_id: res.data.user_id
            })
            refreshListPengguna()
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
            setOpenModal(false)
        })
    }

    useEffect(() => {
        refreshListClass()
        // eslint-disable-next-line
    }, [])


    return <div>
        <TitleBar title="Nilai" subtitle="Penilaian kuis oleh dosen" />
        <Grid container className={classes.content}>
            <Grid item md={12}>
                <MaterialTable 
                    icons={tableIcons}
                    title={
                        <FormControl variant="outlined" style={{
                            marginTop:8,
                            width: 200
                        }} fullWidth>
                            <InputLabel id="demo-simple-select-outlined-label">Kode Kelas</InputLabel>
                            <Select
                                disabled={(userData.role === params.ROLE.ADMIN || params.ROLE.FACULTY) ? false : true}
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={selectedClassCode}
                                onChange={handleOnChangeClassCode}
                                label="Kode Kelas"
                                style={{
                                    marginTop: 5
                                }}
                            >
                                {(listClassCode && listClassCode.length > 0) && listClassCode.map(code => <MenuItem value={code._id} key={code._id}>
                                    <em>{code.name}</em>
                                </MenuItem>)}
                            </Select>
                        </FormControl>
                    }
                    columns={listPengguna.column}
                    data={listPengguna.data}
                    isLoading={isTableLoad}
                    options={{
                        header: true,
                        actionsColumnIndex: 8
                    }}
                    actions={[
                        {
                            icon: tableIcons.Export,
                            tooltip: 'Export Nilai',
                            isFreeAction: true,
                            onClick: (event) => {
                                window.open(baseurl() + "api/score/export-nilai/"+selectedClassCode, '_self', 'toolbar=0,location=0,menubar=0');
                            }
                        },
                        {
                            icon: tableIcons.Edit,
                            tooltip: 'Masukan data nilai',
                            onClick: (event, rowData) => handleOpenModal(rowData)
                        },
                        {
                            icon: tableIcons.Delete,
                            tooltip: 'Hapus data nilai',
                            onClick: (event, rowData) => handleDeleteScore(rowData)
                        },
                    ]}
                />
            </Grid>
        </Grid>
        <Dialog open={openModal} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={(attachment.length !== 0) ? "md" : "xs"}>
            <DialogTitle id="form-dialog-title">Ubah Nilai</DialogTitle>
            <DialogContent>
                <Typography>
                    {(selectedUser === {}) && "Nilai" + selectedUser.name + "dalam" + selectedUser.book.name + "," + selectedUser.unit.name + "kuis" + selectedUser.chapter.name}
                    
                </Typography>
                <div>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="nilai"
                        label="Nilai"
                        type="text"
                        fullWidth
                        variant='outlined'
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircleIcon/>
                            </InputAdornment>
                            ),
                        }}
                        onChange={handleInputScore}
                        placeholder="masukan nilai..."
                        value={score}
                    />
                    {(attachment.length !== 0) && attachment.map(qna => <div>
                        <p>{qna.short_question}<br/>{qna.short_answer}</p>
                        <p>{qna.answer}</p>
                        <hr/>
                    </div>)}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Batalkan
                </Button>
                <Button onClick={submitScore} color="primary" variant="contained">
                    Kirim
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}