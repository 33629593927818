import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { loadAssets } from "../helper/utils";
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTrack, setIsPlaying } from "../actions/audio-player.actions";

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottom: '1px solid #e0e0e0',
        marginBottom: 20
    },
    imagePlaceholder: {
        width: '100%'
    }
}));

export default function InstructionTextAudio(props) {
    const classes = useStyles()
    const audioIsPlay = useSelector(state => state.globalIsAudioPlaying)
    const currentTrack = useSelector(state => state.globalCurrentTrack)

    const dispatch = useDispatch()

    function handlePlayInstruction() {
        dispatch(setCurrentTrack(
            loadAssets(props.data.instructions_audio)
        ))
        setTimeout(() => {
            dispatch(setIsPlaying(
                !audioIsPlay
            ))
        }, 1000);
    }

    return <Grid container className={classes.root}>
        <Grid item md={12}>
            <table>
                <tbody>
                    <tr>
                        <td>
                            {(props.data.instructions_audio && props.data.instructions_audio !== "") && <IconButton onClick={handlePlayInstruction}>
                                {(loadAssets(props.data.instructions_audio) === currentTrack && audioIsPlay)
                                    ? <PauseCircleFilledIcon/>
                                    : <PlayCircleFilledIcon color="primary" />}
                            </IconButton>}
                        </td>
                        <td>
                            <Typography variant="h5" style={{
                                fontFamily: "Hacen",
                                fontSize: 32
                            }}>
                                {props.data.instructions}
                            </Typography>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Grid>
    </Grid>
}