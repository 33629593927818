import { Container, Grid, makeStyles,Typography } from "@material-ui/core";
import lang from "../helper/localize";
import params from "../helper/params";
import EmptyCourseCard from "./emptyCourseCard.component";
import InstructionTextAudio from "./instructionTextAudio.component";
import renderHTML from 'react-render-html'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 8,
        fontFamily: "trado",
        fontSize: 32
    },
    imagePlaceholder: {
        width: '100%'
    },
    kuisinfo: {
        padding: 20,
        fontSize: (lang.getLanguage() === params.LANG.ARB) ? 24 : 12
    }
}));

export default function EssaiUnit(props) {
    const classes = useStyles()
    return <Container className={classes.root}>
            <InstructionTextAudio data={props.data} />
            <Grid container style={{
                padding: 20
            }}>
                <Grid item md={12}>
                    {(props.data.content.length === 0) && <EmptyCourseCard message={lang.empty_unit}
                        showLinkButton={false}/>}
                    
                    {(props.data.content.length > 0) && <Typography style={{
                        fontFamily: "trado",
                        fontSize: 32
                    }}>
                        {renderHTML(props.data.content[0].question ?? "")}
                    </Typography>}
                    
                </Grid>
            </Grid>
            <br/>
        </Container>
}