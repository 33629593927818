import UserPage from './pages/cpanel/user.pages'
import BooksPage from './pages/cpanel/books.pages'
import EnrollmentPage from './pages/cpanel/enrollment.pages'
import UnitManagementPage from './pages/cpanel/unitManagement.pages'
import ChapterManagementPage from './pages/cpanel/chapterManagement.pages'
import AdminProfilePage from './pages/cpanel/adminProfile.pages'
import ScoreManagementPage from './pages/cpanel/scoreManagement.pages'
import ClassRoomPage from './pages/cpanel/classroomManagement.pages'

const routes = [
    {
        path: '/cpanel',
        component: BooksPage,
        menu: "BOOKS"
    },
    {
        path: '/cpanel/user',
        component: UserPage,
        menu: "USER"
    },
    {
        path: '/cpanel/enrollment',
        component: EnrollmentPage,
        menu: "ENROLL"
    },
    {
        path: '/cpanel/books',
        component: BooksPage,
        menu: "BOOKS"
    },
    {
        path: '/cpanel/books/unit',
        component: UnitManagementPage,
        menu: "BOOKS"
    },
    {
        path: '/cpanel/books/unit/chapter',
        component: ChapterManagementPage,
        menu: "BOOKS"
    },
    {
        path: '/cpanel/profile',
        component: AdminProfilePage,
        menu: "ADMIN_PROFILE"
    },
    {
        path: '/cpanel/score',
        component: ScoreManagementPage,
        menu: "SCORE"
    },
    {
        path: '/cpanel/classroom',
        component: ClassRoomPage,
        menu: "CLASS_ROOM"
    },
]

export default routes