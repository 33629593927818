import { Grid, makeStyles, Dialog, DialogTitle, DialogActions, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, DialogContent, Button } from "@material-ui/core";
import MaterialTable from "material-table";
import TitleBar from "../../component/titleBar.component";
import tableIcons from '../../component/tableIcon.component'
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import params from "../../helper/params";
import { addUser, deleteUser, getListUser, importParticipant, updateprofile } from "../../models/user.model";
import { show_alert } from "../../actions/alert.actions";
import { hide_dialog, show_dialog } from "../../actions/dialog.actions";
import { hideloader, showloader } from "../../actions/loader.actions";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EmailIcon from '@material-ui/icons/Email';
import { DropzoneDialog } from 'material-ui-dropzone';
import { getClassList } from "../../models/claassrom.model";

    const useStyles = makeStyles((theme) => ({
        content: {
            padding: 20
        }
    }));

export default function EnrollmentPage() {
    const classes = useStyles()
    const [listPengguna, setListPengguna] = useState({
        column: [
            {title: "Nama", field: "name"},
            {title: "Email", field: "email"},
            {title: "NIM", field: "nik"},
            {title: "Kode Kelas", field: "class_code_text"}
        ],
        data: []
    })
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const [isUpdate, setIsUpdate] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [fullName, setFullName] = useState('')
    const [nik, setNik] = useState('')
    const [gender, setGender] = useState(params.GENDER.MALE)
    const [isTableLoad, setTableLoad] = useState(false)
    const [userId, setUserId] = useState("")
    const [isOpenImport, setOpenImport] = useState(false)
    const [classCode, setClassCode] = useState("")
    const [classList, setClassList] = useState([])
    const handleChangeClassCode = (event) => {
        setClassCode(event.target.value);
    };

    function resetFormValue() {
        setEmail('');
        setPassword('');
        setFullName('')
        setNik('')
        setGender(params.GENDER.MALE)
        setClassCode('')
    }

    function handleInputEmail(e) {
        setEmail(e.target.value)
    }

    function handleInputPassword(e) {
        setPassword(e.target.value)
    }

    function handleInputFullName(e) {
        setFullName(e.target.value)
    }

    function handleInputNik(e) {
        setNik(e.target.value)
    }

    function handleInputGender(e) {
        setGender(e.target.value)
    }

    function refreshListPengguna() {
        setTableLoad(true)
        getListUser(token, params.ROLE.USER).then(res => {
            for (let i = 0; i < res.data.length; i++) {
                const account = res.data[i];
                res.data[i].class_code_text = '-'
                if (account.class_code.length > 0) {
                    res.data[i].class_code_text = account.class_code.map(c => c.name).join(', ')
                }
            }
            setListPengguna({ column: listPengguna.column, data: res.data })
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            setTableLoad(false)
        })

        getClassList(token, null).then(res => {
            setClassList(res.data)
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        })
    }

    function handleClose() {
        setOpenModal(!openModal)
    }

    function handleDeletePengguna(data) {
        dispatch(show_dialog({
            title: "Peringatan",
            message: "Apa Anda yakin mengapus data ini?"
        }, () => {
            deleteUser(token, data._id).then(res => {
                refreshListPengguna()
            }).catch(err => {
                dispatch(show_alert({
                    status: "error",
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hide_dialog())
            })
        }))
    }

    function handleSubmitForm() {
        dispatch(showloader("Memproses data"))
        if (isUpdate) {
            updateprofile(token, userId, {
                name: fullName,
                nik,
                gender,
                email,
                class_code: [classCode]
            }).then(res => {
                refreshListPengguna()
                setOpenModal(false)
                resetFormValue()
            }).catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        } else {
            addUser(token, {
                name: fullName,
                nik,
                gender,
                email,
                password,
                role: params.ROLE.USER,
                class_code: [classCode]
            }).then(res => {
                refreshListPengguna()
                resetFormValue()
                setOpenModal(false)
            }).catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }
    }

    function handleAddPengguna() {
        resetFormValue()
        setIsUpdate(false)
        setOpenModal(true)
    }

    function handleEditPengguna(row) {
        setIsUpdate(true)
        setOpenModal(true)
        setFullName(row.name)
        setNik(row.nik)
        setGender(row.gender)
        setEmail(row.email)
        setUserId(row._id)
        setClassCode(row.class_code ?? '')
    }

    function handleImportPengguna(file) {
        let formData = new FormData()
        formData.append('file', file[0])
        dispatch(showloader("Memproses data..."))
        importParticipant(token, formData).then(res => {
            refreshListPengguna()
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
            setOpenImport(false)
        })
    }

    useEffect(() => {
        refreshListPengguna()
        // eslint-disable-next-line
    }, [])

    return <div>
        <TitleBar title="Pengguna" subtitle="Pengguna terdaftar" />
        <Grid container className={classes.content}>
            <Grid item md={12}>
                <MaterialTable 
                    icons={tableIcons}
                    title=""
                    columns={listPengguna.column}
                    data={listPengguna.data}
                    isLoading={isTableLoad}
                    options={{
                        header: true,
                        actionsColumnIndex:4
                    }}
                    actions={[
                        {
                            icon: tableIcons.Import,
                            tooltip: 'import pengguna',
                            isFreeAction: true,
                            onClick: (event) => setOpenImport(true)
                        },
                        {
                            icon: tableIcons.Add,
                            tooltip: 'Tambah pengguna',
                            isFreeAction: true,
                            onClick: (event) => handleAddPengguna()
                            },
                        {
                            icon: tableIcons.Edit,
                            tooltip: 'Ubah data pengguna',
                            onClick: (event, rowData) => handleEditPengguna(rowData)
                        },
                        {
                            icon: tableIcons.Delete,
                            tooltip: 'Hapus data pengguna',
                            onClick: (event, rowData) => handleDeletePengguna(rowData)
                        },
                    ]}
                />
            </Grid>
        </Grid>
        <Dialog open={openModal} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xs">
            <DialogTitle id="form-dialog-title">{(isUpdate) ? "Ubah data" : "Tambah data"}</DialogTitle>
            <DialogContent>
                <div>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="fullname"
                        label="Nama lengkap"
                        type="text"
                        fullWidth
                        variant='outlined'
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircleIcon/>
                            </InputAdornment>
                            ),
                        }}
                        onChange={handleInputFullName}
                        placeholder="masukan nama lengkap..."
                        value={fullName}
                    />
                    <br/>
                    <TextField
                        id="nik"
                        label="NIM"
                        type="text"
                        defaultValue=""
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{
                            marginTop: 5
                        }}
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircleIcon/>
                            </InputAdornment>
                            ),
                        }}
                        onChange={handleInputNik}
                        value={nik}
                        placeholder="masukan NIM..."
                    />
                    <FormControl variant="outlined" style={{
                        marginTop:8
                    }} fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">Jenis Kelamin</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={gender}
                            onChange={handleInputGender}
                            label="Jenis Kelamin"
                            style={{
                                marginTop: 5
                            }}
                        >
                            <MenuItem value={params.GENDER.MALE}>
                                <em>Laki-Laki</em>
                            </MenuItem>
                            <MenuItem value={params.GENDER.FEMALE}>Perempuan</MenuItem>
                        </Select>
                    </FormControl>
                    <hr/>
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email"
                        type="email"
                        fullWidth
                        variant='outlined'
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon/>
                            </InputAdornment>
                            ),
                        }}
                        onChange={handleInputEmail}
                        placeholder="masukan e-mail..."
                        value={email}
                    />
                    <div style={{display: (isUpdate) ? 'none' : 'block'}}>
                        <TextField
                            margin="dense"
                            id="katasandi"
                            label="Kata Sandi"
                            type="password"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <VpnKeyIcon/>
                                </InputAdornment>
                                ),
                            }}
                            onChange={handleInputPassword}
                            placeholder="masukan kata sandi..."
                            value={password}
                        />
                    </div>
                    <FormControl variant="outlined" style={{
                        marginTop:8
                    }} fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">Kode Kelas</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={classCode}
                            onChange={handleChangeClassCode}
                            label="Jenis Kelamin"
                            style={{
                                marginTop: 5
                            }}
                        >
                            {classList.map((classroom) => (
                                <MenuItem key={classroom._id} value={classroom._id}>
                                {classroom.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Batalkan
                </Button>
                <Button onClick={handleSubmitForm} color="primary" variant="contained">
                    Kirim
                </Button>
            </DialogActions>
        </Dialog>
        <DropzoneDialog
            dialogTitle="Impor pengguna"
            dropzoneText="seret dan jatuhkan file atau klik untuk impor pengguna"
            acceptedFiles={['application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
            cancelButtonText="Batal"
            submitButtonText="Kirim"
            showFileNames={true}
            useChipsForPreview={true}
            showPreviewsInDropzone={true}
            maxFileSize={5000000}
            open={isOpenImport}
            onClose={() => setOpenImport(!isOpenImport)}
            onSave={handleImportPengguna}
            showPreviews={false}
            filesLimit={1}
            clearOnUnmount={true}
        />
    </div>
}