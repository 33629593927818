import { Grid, makeStyles, Dialog, DialogTitle, DialogContent, TextField, InputAdornment, Button, DialogActions,FormControl, InputLabel,Select, Input, Chip, MenuItem} from "@material-ui/core";
import { useEffect, useState } from "react";
import TitleBar from "../../component/titleBar.component";
import tableIcons from "../../component/tableIcon.component";
import { useDispatch } from "react-redux";
import MaterialTable from "material-table";
import { addBook, deleteBook, getBookList, updateBook } from "../../models/book.model";
import { show_alert } from "../../actions/alert.actions";
import { hide_dialog, show_dialog } from "../../actions/dialog.actions";
import { hideloader, showloader } from "../../actions/loader.actions";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { loadImage } from "../../helper/utils";
import { useHistory } from "react-router-dom";
import { getClassList } from "../../models/claassrom.model";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: 20
    },
    imagePlaceholder: {
        width: '30%',
        border: '1px dashed black'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function BooksPage() {
    const history = useHistory()
    const classes = useStyles()
    const [isOpenModal, setOpenModal] = useState(false)
    const dispatch = useDispatch()
    const [isTableLoad, setIsTableLoad] = useState(false)
    const [isEditBook, setIsEditBook] = useState(false)
    const token = localStorage.getItem('token')

    const [classList, setClassList] = useState([])

    const [listBook, setListBook] = useState({
        column: [
            {title: "Book name", field: "name"},
            {title: "Kode Kelas", field: "class_code_text"}
        ],
        data: []
    })

    const [bookId, setBookId] = useState('')
    const [bookName, setBookName] = useState('')
    function handleInputBookName(e) {
        setBookName(e.target.value  )
    }

    const [bookDetails, setBookDetails] = useState('')
    function handleInputBookDetails(e) {
        setBookDetails(e.target.value)
    }

    const [bookSinopsys, setBookSinopsys] = useState('')
    function handleInputBookSinopsys(e) {
        setBookSinopsys(e.target.value)
    }

    const [bookCover, setBookCover] = useState('')
    const [coverPreview, setCoverPreview] = useState(loadImage(''))
    function handleInputBookCover(e) {
        var file = e.target.files[0]
        var url = URL.createObjectURL(file)

        setBookCover(file)
        setCoverPreview(url)
    }

    const [bookIsbn, setBookIsbn] = useState('')
    function handleInputIsbn(e) {
        setBookIsbn(e.target.value)
    }

    const [classCode, setClassCode] = useState([])
    const handleChangeClassCode = (event) => {
        setClassCode(event.target.value);
    };

    function resetFormValue() {
        setBookName('')
        setBookDetails('')
        setBookSinopsys('')
        setBookCover('')
        setBookIsbn('')
        setClassCode([])
        setBookId('')
        setCoverPreview(loadImage(''))
    }

    function handleEditBook(data) {
        setOpenModal(true)
        setIsEditBook(true)
        setBookName(data.name)
        setBookDetails(data.details)
        setBookSinopsys(data.sinopsys)
        setBookIsbn(data.isbn)

        let classCode = (data.class_code.length > 0)
            ? data.class_code.map(cc => cc._id)
            : []

        setClassCode(classCode)
        setBookId(data._id)
        setBookCover(data.cover)
        setCoverPreview(loadImage(data.cover))
    }

    function handleAddBook() {
        setOpenModal(true)
        setIsEditBook(false)
        resetFormValue()
    }

    function handleDeleteBook(data) {
        dispatch(show_dialog({
            title: "Peringatan",
            message: "Apa Anda yakin ingin menghapus buku ini?"
        }, () => {
            dispatch(hide_dialog())
            dispatch(showloader("Memproses data..."))
            deleteBook(token, data._id).then(res => {
                refreshBookList()
            }).catch(err => {
                dispatch(show_alert({
                    status: "error",
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }))
    }

    function refreshBookList() {
        setIsTableLoad(true)
        getBookList().then(res => {
            for (let i = 0; i < res.data.length; i++) {
                const book = res.data[i];
                res.data[i].class_code_text = '-'
                if (book.class_code.length > 0) {
                    res.data[i].class_code_text = book.class_code.map(c => c.name).join(', ')
                }
            }
            setListBook({
                column: listBook.column,
                data: res.data
            })
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            setIsTableLoad(false)
        })

        getClassList(token, null).then(res => {
            setClassList(res.data)
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        })
    }

    function handleDetail(data) {
        history.push({
            pathname: '/cpanel/books/unit',
            state: data
        })
    }

    function handleSubmitForm() {
        dispatch(showloader("Memproses data..."))
        let formData = new FormData()
        formData.append('name', bookName)
        formData.append('details', bookDetails)
        formData.append('sinopsys', bookSinopsys)
        formData.append('isbn', bookIsbn)
        formData.append('class_code', JSON.stringify(classCode))
        formData.append('file', bookCover)
        if (isEditBook) {
            updateBook(token, bookId, formData).then(res => {
                refreshBookList()
                setOpenModal(false)
            }).catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        } else {
            addBook(token, formData).then(res => {
                refreshBookList()
                setOpenModal(false)
            }).catch(err => {
                dispatch(show_alert({
                    status: "error",
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }
    }

    function handleClose() {
        setOpenModal(!isOpenModal)
    }

    useEffect(() => {
        refreshBookList()
        // eslint-disable-next-line
    }, [])
    
    return <div>
        <TitleBar title="Buku" subtitle="Daftar buku dan materi."/>
        <Grid container className={classes.content}>
            <Grid item md={12}>
                <MaterialTable 
                    icons={tableIcons}
                    title=""
                    columns={listBook.column}
                    data={listBook.data}
                    isLoading={isTableLoad}
                    options={{
                        header: true,
                        actionsColumnIndex:3
                    }}
                    actions={[
                        {
                            icon: tableIcons.Add,
                            tooltip: 'Tambah buku',
                            isFreeAction: true,
                            onClick: (event) => handleAddBook()
                        },
                        {
                            icon: tableIcons.DetailBook,
                            tooltip: 'Detail buku',
                            onClick: (event, rowData) => handleDetail(rowData)
                        },
                        {
                            icon: tableIcons.Edit,
                            tooltip: 'Ubah data buku',
                            onClick: (event, rowData) => handleEditBook(rowData)
                        },
                        {
                            icon: tableIcons.Delete,
                            tooltip: 'Hapus data buku',
                            onClick: (event, rowData) => handleDeleteBook(rowData)
                        },
                    ]}
                />
            </Grid>
        </Grid>
        <Dialog open={isOpenModal} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xs">
            <DialogTitle id="form-dialog-title">{(isEditBook) ? "Ubah data" : "Tambah data"}</DialogTitle>
            <DialogContent>
                <div>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="demo-mutiple-chip-label">Kode Kelas</InputLabel>
                        <Select
                        style={{width: '100%'}}
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        multiple={true}
                        value={classCode}
                        onChange={handleChangeClassCode}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                            <div className={classes.chips}>
                            {selected.map((value) => {
                                let index = classList.findIndex(cls => cls._id === value)

                                return <Chip key={classList[index]._id} label={classList[index].name} className={classes.chip} />
                            })}
                            </div>
                        )}
                        MenuProps={MenuProps}
                        >
                        {classList.map((classroom) => (
                            <MenuItem key={classroom._id} value={classroom._id}>
                            {classroom.name}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        id="bookname"
                        label="Judul Buku"
                        type="text"
                        fullWidth
                        variant='outlined'
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <InsertDriveFileIcon/>
                            </InputAdornment>
                            ),
                        }}
                        onChange={handleInputBookName}
                        placeholder="masukan judul buku..."
                        value={bookName}
                    />
                    <img src={coverPreview} alt="sampul buku" className={classes.imagePlaceholder} />
                    <TextField
                        title="masukan sampul buku"
                        margin="dense"
                        id="bookCover"
                        label="Sampul Buku"
                        type="file"
                        fullWidth
                        variant='outlined'
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <InsertDriveFileIcon/>
                            </InputAdornment>
                            ),
                        }}
                        onChange={handleInputBookCover}
                        placeholder="masukan sampul buku..."
                    />
                    <TextField
                        margin="dense"
                        id="bookisbn"
                        label="ISBN"
                        type="text"
                        fullWidth
                        variant='outlined'
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <InsertDriveFileIcon/>
                            </InputAdornment>
                            ),
                        }}
                        onChange={handleInputIsbn}
                        placeholder="masukan nomor ISBN..."
                        value={bookIsbn}
                    />
                    <TextField
                        multiline={true}
                        rows={4}
                        margin="dense"
                        id="bookdetail"
                        label="Detail buku"
                        type="text"
                        fullWidth
                        variant='outlined'
                        onChange={handleInputBookDetails}
                        placeholder="masukan detail buku..."
                        value={bookDetails}
                    />
                    <TextField
                        multiline={true}
                        rows={4}
                        margin="dense"
                        id="booksinopsys"
                        label="Sinopsis buku"
                        type="text"
                        fullWidth
                        variant='outlined'
                        onChange={handleInputBookSinopsys}
                        placeholder="masukan sinopsis buku..."
                        value={bookSinopsys}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Batalkan
                </Button>
                <Button onClick={handleSubmitForm} color="primary" variant="contained">
                    Kirim
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}