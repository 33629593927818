import LocalizedStrings from 'react-localization'

let lang = new LocalizedStrings({
    id:{
        login: "Masuk",
        logout: "Keluar",
        forgot_password: "Lupa kata sandi",
        authentication: "Autentikasi",
        cancel: "Batalkan",
        submit: "Kirim",
        email: "Username",
        email_placeholder: "masukan username",
        password: "Kata Sandi",
        password_placeholder: "masukan kata sandi...",
        have_account_text: "Sudah punya akun, masuk.",
        start_learning: "Mulai Belajar",
        arabic_learning: "Arabic Learning",
        copyright: "Copyright © 2021 Pusat Pengembangan Bahasa UIN Maliki Malang",
        books: "Buku",
        profile: "Profil",
        books_title: "Buku apa yang ingin dipelajari?",
        start_learning_now: "Pelajari buku ini",
        about_book: "Tentang buku ini",
        book_detail_title: "Pilih unit yang ingin dipelajari",
        lesson: "Materi",
        quiz: "Kuis",
        class_code: "Kode Kelas",
        ok: "Ok",
        hiwar: "HIWAR",
        mufrodat: "MUFRODAT",
        tarakib: "TARAKIB",
        istima: "ISTIMA",
        kalam: "KALAM",
        qiraah: "QIRAAH",
        kitabah: "KITABAH",
        process_data: "Memproses data...",
        loading_data: "Memuat data...",
        save: "Simpan",
        full_name: "Nama lengkap",
        full_name_placeholder: "masukan nama lengkap...",
        nim: "NIM",
        nim_placeholder: "masukan NIM...",
        change_password: "Ubah kata sandi",
        old_password: "Kata sandi lama",
        old_password_placeholder: "masukan kata sandi lama...",
        new_password: "Kata sandi baru",
        new_password_placeholder: "masukan kata sandi baru...",
        confirm_password: "Konfirmasi kata sandi baru",
        confirm_password_placeholder: "masukan konfirmasi password baru...",
        empty_books: "Belum ada buku yang di publikasikan",
        logout_text: "Keluar aplikasi...",
        gender: "Jenis Kelamin",
        male: "LAKI-LAKI",
        female: "PEREMPUAN",
        next: "Selanjutnya",
        empty_unit: "Unit belum tersedia",
        chapter_complete_title: "Selamat Anda telah Menyelesaikan!",
        play_audio: "Putar audio",
        submit_kuis_info: "Kirimkan jawaban Anda ke e-mail dosen bersangkutan di",
        attention: "Perhatian",
        submit_quiz_attention_message: "Apa Anda yakin ingin mengirim jawaban Anda?",
        total_score: "Total nilai: {0}"
    },
    ar: {
        login: "الدخول",
        logout: "الخروج",
        forgot_password: "نسيت كلمة السر؟",
        authentication: "التأكيد",
        cancel: "إلغاء",
        submit: "إرسال",
        email: "username",
        email_placeholder: "username",
        password: "كلمه السر",
        password_placeholder: "اكتب كلمة السر",
        have_account_text: "لديك حساب/الدخول",
        start_learning: "ابدأ الدرس",
        arabic_learning: "حياة العربية",
        copyright: "جميع الحقوق محفوظة لدى مركز اللغات جامعة مولانا مالك إبراهيم الإسلامية الحكومية مالانج",
        books: "الكتب", 
        profile: "البيانات الشخصية",
        books_title: "ماذا تريد أن تدرس؟",
        start_learning_now: "استعراض الكتاب",
        about_book: "عن الكتاب",
        book_detail_title: "اختر الوحدة",
        lesson: "مادة",
        quiz: "تدريب",
        class_code: "رمز الفصل",
        ok: "نعم",
        hiwar: "الحوار",
        mufrodat: "المفردات",
        tarakib: "التراكيب",
        istima: "الاستماع",
        kalam: "الكلام",
        qiraah: "القراءة",
        kitabah: "الكتابة",
        process_data: "معالجة البيانات",
        loading_data: "تحميل البيانات",
        save: "حفظ",
        full_name: "الاسم الكامل",
        full_name_placeholder: "اكتب الاسم الكامل",
        nim: "الرقم الجامعي",
        nim_placeholder: "اكتب الرقم الجامعي",
        change_password: "تغيير كلمة السر",
        old_password: "كلمة السر السابق",
        old_password_placeholder: "اكتب كلمة السر السابق",
        new_password: "كلمة السر الجديد",
        new_password_placeholder: "اكتب كلمة السر الجديد",
        confirm_password: "تأكيد كلمة السر الجديد",
        confirm_password_placeholder: "اكتب كلمة السر الجديد مرة ثانية",
        empty_books: "لم يتم نشر الكتاب",
        logout_text: "الخروج",
        gender: "الجنس",
        male: "الذكر",
        female: "الأنثى",
        next: "التالي",
        empty_unit: "الوحدة غير متوفرة",
        chapter_complete_title: "تهنئة، قد أكملت العمل",
        play_audio: "تشغيل الصوت",
        submit_kuis_info: "أرسل الأجوبة إلى البريد الإلكتروني للأستاذ",
        attention: "الانتباه",
        submit_quiz_attention_message: "هل أنت متأكد أنك تريد تقديم إجابتك؟",
        total_score: "{0} :النتيجة"
    }
})

export default lang