import { useState } from "react"
import { Button, Menu, MenuItem } from '@material-ui/core';
import TranslateIcon from '@material-ui/icons/Translate';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import params from "../helper/params";
import localize from '../helper/localize'
import { useHistory } from "react-router-dom";

export default function Bilingual(props) {
    const history = useHistory()
    let language = localStorage.getItem('lang') ?? params.LANG.ARB
    const [mainLang, setMainLang] = useState(language)
    const [anchorEl, setAnchorEl] = useState(null);
    function handleOpenMenu (event) {
        setAnchorEl(event.currentTarget)
    }
    
    function handleCloseMenu () {
        setAnchorEl(null);
    }

    function handleChangeLanguage(lang) {
        setMainLang(lang)
        localStorage.setItem('lang', lang)
        localize.setLanguage(lang)
        handleCloseMenu()
        history.go(0)
    }
    
    return <div>
        <Button color="default" variant="contained" disableElevation onClick={handleOpenMenu} style={{marginRight: 20}}>
            <TranslateIcon/>
            &nbsp;
            {(mainLang === params.LANG.INA) && params.LANGUAGE.INDONESIA}
            {(mainLang === params.LANG.ARB) && params.LANGUAGE.ARABIC}
            &nbsp;
            <KeyboardArrowDownIcon/>
        </Button>
        <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        >
            <MenuItem onClick={() => handleChangeLanguage(params.LANG.INA)}>{params.LANGUAGE.INDONESIA}</MenuItem>
            <MenuItem onClick={() => handleChangeLanguage(params.LANG.ARB)}>{params.LANGUAGE.ARABIC}</MenuItem>
        </Menu>
    </div>
}