import { Box, Button, Container, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import params from '../../helper/params'
import EmailIcon from '@material-ui/icons/Email';
import TitlePage from '../../component/titlePage.component';
import PropTypes from 'prop-types';
import { useState } from 'react';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { useDispatch } from 'react-redux';
import { hideloader, showloader } from '../../actions/loader.actions';
import { changePassword, updateprofile } from '../../models/user.model';
import { show_alert } from '../../actions/alert.actions';
import lang from '../../helper/localize';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    textInput: {
        backgroundColor: 'white'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

export default function ProfilePage() {
    const userData = JSON.parse(localStorage.getItem('auth'))
    const token = localStorage.getItem("token")
    const classes = useStyles()
    const [tabMenu, setTabMenu] = useState(0)
    const dispatch = useDispatch()

    const [fullName, setFullname] = useState(userData.name)
    function handleChangeFullName(e) {
        setFullname(e.target.value)
    }

    const [nik, setNik] = useState(userData.nik)
    function handleChangeNik(e) {
        setNik(e.target.value)
    }

    const [gender, setGender] = useState(userData.gender)
    function handleChangeGender(e) {
        setGender(e.target.value)
    }

    const [email, setEmail] = useState(userData.email)
    function handleChangeEmail(e) {
        setEmail(e.target.value)
    }

    const [oldPassword, setOldPassword] = useState("")
    function handleChangeInputOldPassword(e) {
        setOldPassword(e.target.value)
    }

    const [newPassword, setNewPassword] = useState("")
    function handleChangeInputNewPassword(e) {
        setNewPassword(e.target.value)
    }

    const [conPassword, setConPassword] = useState("")
    function handleChangeInputConPassword(e) {
        setConPassword(e.target.value)
    }

    function handleChangeProfile() {
        dispatch(showloader(lang.process_data))
        updateprofile(token, userData.id, {
            name: fullName,
            nik,
            gender,
            email
        }).then(res => {
            userData.name = fullName
            userData.nik = nik
            userData.gender = gender
            userData.email = email

            localStorage.setItem('auth', JSON.stringify(userData))
            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    function handleChangePassword() {
        if (newPassword !== conPassword) {
            dispatch(show_alert({
                status: 'error',
                message: "Konfirmasi password baru tidak sesuai"
            }))
        } else {
            dispatch(showloader(lang.process_data))
            changePassword(token, {
                old_password: oldPassword,
                new_password: newPassword
            }).then(res => {
                dispatch(show_alert({
                    status: 'success',
                    message: res.message
                }))
            }).catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
                setOldPassword("")
                setNewPassword("")
                setConPassword("")
            })
        }
    }

    const handleChange = (event, newValue) => {
        setTabMenu(newValue);
    };

    return <div className={classes.root}>
        <TitlePage title={lang.profile} />
        <Container style={{padding: 16, backgroundColor: '#FAFAFA', borderRadius: '8px', marginTop: 20, textAlign: 'center'}} dir={
            (lang.getLanguage() === params.LANG.ARB)
                ? "rtl"
                : "ltr"
        }>
            <Grid container spacing={4} style={{padding: 10}} justify='center' >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tabMenu}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className={classes.tabs}
                >
                    <Tab label={lang.profile} {...a11yProps(0)} style={{
                        fontFamily: "Hacen"
                    }}/>
                    <Tab label={lang.change_password} {...a11yProps(1)} style={{
                        fontFamily: "Hacen"
                    }}/>
                </Tabs>
                <TabPanel value={tabMenu} index={0} style={{maxWidth: 600}} >
                    <Grid item md={12}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="fullname"
                            label={lang.full_name}
                            type="text"
                            fullWidth
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircleIcon/>
                                </InputAdornment>
                                ),
                            }}
                            className={classes.textInput}
                            placeholder={lang.full_name_placeholder}
                            value={fullName}
                            onChange={handleChangeFullName}
                        />
                        <TextField
                            id="nik"
                            label="NIM"
                            type="text"
                            defaultValue=""
                            value={nik}
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                marginTop: 5
                            }}
                            className={classes.textInput}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircleIcon/>
                                </InputAdornment>
                                ),
                            }}
                            onChange={handleChangeNik}
                            placeholder={lang.nim_placeholder}
                        />
                        <FormControl variant="outlined" style={{
                            marginTop:8,
                            backgroundColor: 'white',
                            textAlign: 'left'
                        }} fullWidth>
                            <InputLabel id="demo-simple-select-outlined-label">{lang.gender}</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={gender}
                                onChange={handleChangeGender}
                                label={lang.gender}
                                style={{
                                    marginTop: 5
                                }}
                            >
                                <MenuItem value={params.GENDER.MALE}>
                                    <em>{lang.male}</em>
                                </MenuItem>
                                <MenuItem value={params.GENDER.FEMALE}>{lang.female}</MenuItem>
                            </Select>
                        </FormControl>
                        <hr/>
                        <TextField
                            margin="dense"
                            id="email"
                            label={lang.email}
                            type="email"
                            fullWidth
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon/>
                                </InputAdornment>
                                ),
                            }}
                            className={classes.textInput}
                            placeholder={lang.email_placeholder}
                            value={email}
                            onChange={handleChangeEmail}
                        />
                    </Grid>
                    <Grid item md={12} style={{textAlign:'right'}}>
                        <Button variant="contained" color="primary" style={{marginTop:10}} onClick={handleChangeProfile}>
                            {lang.save}
                        </Button>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabMenu} index={1} style={{maxWidth: 600}}>
                    <Grid item md={12}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="oldpassword"
                            label={lang.old_password}
                            type="password"
                            fullWidth
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <VpnKeyIcon/>
                                </InputAdornment>
                                ),
                            }}
                            className={classes.textInput}
                            placeholder={lang.old_password_placeholder}
                            value={oldPassword}
                            onChange={handleChangeInputOldPassword}
                        />
                        <TextField
                            margin="dense"
                            id="oldpassword"
                            label={lang.new_password}
                            type="password"
                            fullWidth
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <VpnKeyIcon/>
                                </InputAdornment>
                                ),
                            }}
                            className={classes.textInput}
                            placeholder={lang.new_password_placeholder}
                            value={newPassword}
                            onChange={handleChangeInputNewPassword}
                        />
                        <TextField
                            margin="dense"
                            id="confirmpassword"
                            label={lang.confirm_password}
                            type="password"
                            fullWidth
                            variant='outlined'
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <VpnKeyIcon/>
                                </InputAdornment>
                                ),
                            }}
                            className={classes.textInput}
                            placeholder={lang.confirm_password_placeholder}
                            value={conPassword}
                            onChange={handleChangeInputConPassword}
                        />
                    </Grid>
                    <Grid item md={12} style={{textAlign:'right', marginTop: 10}}>
                        <Button variant="contained" color="primary" onClick={handleChangePassword}>
                            {lang.save}
                        </Button>
                    </Grid>
                </TabPanel>
            </Grid>
        </Container>
    </div>
}