import { makeStyles, Typography } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import VideoCallIcon from '@material-ui/icons/VideoCall';
import { uploadVideoOnlyVariant } from "../models/chapter.model";
import params from "../helper/params";
import { useDispatch } from "react-redux";
import { hideloader, showloader } from "../actions/loader.actions";
import { show_alert } from "../actions/alert.actions";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: 20,
        margin:0,
        width: '100%'
    },
    fieldset: {
        border: "2px dashed grey",
        marginBotom: 10,
        display: 'block'
    }
}));

export default function VideoOnlyVariant(props) {
    const classes = useStyles()
    let variant = params.CHAPTER_VARIANT.ONLY_VIDEO
    let chapterData = props.chapterData
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    function handleUpload(file) {
        dispatch(showloader("memproses data..."))
        let formData = new FormData()
        formData.append('book_id', chapterData.book_id)
        formData.append('unit_id', chapterData.unit_id)
        formData.append('file', file[0])
        formData.append('variant', variant)
        uploadVideoOnlyVariant(token, chapterData._id, formData).then(res => {
            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    return <div>
        <Typography>
            Materi hanya terdiri dari satu buah video.
        </Typography>
        <fieldset className={classes.fieldset}>
            <legend>Video</legend>
            {chapterData.video ?? 'tidak ada file video'}
        </fieldset>
        <br/>
        <DropzoneArea
            Icon={VideoCallIcon}
            onChange={handleUpload}
            dropzoneText="seret dan jatuhkan file di sini atau klik untuk mengupload"
            acceptedFiles={['video/mp4']}
            maxFileSize={150000000}
            showPreviewsInDropzone={true}
            showFileNames={true}
            filesLimit={1}
        />
    </div>
}