import Api from '../helper/api'
import params from '../helper/params'

export const getChapter = (token, id) => {
    return new Promise(function(resolve, reject) {
        Api.get("/api/chapters/detail/"+id, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const getCourseChapter = (token, bookid, unitid) => {
    return new Promise(function(resolve, reject) {
        Api.get("/api/chapters/toc?bookid="+bookid+'&unitid='+unitid, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const getChapterList = (unitid, category) => {
    return new Promise(function(resolve, reject) {
        Api.get("/api/chapters?unitid="+unitid+"&category="+category).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const createChapter = (token, data) => {
    return new Promise(function(resolve, reject) {
        Api.post("/api/chapters", data, {
            headers: {'auth-token': token},
            'Content-Type': 'multipart/form-data'
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const updateChapter = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id, data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteChapter = (token, id) => {
    return new Promise(function(resolve, reject) {
        Api.delete("/api/chapters/"+id, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const resetChapterContent = (token, id, oldVariant, newVariant) => {

    let data = {}
    switch (oldVariant) {
        case params.CHAPTER_VARIANT.ONLY_IMAGE:
            data = {
                image: ''
            }
            break;
        case params.CHAPTER_VARIANT.ONLY_VIDEO:
            data = {
                video: ''
            }
            break;
        case params.CHAPTER_VARIANT.LIST_AUDIO:
            data = {
                content: []
            }
            break;
        case params.CHAPTER_VARIANT.AUDIO_IMAGES:
            data = {
                content: []
            }
            break;
        case params.CHAPTER_VARIANT.AUDIO_A_B:
            data = {
                content: [],
                audio: ''
            }
            break;
        default:
            break;
    }

    data.variant = newVariant

    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/reset-content", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const uploadImageOnlyVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/content-only-image", data, {
            headers: {'auth-token': token, 'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const uploadVideoOnlyVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/content-only-video", data, {
            headers: {'auth-token': token,
            'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const uploadAudioTextImageVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/content-audio-text-image", data, {
            headers: {'auth-token': token,
            'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteAudioTextImageVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/delete-content-audio-text-image", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const uploadAudioTextVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/content-audio-text", data, {
            headers: {'auth-token': token,
            'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteAudioTextVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/delete-content-audio-text", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const uploadAudioABVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/content-audio-ab", data, {
            headers: {'auth-token': token, 'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteAudioABVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/delete-content-audio-ab", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const uploadEssaiVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/content-essai", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const uploadMultiChoiceVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/multi-choice", data, {
            headers: {'auth-token': token, 'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteMultiChoiceVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/delete-multi-choice", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const uploadDragNDropVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/drag-n-drop", data, {
            headers: {'auth-token': token, 'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteDragAndDropVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/delete-drag-n-drop", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}


export const uploadDragNDropTextVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/drag-n-drop-text", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteDragAndDropTextVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/delete-drag-n-drop-text", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const uploadTextOnlyVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/content-text-only", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const uploadImageTextVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/content-image-text", data, {
            headers: {'auth-token': token,
            'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteImageTextVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/chapters/"+id+"/delete-content-image-text", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const uploadReadingVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.put("/api/chapters/"+id+"/content-reading", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteReadingVariant = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.put("/api/chapters/"+id+"/delete-content-reading", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}