import { Container, Grid, makeStyles,Typography, FormControl, RadioGroup, FormControlLabel, Radio, Button } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { hide_dialog, show_dialog } from "../actions/dialog.actions";
import { hideloader, showloader } from "../actions/loader.actions";
import lang from "../helper/localize";
import params from "../helper/params";
import { updateProgress } from "../models/enrollment.model";
import { requestForScore } from "../models/score.model";
import EmptyCourseCard from "./emptyCourseCard.component";
import InstructionTextAudio from "./instructionTextAudio.component";
import { numberToArabic } from 'number-to-arabic'
import { loadImage, loadAssets } from "../helper/utils";
import { setCurrentTrack, setIsPlaying } from "../actions/audio-player.actions";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 8,
        fontFamily: "trado",
        fontSize: 32
    },
    imagePlaceholder: {
        width: '100%'
    },
    kuisinfo: {
        padding: 20,
        fontSize: (lang.getLanguage() === params.LANG.ARB) ? 24 : 12
    }
}));

export default function MultiChoiceUnit(props) {
    const classes = useStyles()
    const listQuestion = props.data.content
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const history = useHistory()
    const {bookDetails, unit, chapter } = history.location.state
    const userData = JSON.parse(localStorage.getItem('auth'))

    const [selectedAnswer, setSelectedAnswer] = useState([])
    const audioIsPlay = useSelector(state => state.globalIsAudioPlaying)
    const currentTrack = useSelector(state => state.globalCurrentTrack)
    function handleAnswer(e, no) {
        let answer = selectedAnswer

        answer[no] = e.target.value
        setSelectedAnswer(answer)
    }

    function handlePlayAudio(track) {
        dispatch(setCurrentTrack(
            loadAssets(track)
        ))
        setTimeout(() => {
            dispatch(setIsPlaying(
                !audioIsPlay
            ))
        }, 1000);
    }

    function handleSubmit() {
        dispatch(show_dialog({
            title: lang.attention,
            message: lang.submit_quiz_attention_message
        }, async () => {
            dispatch(hide_dialog())
            dispatch(showloader(lang.process_data))
            let score = 0
            for (let i = 0; i < listQuestion.length; i++) {
                const answer = selectedAnswer[i];
                if (answer !== undefined && answer === listQuestion[i].right_answer) {
                    score += 1
                }
            }

            let total_score = (score / listQuestion.length) * 100

            let score_detail = score + "/" + listQuestion.length

            setTimeout(async () => {
                requestForScore(token, {
                    book_id: bookDetails._id,
                    unit_id: unit._id,
                    chapter_id: chapter._id,
                    classcode: userData.class_code,
                    attachment: selectedAnswer,
                    score: total_score,
                    score_detail: score_detail
                }).then(async data => {
                    await updateProgress(token, bookDetails._id, {
                        chapter_complete: chapter._id,
                        user_id: userData.id
                    })

                    dispatch(show_dialog({
                        title: lang.attention,
                        message: lang.formatString(lang.total_score, score_detail)
                    }, () => {
                        dispatch(hide_dialog())
                    }))

                    dispatch(hideloader())
                })
            }, 1000)
        }))
    }

    return <Container className={classes.root}>
            <InstructionTextAudio data={props.data} />
            <Grid container style={{
                padding: 20
            }}>
                <Grid item md={12}>
                    {(listQuestion.length === 0) && <EmptyCourseCard message={lang.empty_unit}
                        showLinkButton={false}/>}

                    {(listQuestion.length > 0) && listQuestion.map(data => <Grid container>
                        <Grid item md={6}>
                            <Typography style={{
                                fontFamily: "trado",
                                fontSize: 32
                            }}>
                                
                                {numberToArabic(listQuestion.indexOf(data) + 1)}. {data.question}
                                <br/>
                                {(data.question_image) && <img style={{
                                    width: '100%'
                                }} src={loadImage(data.question_image)} style={{
                                    marginTop: 10,
                                    marginBottom: 10
                                }}/>}
                                {(data.question_audio) && <Button variant="contained" color="primary" onClick={() => handlePlayAudio(data.question_audio)} style={{
                                    marginTop: 10,
                                    marginBottom: 10
                                }}>
                                    {(currentTrack === loadAssets(data.question_audio) && audioIsPlay)
                                        ? "Matikan Audio"
                                        : "Putar Audio"}
                                </Button> }&nbsp;
                            </Typography>
                            <Grid container>
                                <Grid item md={12}>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="gender" name="gender1" value={selectedAnswer[listQuestion.indexOf(data)]} onChange={(e) => handleAnswer(e, listQuestion.indexOf(data))} >
                                            {(data.option && data.option.length > 0) && data.option.map(opt => 
                                                <FormControlLabel value={opt}control={<Radio color="primary"/>} label={(data.answerType == 0 || !data.answerType) ? <span style={{
                                                    fontFamily: "trado",
                                                    fontSize: 32
                                                }}>{opt}</span> : <img style={{
                                                    width: '100px'
                                                }} src={loadImage(opt)} />} key={opt} />
                                            )}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <br/>
                        </Grid>
                    </Grid>)}
                </Grid>
                <Grid container>
                    <Grid item>
                        <Button color="primary" variant="contained" onClick={handleSubmit}>
                            {lang.submit}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <br/>
        </Container>
}