import Api from '../helper/api'

export const enrollBook = (token, book_id) => {
    return new Promise(function(resolve, reject) {
        Api.post("/api/books/"+book_id+"/enroll", {}, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const updateProgress = (token, book_id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/books/"+book_id+"/progress", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}