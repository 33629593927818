import { Container, Grid, makeStyles } from "@material-ui/core";
import { loadImage } from "../helper/utils";
import EmptyCourseCard from "./emptyCourseCard.component";
import lang from "../helper/localize";
import params from "../helper/params";
import InstructionTextAudio from "./instructionTextAudio.component";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 16,
        fontFamily: "trado",
        fontSize: 32
    },
    placeholder:{
        textAlign:'center',
        border: '1px dashed #e0e0e0',
    },
    ilustration: {
        margin: 'auto',
        textAlign: 'center',
        width: '100%',
        height: 200,
        objectFit: "contain",
        backgroundColor: 'white'
    }
}));

export default function TextWithImageUnit(props) {
    const classes = useStyles()

    const content = props.data.content

    return <Container className={classes.root} dir={
        (lang.getLanguage() === params.LANG.ARB)
            ? "rtl"
            : "ltr"
    }>
        <InstructionTextAudio data={props.data} />
        <Grid container justify="center" spacing={5} className={classes.root}>
            {(content.length === 0) && <Grid item md={12}><EmptyCourseCard message={lang.empty_unit} showLinkButton={false} /></Grid>}

            {(content.length !== 0) && content.map(data => <Grid item md={12} className={classes.placeholder}>
                <img alt="img" src={loadImage(data.image)} className={classes.ilustration} />
                <span style={{
                    fontSize: 24
                }}>{data.text}</span>
            </Grid>)}
        </Grid>
    </Container>
}