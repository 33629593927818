import Api from '../helper/api'

export const getScoreList = (token, classcode) => {
    return new Promise(function(resolve, reject) {
        Api.get("/api/score?classcode="+classcode, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const requestForScore = (token, data) => {
    return new Promise(function(resolve, reject) {
        Api.post("/api/score/", data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const updateScore = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        Api.patch("/api/score/" + id, data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteScore = (token, id) => {
    return new Promise(function(resolve, reject) {
        Api.delete("/api/score/" + id, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}