import {
    SHOW_LOADER,
    HIDE_LOADER
} from '../actions/loader.actions'

import {
    SHOW_DIALOG,
    HIDE_DIALOG
} from '../actions/dialog.actions'

import {
    SHOW_ALERT,
    HIDE_ALERT
} from '../actions/alert.actions'

import {
    CURRENT_TRACK,
    IS_PLAY
} from '../actions/audio-player.actions'

import {
    SHOW_ANSWER,
    HIDE_ANSWER
} from '../actions/show-answer.actions'

export const LoaderReducers = (state = {isopen: false, message: ""}, action) => {
    switch (action.type) {
        case SHOW_LOADER:
            return state = {isopen:true, message: action.message}
        case HIDE_LOADER:
            return state = {isopen:false, message: ""}
        default:
            return state
    }
}

export const DialogReducers = (state = {isopen: false, title: "", message: "", handleok: () => {} }, action) => {
    switch (action.type) {
        case SHOW_DIALOG:
            return state = {
                isopen: true,
                title: action.title,
                message: action.message,
                handleok: action.handleok
            }
        case HIDE_DIALOG:
            return state = {
                isopen: false
            }
        default:
            return state
    }
}

export const AlertReducers = (state = {isopen: false, data: {}}, action) => {
    switch (action.type) {
        case SHOW_ALERT:
            return state = {
                isopen: true,
                data: action.data
            }
        case HIDE_ALERT:
            return state = {
                isopen: false,
                data: action.data
            }
        default:
            return state
    }
}

export const currentTrackReducer = (state = '', action) => {
    switch (action.type) {
        case CURRENT_TRACK:
            return state = action.data
        default:
            return state
    }
}

export const audioPlayingReducer = (state = false, action) => {
    switch (action.type) {
        case IS_PLAY:
            return state = action.data
        default:
            return state
    }
}

export const showAnswerReducer = (state = false, action) => {
    switch (action.type) {
        case SHOW_ANSWER:
            return true
        case HIDE_ANSWER:
            return false
        default:
            return state
    }
}