import {
    LoaderReducers,
    DialogReducers,
    AlertReducers,
    audioPlayingReducer,
    currentTrackReducer,
    showAnswerReducer
} from './app.reducers'

import { combineReducers } from 'redux'

const allReducers = combineReducers({
    globalLoader: LoaderReducers,
    globalDialog: DialogReducers,
    globalAlert: AlertReducers,
    globalCurrentTrack: currentTrackReducer,
    globalIsAudioPlaying: audioPlayingReducer,
    globalShowAnswer: showAnswerReducer
})

const appReducers = (state, action) => {
    return allReducers(state, action)
}

export default appReducers