import { Breadcrumbs, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, Typography, Button } from "@material-ui/core";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import SuccessSubmitDialog from "../../component/successSubmitDialog.component";
import TitlePage from "../../component/titlePage.component";
import { chapterCategoryText, variantComponent } from "../../helper/utils";
import UnitComplete from "../../component/unitComplate.component";
import { useDispatch } from "react-redux";
import { show_alert } from "../../actions/alert.actions";
import { updateProgress } from "../../models/enrollment.model";
import { hideloader, showloader } from "../../actions/loader.actions";
import lang from "../../helper/localize";
import params from "../../helper/params";
import { requestForScore } from "../../models/score.model";
import { setIsPlaying } from "../../actions/audio-player.actions";
import { hide_answer } from "../../actions/show-answer.actions";

const useStyles = makeStyles((theme) => ({
    elearningContent: {
        backgroundColor: '#FAFAFA',
        border: '1px solid #BDBDBD',
        minHeight: 200,
        borderRadius: 10,
        marginTop: 20
    },
    elearningNav: {
        marginTop:20
    }
}));

export default function ElearningPage(props) {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const userData = JSON.parse(localStorage.getItem('auth'))

    const [openSuccessSubmit, setOpenSuccessSubmit] = useState(false)
    function handleOpenSuccessSubmit() {
        setOpenSuccessSubmit(!openSuccessSubmit)
    }

    const {bookDetails, unit, syllabus, chapter, chapterList, categoryKey, chapterIndex, categoryIndex} = history.location.state

    const unitName = unit.name ?? ''
    const [chapNo, setChapNo] = useState(chapterIndex ?? 0)
    const [chapterName, setChapterName] = useState(chapter.name ?? '')
    const [isCourseFinish, setIsCourseFinish] = useState(false)
    const [isUnitFinish, setIsUnitFinish] = useState(false)
    async function handleNavChapter(e) {
        setChapNo(e.target.value)
        dispatch(setIsPlaying(
            false
        ))
        dispatch(hide_answer())
        setChapterName(chapterList[e.target.value].name)
        try {
            dispatch(showloader(lang.process_data))
            if (chapterList[e.target.value].type !== params.CHAPTER_TYPE.QUIZ) {
                await updateProgress(token, bookDetails._id, {
                    chapter_complete: chapterList[e.target.value]._id
                })
            } else {
                await requestForScore(token, {
                    user_id: userData.id,
                    book_id: bookDetails._id,
                    unit_id: unit._id,
                    chapter_id: chapterList[e.target.value]._id,
                    classcode: userData.class_code
                })
            }
            
            dispatch(hideloader())
        } catch (error) {
            dispatch(show_alert({
                status: 'error',
                message: error.message
            }))
        }
    }

    async function handleNext() {
        dispatch(setIsPlaying(
            false
        ))
        dispatch(hide_answer())
        let newChap = chapNo + 1
        if (chapNo < chapterList.length -1) {
            try {
                dispatch(showloader(lang.process_data))
                if (chapterList[newChap].type !== params.CHAPTER_TYPE.QUIZ) {
                    await updateProgress(token, bookDetails._id, {
                        chapter_complete: chapterList[newChap]._id
                    })
                } else {
                    await requestForScore(token, {
                        user_id: userData.id,
                        book_id: bookDetails._id,
                        unit_id: unit._id,
                        chapter_id: chapterList[newChap]._id,
                        classcode: userData.class_code
                    })
                }
                
                dispatch(hideloader())
                setChapNo(newChap)
                setChapterName(chapterList[newChap].name)
            } catch (error) {
                dispatch(show_alert({
                    status: 'error',
                    message: error.message
                }))
            }
        }

        if (newChap === chapterList.length) {
            setIsCourseFinish(true)
        }
    }

    function handlePrev() {
        if (chapNo > 0) {
            let newChap = chapNo - 1
            setChapNo(newChap)
            setChapterName(chapterList[newChap].name)
        }

        if (chapNo === chapterList.length) {
            setIsCourseFinish(false)
            setChapNo(chapNo-1)
        }
        dispatch(setIsPlaying(
            false
        ))

        dispatch(hide_answer())
    }

    async function handleNextChapter() {
        setIsCourseFinish(false)
        let nextCategoryIndex = categoryIndex + 1
        if (nextCategoryIndex > categoryKey.length - 1) {
            try {
                setIsUnitFinish(true)
                dispatch(showloader(lang.process_data))
                await updateProgress(token, bookDetails._id, {
                    unit_complete: unit._id
                })
                dispatch(hideloader())
                history.replace({pathname: '/course/detail', state: bookDetails})
            } catch (error) {
                dispatch(show_alert({
                    status: 'error',
                    message: error.message
                }))
            }
        } else {
            let nextChapterList = syllabus[categoryKey[nextCategoryIndex]]
            try {
                dispatch(showloader(lang.process_data))
                if (nextChapterList[0].type !== params.CHAPTER_TYPE.QUIZ) {
                    await updateProgress(token, bookDetails._id, {
                        chapter_complete: nextChapterList[0]._id
                    })
                } else {
                    await requestForScore(token, {
                        user_id: userData.id,
                        book_id: bookDetails._id,
                        unit_id: unit._id,
                        chapter_id: nextChapterList[0]._id,
                        classcode: userData.class_code
                    })
                }
                

                setChapNo(0)
                dispatch(hideloader())
                history.replace({pathname: '/course/elearning', state: {
                    bookDetails,
                    unit,
                    syllabus,
                    chapter: nextChapterList[0],
                    chapterIndex: 0,
                    chapterList: nextChapterList,
                    categoryKey,
                    categoryIndex: nextCategoryIndex
                }})
        
                history.go(0)
            } catch (error) {
                dispatch(show_alert({
                    status: 'error',
                    message: error.message
                }))
            }
        }
        dispatch(setIsPlaying(
            false
        ))

        dispatch(hide_answer())
    }

    return <div>
        <Grid container dir="rtl">
            <Grid item md={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="primary" to={{pathname:'/course'}} style={{
                        fontFamily: "Hacen"
                    }}>
                        {lang.books}
                    </Link>
                    <Link color="primary" to={{pathname:'/course/detail', state: bookDetails}} style={{
                        fontFamily: "Hacen"
                    }}>
                        {bookDetails.name}
                    </Link>
                    <Link color="primary" to={{pathname:'/course/detail/unit', state: {bookDetails, unit}}} style={{
                        fontFamily: "Hacen"
                    }}>
                        {unitName} : {chapterCategoryText(chapter.category)}
                    </Link>
                    <Typography color="textPrimary" style={{
                        fontFamily: "Hacen"
                    }}>{chapterName}</Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
        <br/><br/>
        <TitlePage title={chapterName} />
        <Grid container justify="center" dir="rtl">
            <Grid item md={10} className={classes.elearningContent}>
                { (!isCourseFinish) && variantComponent(chapterList[chapNo].variant, chapterList[chapNo])}

                { (isCourseFinish && !isUnitFinish) && <UnitComplete unit={chapterCategoryText(chapter.category)} />}

                { (isCourseFinish && isUnitFinish) && <UnitComplete unit={chapterCategoryText(unit.name)}/>}
            </Grid>
        </Grid>
       
            {(!isCourseFinish) && <Grid container className={classes.elearningNav} justify="center">
                <Grid item md={2} style={{textAlign: 'right'}}>
                    <IconButton onClick={handlePrev}>
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5 0C7.8575 0 0 7.8575 0 17.5C0 27.16 7.8575 35 17.5 35L17.9896 34.9933C27.4235 34.7337 35 26.9963 35 17.5C35 7.8575 27.16 0 17.5 0ZM20.02 10.115C20.37 10.115 20.7025 10.2375 20.965 10.5C21.4725 11.025 21.4725 11.8475 20.9475 12.355L15.785 17.5L20.9475 22.645C21.4725 23.1525 21.4725 23.9925 20.965 24.5C20.44 25.025 19.6175 25.025 19.11 24.5L13.0025 18.4275C12.7575 18.1825 12.6175 17.85 12.6175 17.5C12.6175 17.15 12.7575 16.8175 13.0025 16.5725L19.11 10.5C19.355 10.2375 19.6875 10.115 20.02 10.115Z" fill="#333333"/>
    </svg>
                    </IconButton>
                </Grid>
                <Grid item md={1}>
                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">{lang.lesson}</InputLabel>
                            <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={chapNo}
                            label={lang.lesson}
                            onChange={handleNavChapter}
                            >
                                {(chapterList.map(data => <MenuItem key={data._id} value={chapterList.indexOf(data)}>{data.name}</MenuItem>))}
                            </Select>
                    </FormControl>
                </Grid>
                <Grid item md={2}>
                    <IconButton onClick={handleNext}>
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5 0C27.1425 0 35 7.8575 35 17.5C35 27.16 27.1425 35 17.5 35L17.0104 34.9933C7.57649 34.7337 0 26.9963 0 17.5C0 7.8575 7.84 0 17.5 0ZM14.98 10.115C14.63 10.115 14.2975 10.2375 14.035 10.5C13.5275 11.025 13.5275 11.8475 14.0525 12.355L19.215 17.5L14.0525 22.645C13.5275 23.1525 13.5275 23.9925 14.035 24.5C14.56 25.025 15.3825 25.025 15.89 24.5L21.9975 18.4275C22.2425 18.1825 22.3825 17.85 22.3825 17.5C22.3825 17.15 22.2425 16.8175 21.9975 16.5725L15.89 10.5C15.645 10.2375 15.3125 10.115 14.98 10.115Z" fill="#333333"/>
    </svg>
                    </IconButton>
                </Grid>
            </Grid>}

            {(isCourseFinish || isUnitFinish) && <Grid container className={classes.elearningNav} justify="flex-end">
                <Grid item md={2} style={{marginRight: 20}}>
                    <Button variant="contained" color="primary" onClick={handleNextChapter}>
                        {lang.next}
                    </Button>
                </Grid>
            </Grid>}
        <SuccessSubmitDialog open={openSuccessSubmit} handleClose={handleOpenSuccessSubmit} />
    </div>
}