import { Button, Container, Grid, makeStyles,TextField,Typography } from "@material-ui/core";
import lang from "../helper/localize";
import params from "../helper/params";
import EmptyCourseCard from "./emptyCourseCard.component";
import InstructionTextAudio from "./instructionTextAudio.component";
import renderHTML from 'react-render-html'
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { hideloader, showloader } from "../actions/loader.actions";
import { requestForScore } from "../models/score.model";
import { updateProgress } from "../models/enrollment.model";
import { hide_dialog, show_dialog } from "../actions/dialog.actions";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 8,
        fontFamily: "trado",
        fontSize: 32
    },
    imagePlaceholder: {
        width: '100%'
    },
    kuisinfo: {
        padding: 20,
        fontSize: (lang.getLanguage() === params.LANG.ARB) ? 24 : 12
    }
}));

export default function ReadingUnit(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const token = localStorage.getItem('token')
    const userData = JSON.parse(localStorage.getItem('auth'))
    const {bookDetails, unit, chapter} = history.location.state

    function handleOnclick() {
        let jawaban = []
        let listQuestion = props.data.content
        if (listQuestion.length !== 0) {
            for (let i = 0; i < listQuestion.length; i++) {
                const materi = listQuestion[i];
                jawaban.push({
                    answer: document.getElementById(materi.id).value,
                    short_answer: materi.short_answer,
                    short_question: materi.short_question
                })
            }

            dispatch(showloader(lang.process_data))
            requestForScore(token, {
                book_id: bookDetails._id,
                unit_id: unit._id,
                chapter_id: chapter._id,
                classcode: userData.class_code,
                attachment: jawaban
            }).then(async res => {
                await updateProgress(token, bookDetails._id, {
                    chapter_complete: chapter._id,
                    user_id: userData.id
                })
                
                dispatch(hideloader())
                dispatch(show_dialog({
                    title: lang.attention,
                    message: "Jawaban Anda sudah di input dan akan di nilai oleh dosen."
                }, () => {
                    dispatch(hide_dialog())
                    history.go(0)
                }))
            })
        }
    }


    return <Container className={classes.root}>
            <InstructionTextAudio data={props.data} />
            <Grid container style={{
                padding: 20
            }}>
                <Grid item md={12}>
                    {(props.data.passage) && <Typography style={{
                        fontFamily: "trado",
                        fontSize: 32
                    }}>
                        {renderHTML(props.data.passage ?? "")}
                    </Typography>}
                    
                </Grid>
            </Grid>
            <Grid container style={{
                padding: 20
            }}>
                <Grid item md={12}>
                    {(props.data.content.length === 0) && <EmptyCourseCard message={lang.empty_unit}
                        showLinkButton={false}/>}
                    
                    {(props.data.content.length !== 0) && props.data.content.map(materi => 
                        <Grid container key={props.data.content.indexOf(materi)}>
                            <Grid item md={6}>
                                <span style={{
                                    fontFamily: "trado",
                                    fontSize: 32
                                }}>{materi.short_question}</span>
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    key={materi.id}
                                    margin="dense"
                                    id={materi.id}
                                    type="text"
                                    fullWidth
                                    variant='outlined'
                                    placeholder="Masukan jawaban..."
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid container style={{
                padding: 20
            }}>
                <Grid item md={12}>
                    <Button color="primary" variant="contained" onClick={handleOnclick}>
                        {lang.submit}
                    </Button>
                </Grid>
            </Grid>
            <br/>
        </Container>
}