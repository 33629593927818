import { Container, Grid, makeStyles } from "@material-ui/core";
import lang from "../helper/localize";
import params from "../helper/params";
import { loadAssets } from "../helper/utils";
import InstructionTextAudio from "./instructionTextAudio.component";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 8,
        fontFamily: "trado",
        fontSize: 32
    },
    imagePlaceholder: {
        width: '100%'
    },
    kuisinfo: {
        padding: 20,
        fontSize: (lang.getLanguage() === params.LANG.ARB) ? 24 : 12
    }
}));

export default function ImageUnit(props) {
    const classes = useStyles()
    return <Container className={classes.root}>
            <InstructionTextAudio data={props.data} />
            <Grid container justify="center">
                <Grid item md={8}>
                    <img
                        alt=""
                        src={loadAssets(props.data.image)}
                        className={classes.imagePlaceholder}
                    />
                </Grid>
            </Grid>
            <br/>
            {(props.data.faculty_id) && <Grid container style={(lang.getLanguage() ===  params.LANG.ARB) ? {
                    fontSize: 20,
                    marginTop: 5,
                    padding: 20
                } : {
                    fontSize: 14,
                    padding: 20
                }}>
                {lang.submit_kuis_info} &nbsp;<span >{props.data.faculty_id.email}</span>
            </Grid>}
        </Container>
}