import { Typography, Button, TextField, Grid, List, ListItem, ListItemIcon, ListItemText, IconButton, InputAdornment, MenuItem, FormControl, InputLabel, Select, FormLabel, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import params from "../helper/params";
import { useDispatch } from "react-redux";
import { hideloader, showloader } from "../actions/loader.actions";
import { show_alert } from "../actions/alert.actions";
import { useState } from "react";
import { hide_dialog, show_dialog } from "../actions/dialog.actions";
import { deleteMultiChoiceVariant, getChapter, uploadMultiChoiceVariant } from "../models/chapter.model";
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/Help';
import AssignmentIcon from '@material-ui/icons/Assignment';

export default function MultiChoiceVariant(props) {
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    const [listQuestion, setListQuestion] = useState(
        props.chapterData.content ?? []
    )

    const [question, setQuestion] = useState('')
    function handleInputQuestion(e) {
        setQuestion(e.target.value)
    }

    const [questionImage, setQuestionImage] = useState('')
    function handleInputQuestionImage(e) {
        setQuestionImage(e.target.files[0])
    }

    const [questionAudio, setQuestionAudio] = useState('')
    function handleInputQuestionAudio(e) {
        setQuestionAudio(e.target.files[0])
    }

    const [answerType, setAnswerType] = useState('0')
    function handleInputAnswerType(e) {
        setAnswerType(e.target.value)
    }

    const [optionA, setOptionA] = useState('')
    function handleInputOptionA(e) {
        setOptionA(e.target.value)
    }

    const [optionB, setOptionB] = useState('')
    function handleInputOptionB(e) {
        setOptionB(e.target.value)
    }

    const [optionC, setOptionC] = useState('')
    function handleInputOptionC(e) {
        setOptionC(e.target.value)
    }

    const [optionD, setOptionD] = useState('')
    function handleInputOptionD(e) {
        setOptionD(e.target.value)
    }

    const [optionAfile, setOptionAfile] = useState('')
    function handleInputOptionAfile(e) {
        setOptionAfile(e.target.files[0])
    }

    const [optionBfile, setOptionBfile] = useState('')
    function handleInputOptionBfile(e) {
        setOptionBfile(e.target.files[0])
    }

    const [optionCfile, setOptionCfile] = useState('')
    function handleInputOptionCfile(e) {
        setOptionCfile(e.target.files[0])
    }

    const [optionDfile, setOptionDfile] = useState('')
    function handleInputOptionDfile(e) {
        setOptionDfile(e.target.files[0])
    }

    const [rightAnswer, setRightAnswer] = useState(0)
    const [rightAnswerText, setRightAnswerText] = useState("")
    const [isChangeRightAnswer, setIsChangeRightAnswer] = useState(false)

    function handleInputSetRightAnswer(e) {
        setIsChangeRightAnswer(true)
        let answer = e.target.value
        let answerText = ""
        switch (answer) {
            case 0:
                answerText = optionA
                break;
            case 1:
                answerText = optionB
                break;
            case 2:
                answerText = optionC
                break;
            case 3:
                answerText = optionD
                break;
            default:
                answerText = optionA
                break;
        }
        setRightAnswer(answer)
        setRightAnswerText(answerText)
    }

    function handleInputSetRightAnswerFile(e) {
        setIsChangeRightAnswer(true)
        let answer = e.target.value
        let answerText = ""
        switch (answer) {
            case 0:
                answerText = optionAfile.name
                break;
            case 1:
                answerText = optionBfile.name
                break;
            case 2:
                answerText = optionCfile.name
                break;
            case 3:
                answerText = optionDfile.name
                break;
            default:
                answerText = optionAfile.name
                break;
        }
        setRightAnswer(answer)
        setRightAnswerText(answerText)
    }

    function resetFormValue() {
        setQuestion('')
        setOptionA('')
        setOptionB('')
        setOptionC('')
        setOptionD('')
        setRightAnswer(0)
        setRightAnswerText('')
        document.getElementById('question_image').value = '';
        document.getElementById('question_audio').value = '';
        if (answerType == 1) {
            document.getElementById('optionAfile').value = '';
            document.getElementById('optionBfile').value = '';
            document.getElementById('optionCfile').value = '';
            document.getElementById('optionDfile').value = '';
        }
        
        setAnswerType(0)
        setQuestionAudio('')
        setQuestionImage('')
        setOptionAfile('')
        setOptionBfile('')
        setOptionCfile('')
        setOptionDfile('')
    }

    function refreshListQuestion() {
        dispatch(showloader("Memuat data..."))
        getChapter(token, props.chapterData._id).then(res => {
            setListQuestion(res.data.content)
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    function handleDelete(data) {
        dispatch(show_dialog({
            title: "Perhatian",
            message: "Apa Anda yakin ingin menghapus data ini?"
        }, () => {
            dispatch(hide_dialog())
            deleteMultiChoiceVariant(token, props.chapterData._id, {deleted: data}).then(res => {
                dispatch(show_alert({
                    status: "success",
                    message: res.message
                }))
            }).catch(err => {
                dispatch(show_alert({
                    status: "error",
                    message: err.message
                }))
            }).finally(() => {
                refreshListQuestion()
            })
        }))
    }

    function handleSubmit() {
        let option = []

        if (optionA !== '') {
            option.push(optionA)
        }

        if (optionB !== '') {
            option.push(optionB)
        }

        if (optionC !== '') {
            option.push(optionC)
        }

        if (optionD !== '') {
            option.push(optionD)
        }

        var formData = new FormData()
        formData.append('question', question)
        formData.append('questionImage', questionImage)
        formData.append('questionAudio', questionAudio)
        formData.append('option', option)
        formData.append('rightAnswer', (isChangeRightAnswer === true) ? rightAnswerText : optionA)
        formData.append('variant', params.CHAPTER_VARIANT.MULTI_CHOICE)
        formData.append('answerType', answerType)
        formData.append('book_id', props.chapterData.book_id)
        formData.append('unit_id', props.chapterData.unit_id)

        dispatch(showloader("Memproses data..."))
        uploadMultiChoiceVariant(token, props.chapterData._id, formData).then(res => {
            dispatch(show_alert({
                status: "success",
                message: res.message
            }))
            refreshListQuestion()
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
            resetFormValue()
        })
    }

    function handleSubmitWithFile() {
        var formData = new FormData()
        let option = []

        if (optionAfile !== '') {
            option.push(optionAfile)
            formData.append('optionA', optionAfile)
        }

        if (optionBfile !== '') {
            option.push(optionBfile)
            formData.append('optionB', optionBfile)
        }

        if (optionCfile !== '') {
            option.push(optionCfile)
            formData.append('optionC', optionCfile)
        }

        if (optionDfile !== '') {
            option.push(optionDfile)
            formData.append('optionD', optionDfile)
        }

        let rightAnswer = (isChangeRightAnswer === true) ? rightAnswerText : optionAfile.name

        formData.append('question', question)
        formData.append('questionImage', questionImage)
        formData.append('questionAudio', questionAudio)
        formData.append('option', option)
        formData.append('rightAnswer', rightAnswer)
        formData.append('variant', params.CHAPTER_VARIANT.MULTI_CHOICE)
        formData.append('answerType', answerType)
        formData.append('book_id', props.chapterData.book_id)
        formData.append('unit_id', props.chapterData.unit_id)

        dispatch(showloader("Memproses data..."))
        
        uploadMultiChoiceVariant(token, props.chapterData._id, formData).then(res => {
            dispatch(show_alert({
                status: "success",
                message: res.message
            }))
            refreshListQuestion()
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
            resetFormValue()
        })
    }

    return <div>
        <Typography>
            Kuis berupa pilihan ganda, masukan soal beserta pilihan jawaban.
        </Typography>
        <hr/>
        <Grid container>
            <Grid item md={12}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="question_image"
                    label="Gambar"
                    type="file"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <HelpIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputQuestionImage}
                    placeholder="Masukan Gambar..."
                />
            </Grid>
            <Grid item md={12}>
                {(questionImage && Object(questionImage) === questionImage) 
                    ? <span>Gambar: {questionImage.name}</span>
                    : <span>Gambar: {questionImage}</span>}
            </Grid>
            <Grid item md={12}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="question_audio"
                    label="Audio"
                    type="file"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <HelpIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputQuestionAudio}
                    placeholder="Masukan Audio..."
                />
            </Grid>
            <Grid item md={12}>
                {(questionAudio && Object(questionAudio) === questionAudio)
                    ? <span>Audio: {questionAudio.name}</span>
                    : <span>Audio: {questionAudio}</span>}
            </Grid>
            <Grid item md={12}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="question"
                    label="Pertanyaan"
                    type="text"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <HelpIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputQuestion}
                    placeholder="Masukan pertanyaan..."
                    value={question}
                />
            </Grid>
            <hr/>
            <Grid item md={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Tipe Jawaban</FormLabel>
                    <RadioGroup
                        row
                        aria-label="answerType"
                        name="answerType"
                        value={answerType}
                        onChange={handleInputAnswerType}
                    >
                        <FormControlLabel value='0' control={<Radio color="primary" />} label="Text" />
                        <FormControlLabel value='1' control={<Radio color="primary" />} label="Gambar" />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
        <hr/>

        {(answerType == 0) && <div><Grid container spacing={4}>
            <Grid item md={4}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="optionA"
                    label="Option A"
                    type="text"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <AssignmentIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputOptionA}
                    placeholder="Masukan jawaban A..."
                    value={optionA}
                />
            </Grid>

            <Grid item md={4}>
                <TextField
                    margin="dense"
                    id="optionB"
                    label="Option B"
                    type="text"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <AssignmentIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputOptionB}
                    placeholder="Masukan jawaban B..."
                    value={optionB}
                />
            </Grid>
            <Grid item md={4}>
                <FormControl variant="outlined" style={{
                    marginTop:8,
                    width: '100%'
                }} fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Jawaban benar</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={rightAnswer}
                        onChange={handleInputSetRightAnswer}
                        style={{
                            height: 42
                        }}
                        label="Jawaban benar"
                    >
                        <MenuItem key={0} value={0}>
                            <em>OPTION A</em>
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                            <em>OPTION B</em>
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                            <em>OPTION C</em>
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                            <em>OPTION D</em>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>

        <Grid container spacing={4}>
            <Grid item md={4}>
                <TextField
                    margin="dense"
                    id="optionC"
                    label="Option C"
                    type="text"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <AssignmentIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputOptionC}
                    placeholder="Masukan jawaban C..."
                    value={optionC}
                />
            </Grid>

            <Grid item md={4}>
                <TextField
                    margin="dense"
                    id="optionD"
                    label="Option D"
                    type="text"
                    fullWidth
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <AssignmentIcon/>
                        </InputAdornment>
                        ),
                    }}
                    onChange={handleInputOptionD}
                    placeholder="Masukan jawaban D..."
                    value={optionD}
                />
            </Grid>

            <Grid item md={4}>
                <Button fullWidth variant="contained" color="primary" style={{
                    marginTop: 10
                }} onClick={handleSubmit}>
                    Tambah
                </Button>
            </Grid>
        </Grid></div>}
        
        {(answerType == 1) && <div>
            <Grid container spacing={4}>
                <Grid item md={4}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="optionAfile"
                        label="Option A"
                        type="file"
                        fullWidth
                        variant='outlined'
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <AssignmentIcon/>
                            </InputAdornment>
                            ),
                        }}
                        onChange={handleInputOptionAfile}
                        placeholder="Masukan jawaban A..."
                    />
                    <Grid item md={12}>
                        {(optionAfile && Object(optionAfile) === optionAfile)
                            ? <span>Gambar: {optionAfile.name}</span>
                            : <span>Gambar: {optionAfile}</span>}
                    </Grid>
                </Grid>

                <Grid item md={4}>
                    <TextField
                        margin="dense"
                        id="optionBfile"
                        label="Option B"
                        type="file"
                        fullWidth
                        variant='outlined'
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <AssignmentIcon/>
                            </InputAdornment>
                            ),
                        }}
                        onChange={handleInputOptionBfile}
                        placeholder="Masukan jawaban B..."
                    />
                    <Grid item md={12}>
                        {(optionBfile && Object(optionBfile) === optionBfile)
                            ? <span>Gambar: {optionBfile.name}</span>
                            : <span>Gambar: {optionBfile}</span>}
                    </Grid>
                </Grid>
                <Grid item md={4}>
                    <FormControl variant="outlined" style={{
                        marginTop:8,
                        width: '100%'
                    }} fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">Jawaban benar</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={rightAnswer}
                            onChange={handleInputSetRightAnswerFile}
                            style={{
                                height: 42
                            }}
                            label="Jawaban benar"
                        >
                            <MenuItem key={0} value={0}>
                                <em>OPTION A</em>
                            </MenuItem>
                            <MenuItem key={1} value={1}>
                                <em>OPTION B</em>
                            </MenuItem>
                            <MenuItem key={2} value={2}>
                                <em>OPTION C</em>
                            </MenuItem>
                            <MenuItem key={3} value={3}>
                                <em>OPTION D</em>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={4}>
                <Grid item md={4}>
                    <TextField
                        margin="dense"
                        id="optionCfile"
                        label="Option C"
                        type="file"
                        fullWidth
                        variant='outlined'
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <AssignmentIcon/>
                            </InputAdornment>
                            ),
                        }}
                        onChange={handleInputOptionCfile}
                        placeholder="Masukan jawaban C..."
                    />
                    <Grid item md={12}>
                        {(optionCfile && Object(optionCfile) === optionCfile)
                            ? <span>Gambar: {optionCfile.name}</span>
                            : <span>Gambar: {optionCfile}</span>}
                    </Grid>
                </Grid>

                <Grid item md={4}>
                    <TextField
                        margin="dense"
                        id="optionDfile"
                        label="Option D"
                        type="file"
                        fullWidth
                        variant='outlined'
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <AssignmentIcon/>
                            </InputAdornment>
                            ),
                        }}
                        onChange={handleInputOptionDfile}
                        placeholder="Masukan jawaban D..."
                    />
                    <Grid item md={12}>
                        {(optionDfile && Object(optionDfile) === optionDfile)
                            ? <span>Gambar: {optionDfile.name}</span>
                            : <span>Gambar: {optionDfile}</span>}
                    </Grid>
                </Grid>

                <Grid item md={4}>
                    <Button fullWidth variant="contained" color="primary" style={{
                        marginTop: 10
                    }} onClick={handleSubmitWithFile}>
                        Tambah
                    </Button>
                </Grid>
            </Grid>
        </div>}
        <hr/>
        <Grid container>
            <Grid item md={12}>
                <List component="nav" aria-label="main mailbox folders">
                    {(listQuestion.length === 0) && <ListItem>
                        <ListItemText primary="Tidak ada data"/>
                    </ListItem>}

                    {(listQuestion.length > 0) && listQuestion.map(data => <ListItem>
                        <ListItemIcon>
                            {listQuestion.indexOf(data) + 1}
                        </ListItemIcon>
                        <ListItemText primary={data.question} secondary={
                            (data.option.length === 0)
                                ? '-'
                                : data.option.map(val => val.substr(0, 30) + "... | ")
                        } style={{
                            width: 300
                        }} />
                        <ListItemText primary="Jawaban" secondary={data.right_answer ? data.right_answer.substr(0, 50) + '...' : 'Tidak ada jawaban'} />
                        <ListItemIcon>
                            <IconButton onClick={() => handleDelete(data)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemIcon>
                    </ListItem>)}
                </List>
            </Grid>
        </Grid>
    </div>
}