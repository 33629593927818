import { Container, Grid, makeStyles, RadioGroup, Radio, FormControlLabel, Button } from "@material-ui/core";
import { useState } from "react";
import { loadAssets } from "../helper/utils";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InstructionTextAudio from "./instructionTextAudio.component";
import lang from "../helper/localize";
import EmptyCourseCard from "./emptyCourseCard.component";
import { useDispatch, useSelector } from "react-redux";
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import { setCurrentTrack, setIsPlaying } from "../actions/audio-player.actions";
import { numberToArabic } from 'number-to-arabic'
import { hide_answer, show_answer } from "../actions/show-answer.actions";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 8,
        fontFamily: "trado",
        fontSize: 32
    }
}));
export default function AudioABUnit(props) {
    const content = props.data.content
    const classes = useStyles()
    const [value, setValue] = useState([]);
    const audioIsPlay = useSelector(state => state.globalIsAudioPlaying)
    const currentTrack = useSelector(state => state.globalCurrentTrack)
    const showAnswer = useSelector(state => state.globalShowAnswer)
    const dispatch = useDispatch()

    const handleRadioChange = (event, index) => {
        let newValue = value
        
        newValue[index] = event.target.value
        setValue(newValue);
    };

    function handleCheckAnswer() {
        if (showAnswer) {
            dispatch(hide_answer())
        } else {
            dispatch(show_answer())
        }
    }

    function handlePlayAudio() {
        dispatch(setCurrentTrack(
            loadAssets(props.data.audio)
        ))
        setTimeout(() => {
            dispatch(setIsPlaying(
                !audioIsPlay
            ))
        }, 1000);
    }

    return <Container className={classes.root}>
        <InstructionTextAudio data={props.data} />
        <Grid container>
            <Grid item md={12}>
                <Grid container>
                    <Grid item md={12}>
                        {(content.length === 0) && <EmptyCourseCard 
                            message={lang.empty_unit}
                            showLinkButton={false}
                        />}
                        <table>
                            <thead>
                                <tr>
                                    <td colSpan="5">
                                        <Button onClick={handlePlayAudio}>
                                            {(currentTrack === loadAssets(props.data.audio) && audioIsPlay)
                                                ? <PauseCircleFilledIcon/>
                                                : <PlayCircleFilledIcon color="primary" />}
                                            &nbsp; <span style={{
                                                fontFamily: "trado",
                                                fontSize: 32
                                            }}>{lang.play_audio}</span>
                                        </Button>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {(content.length !== 0) && content.map(data => <tr key={content.indexOf(data) + 1}>
                                    <td style={{
                                        verticalAlign: 'top'
                                    }}>{numberToArabic(content.indexOf(data) + 1)}</td>
                                    <td>
                                        &nbsp;
                                    </td>
                                    <td>
                                        <RadioGroup aria-label="quiz" name="quiz1" value={value[content.indexOf(data)]} onChange={(e) => handleRadioChange(e, content.indexOf(data))} key="quiz1">
                                            <FormControlLabel value={data.option_a} control={<Radio color="primary"/>} label={<span style={{
                                                fontFamily: "trado",
                                                fontSize: 32
                                            }}>{data.option_a}</span>} />
                                            <FormControlLabel value={data.option_b} control={<Radio color="primary"/>} label={<span style={{
                                                fontFamily: "trado",
                                                fontSize: 32
                                            }}>{data.option_b}</span>} />
                                        </RadioGroup>
                                    </td>
                                    <td>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </td>
                                    <td style={{
                                        verticalAlign: "top"
                                    }}>
                                        {(showAnswer) ? (data.right_answer === value[content.indexOf(data)])
                                            ? <CheckCircleIcon color="primary" />
                                            : <CancelIcon style={{color: 'red'}} />
                                        : ""}
                                    </td>
                                </tr>)}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="5">
                                        <br/>
                                        <Button variant="contained" color="primary" onClick={handleCheckAnswer}>
                                            {lang.submit}
                                        </Button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Container>
}