import axios from 'axios'

const api = axios.create()

api.interceptors.response.use((res) => {
    return res
}, (error) => {
    let data = error.response
    if (data.status === 401) {
        localStorage.clear()

        window.location = '/'
    }

    return data
})

export default api