import { Button, Toolbar, AppBar, Hidden, IconButton, Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router";
import HomeIcon from '@material-ui/icons/Home';
import BookIcon from '@material-ui/icons/Book';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Footer from "../../component/footer.component";
import { useDispatch } from "react-redux";
import { hideloader, showloader } from "../../actions/loader.actions";
import { logout } from "../../models/user.model";
import { show_alert } from "../../actions/alert.actions";
import Bilingual from "../../component/bilingual.component";
import lang from "../../helper/localize";
import params from "../../helper/params";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    mask: {
        backgroundRepeat: 'repeat',
        backgroundImage: 'url(pattern.jpg)',
        position:'relative',
        width: '100%',
        minHeight: '100vh',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      backgroundColor: "rgba(255,255,255,0.78)"
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    titleLogo: {
      maxWidth: 176
    },
    bar: {
        flexGrow: 1
    },
    content: {
        flex: 1,
        padding: 32,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
}));

export default function HomePage(props) {
    const Component = props.component
    const route = props.route
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    function gotoHome() {
        history.push('/home')
    }

    function gotoCourse() {
        history.push('/course')
    }

    function gotoProfile() {
        history.push('/profile')
    }

    function gotoLogout() {
        dispatch(showloader(lang.logout_text))
        logout(token).then(res => {
            localStorage.removeItem('auth')
            localStorage.removeItem('token')
            history.replace('/')
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <div className={classes.mask}><div className={classes.root}>
        <AppBar position="static" style={{backgroundColor: 'white'}} elevation={5}>
            <Toolbar>
                <img src="/logo-elearning.png" className={classes.titleLogo} alt="logo" />
                <div className={classes.bar}>&nbsp;</div>
                <Hidden mdDown>
                    <Bilingual />
                    <Button
                        style={{
                            display: 'none'
                        }}
                        color="primary" variant={(props.menu === "HOME") ? "contained" : "text"}
                        className={classes.menuButton}
                        onClick={gotoHome}
                    >
                        <HomeIcon />
                        &nbsp;Beranda
                    </Button>
                    <Button
                        color="primary"
                        variant={(props.menu === "COURSE") ? "contained" : "text"}
                        className={classes.menuButton}
                        onClick={gotoCourse}
                        dir={
                            (lang.getLanguage() === params.LANG.ARB)
                                ? "rtl"
                                : "ltr"
                        }
                        style={{
                            fontFamily: "Hacen"
                        }}
                    >
                        <BookIcon/>
                        &nbsp;{lang.books}
                    </Button>
                    <Button 
                        color="primary"
                        variant={(props.menu === "PROFILE") ? "contained" : "text"}
                        className={classes.menuButton}
                        onClick={gotoProfile}
                        dir={
                            (lang.getLanguage() === params.LANG.ARB)
                                ? "rtl"
                                : "ltr"
                        }
                        style={{
                            fontFamily: "Hacen"
                        }}
                    >
                        <PersonIcon/>
                        &nbsp;{lang.profile}
                    </Button>
                    <Button
                        color="primary"
                        variant="text"
                        onClick={gotoLogout}
                        dir={
                            (lang.getLanguage() === params.LANG.ARB)
                                ? "rtl"
                                : "ltr"
                        }
                        style={{
                            fontFamily: "Hacen"
                        }}
                    >
                        <ExitToAppIcon/>
                        &nbsp;{lang.logout}
                    </Button>
                </Hidden>
                <Hidden mdUp>
                    <div>
                        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            dir={
                                (lang.getLanguage() === params.LANG.ARB)
                                    ? "rtl"
                                    : "ltr"
                            }
                        >
                            <MenuItem onClick={() => {
                                gotoCourse()
                                handleClose()
                            }}>{lang.books}</MenuItem>
                            <MenuItem onClick={() => {
                                gotoProfile()
                                handleClose()
                            }}>{lang.profile}</MenuItem>
                            <MenuItem onClick={() => {
                                gotoLogout()
                                handleClose()
                            }}>{lang.logout}</MenuItem>
                        </Menu>
                        </div>
                </Hidden>
            </Toolbar>
        </AppBar>
        <main className={classes.content}>
            <Component route={route}/>
        </main>
        <Footer/>
    </div>
    </div>
}