import { Container, Grid, makeStyles } from "@material-ui/core";
import ReactPlayer from 'react-player'
import { loadAssets } from "../helper/utils";
import InstructionTextAudio from "./instructionTextAudio.component";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 8,
        fontFamily: "trado",
        fontSize: 32
    },
    placeholderPlayer: {
        textAlign: 'center', height: 400, padding: 10
    }
}));

export default function VideoUnit(props) {
    const classes = useStyles()
    return <Container className={classes.root}>
        <InstructionTextAudio data={props.data} />
        <Grid container justify="center">
            <Grid item md={8} className={classes.placeholderPlayer}>
                <ReactPlayer
                    url={loadAssets(props.data.video)}
                    width='100%'
                    height='100%'
                    playing={true}
                    controls={true}
                    
                />
            </Grid>
        </Grid>
    </Container>
}