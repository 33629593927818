import { Grid, Dialog, DialogTitle, DialogActions, Button, TextField, DialogContent, InputAdornment, makeStyles, Typography, Breadcrumbs } from "@material-ui/core"
import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom"
import TitleBar from "../../component/titleBar.component";
import { loadImage } from "../../helper/utils";
import { createUnit, deleteUnit, getUnitList, updateUnit } from "../../models/unit.model";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import tableIcons from "../../component/tableIcon.component";
import MaterialTable from "material-table";
import { useDispatch } from "react-redux";
import { show_alert } from "../../actions/alert.actions";
import { hide_dialog, show_dialog } from "../../actions/dialog.actions";
import { hideloader, showloader } from "../../actions/loader.actions";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: 20,
        margin:0,
        width: '100%'
    },
    imagePlaceholder: {
        width: '100%',
        border: '1px dashed black'
    }
}));

export default function UnitManagementPage() {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const bookDetail = history.location.state ?? {}

    const [isTableLoad, setIsTableLoad] = useState(false)
    const [isOpenModal, setIsOpenModal] = useState(false)
    function handleClose() {
        setIsOpenModal(!isOpenModal)
    }

    const [isEdit, setIsEdit] = useState(false)
    const [listUnit, setListUnit] = useState({
        column: [
            {title: "Nama unit", field: 'name'}
        ],
        data: []
    })

    const [unitId, setUnitId] = useState('')
    const [unitName, setUnitName] = useState('')
    function handleInputUnitName(e) {
        setUnitName(e.target.value)
    }

    function resetFormValue() {
        setUnitName('')
    }

    function handleSubmitForm() {
        dispatch(showloader("Memproses data..."))
        if (isEdit) {
            updateUnit(token, unitId, {
                name: unitName,
                book_id: bookDetail._id
            }).then(res => {
                refreshUnit()
                setIsOpenModal(false)
            }).catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        } else {
            createUnit(token, {
                name: unitName,
                book_id: bookDetail._id
            }).then(res => {
                refreshUnit()
                setIsOpenModal(false)
            }).catch(err => {
                dispatch(show_alert({
                    status: 'error',
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }
    }

    function handleAddUnit() {
        resetFormValue()
        setIsEdit(false)
        setIsOpenModal(true)
    }

    function handleEditUnit(data) {
        setIsEdit(true)
        setIsOpenModal(true)
        setUnitName(data.name)
        setUnitId(data._id)
    }

    function handleDeleteUnit(data) {
        dispatch(show_dialog({
            title: "Peringatan",
            message: "Apakah Anda yakin ingin menghapus unit ini? seluruh materi di dalamnya akan ikut terhapus."
        }, () => {
            dispatch(hide_dialog())
            dispatch(showloader("Memproses data..."))
            deleteUnit(token, data._id).then(res => [
                refreshUnit()
            ]).catch(err => {
                dispatch(show_alert({
                    status: "error",
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }))
    }

    function handleChapter(data) {
        history.push({
            pathname: '/cpanel/books/unit/chapter',
            state: {
                bookDetail,
                unitDetail: data
            }
        })
    }

    function refreshUnit() {
        setIsTableLoad(true)
        getUnitList(token, bookDetail._id).then(res => {
            setListUnit({
                column: listUnit.column,
                data: res.data
            })
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            setIsTableLoad(false)
        })
    }

    useEffect(() => {
        refreshUnit()
        // eslint-disable-next-line
    }, [])

    return <div>
        <TitleBar title="Detail Buku" subtitle="detail buku dan manajemen unit."/>
        <Grid container className={classes.content} style={{paddingBottom: 0}}>
            <Grid item md={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="primary" to={{pathname:'/cpanel/books'}}>
                        Buku
                    </Link>
                    <Typography color="textPrimary">{bookDetail.name}</Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
        <Grid container className={classes.content} spacing={3}>
            <Grid item md={2}>
                <img src={loadImage(bookDetail.cover)} alt="sampul buku" className={classes.imagePlaceholder} />
                <div>
                    <table>
                        <tr>
                            <td>Kode Kelas</td>
                            <td>:</td>
                            <td>{bookDetail.class_code.map(x => x.name).join(', ')}</td>
                        </tr>
                    </table>
                </div>
            </Grid>
            <Grid item md={10}>
                <MaterialTable 
                    icons={tableIcons}
                    title=""
                    columns={listUnit.column}
                    data={listUnit.data}
                    isLoading={isTableLoad}
                    options={{
                        header: true,
                        actionsColumnIndex:2
                    }}
                    actions={[
                        {
                            icon: tableIcons.Add,
                            tooltip: 'Tambah Unit',
                            isFreeAction: true,
                            onClick: (event) => handleAddUnit()
                        },
                        {
                            icon: tableIcons.DetailBook,
                            tooltip: 'Daftar materi',
                            onClick: (event, rowData) => handleChapter(rowData)
                        },
                        {
                            icon: tableIcons.Edit,
                            tooltip: 'Ubah Unit',
                            onClick: (event, rowData) => handleEditUnit(rowData)
                        },
                        {
                            icon: tableIcons.Delete,
                            tooltip: 'Delete Unit',
                            onClick: (event, rowData) => handleDeleteUnit(rowData)
                        },
                    ]}
                />
            </Grid>
        </Grid>
        <Dialog open={isOpenModal} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xs">
            <DialogTitle id="form-dialog-title">{(isEdit)
                ? "Ubah data"
                : "Tambah data"}
            </DialogTitle>
            <DialogContent>
                <div>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="unitname"
                        label="Nama Unit"
                        type="text"
                        fullWidth
                        variant='outlined'
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <InsertDriveFileIcon/>
                            </InputAdornment>
                            ),
                        }}
                        onChange={handleInputUnitName}
                        placeholder="masukan nama unit..."
                        value={unitName}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Batalkan
                </Button>
                <Button onClick={handleSubmitForm} color="primary" variant="contained">
                    Kirim
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}