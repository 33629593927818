import { Typography, Button, TextField, Grid, List, ListItemText, ListItem, ListItemAvatar, ListItemSecondaryAction, IconButton } from "@material-ui/core";
import params from "../helper/params";
import { useDispatch } from "react-redux";
import { hideloader, showloader } from "../actions/loader.actions";
import { getChapter, deleteReadingVariant, uploadReadingVariant } from "../models/chapter.model";
import { show_alert } from "../actions/alert.actions";
import { useState } from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import { hide_dialog, show_dialog } from "../actions/dialog.actions";

export default function ReadingVariant(props) {
    let chapterData = props.chapterData
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    const [passage, setPassage] = useState(
        (props.chapterData.content.length > 0 && props.chapterData.passage)
        ? props.chapterData.passage.replace(/<\s*\/?br\s*[\/]?>/gi, "\n")
        : ''
    )

    const [listQuestion, setListQuestion] = useState(
        props.chapterData.content ?? []
    )
    const [shortQuestion, setShortQuestion] = useState("")
    function handleShortQuestion (e) {
        setShortQuestion(e.target.value)
    }
    const [shortAnswer, setShortAnswer] = useState("")
    function handleShortAnswer (e) {
        setShortAnswer(e.target.value)
    }
    
    function handleInputQuestion(e) {
        setPassage(e.target.value)
    }

    function handleSubmit() {
        let passage_br = passage.replace(/\r?\n/g, '<br />');

        dispatch(showloader("Memproses data..."))
        uploadReadingVariant(token, chapterData._id, {
            passage: passage_br,
            question: {
                short_question: shortQuestion,
                short_answer: shortAnswer
            },
            variant: params.CHAPTER_VARIANT.READING
        }).then(res => {
            refreshChapter()
            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
        }).catch(err => {
            dispatch(show_alert({
                status: 'success',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
            resetForm()
        })
    }

    function resetForm() {
        setShortAnswer('')
        setShortQuestion('')
    }

    function handleDeleteQuestion (data) {
        dispatch(show_dialog({
            title: "Perhatian",
            message: "Apa Anda yakin ingin menghapus konten ini?"
        }, () => {
            dispatch(hide_dialog())
            dispatch(showloader("Memproses data..."))
            deleteReadingVariant(token, props.chapterData._id, {
                deleted_content: data
            }).then(res => {
                refreshChapter()
            }).catch(err => {
                dispatch(show_alert({
                    status: "error",
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }))
    }

    function refreshChapter() {
        dispatch(showloader("Memuat data..."))
        getChapter(token, props.chapterData._id).then(res => {
            setListQuestion(res.data.content ?? [])
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    return <div>
        <Typography>
            Kuis berupa essai, masukan soal essai.
        </Typography>
        <TextField
            autoFocus
            multiline={true}
            rows={4}
            margin="dense"
            id="bookdetail"
            label="Soal Essai"
            type="text"
            fullWidth
            variant='outlined'
            onChange={handleInputQuestion}
            placeholder="masukan soal essai..."
            value={passage}
        />
        <Grid container spacing={3}>
            <Grid item md={5}>
                <TextField
                    margin="dense"
                    id="shortQuestion"
                    label="Soal"
                    type="text"
                    fullWidth
                    variant='outlined'
                    onChange={handleShortQuestion}
                    placeholder="masukan soal..."
                    value={shortQuestion}
                />
            </Grid>
            <Grid item md={5}>
                <TextField
                    margin="dense"
                    id="short"
                    label="Jawaban"
                    type="text"
                    fullWidth
                    variant='outlined'
                    onChange={handleShortAnswer}
                    placeholder="masukan jawaban..."
                    value={shortAnswer}
                />
            </Grid>
            <Grid item md={2}>
                <Button onClick={handleSubmit} fullWidth variant="contained" color="primary" style={{
                    marginTop:10
                }}>
                    SIMPAN
                </Button>
            </Grid>
        </Grid>
       <hr/>
       <Grid container>
           <Grid item md={12}>
                <List dense={false}>
                    {(listQuestion.length === 0) && <ListItem>
                        <ListItemText
                            primary="Tidak ada data"
                        />
                    </ListItem>}
                    {(listQuestion.length !== 0) && listQuestion.map(materi => <ListItem
                        key={listQuestion.indexOf(materi)}
                    >
                        <ListItemAvatar style={{width: 50}}>
                            {listQuestion.indexOf(materi) + 1}
                        </ListItemAvatar>
                        <ListItemText
                            primary={materi.short_question}
                            secondary={materi.short_answer}
                            style={{paddingLeft: 10}}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteQuestion(materi)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>)}
                </List>
           </Grid>
       </Grid>
    </div>
}